import React, { useEffect, useState } from 'react';

import {
    container,
    bar,
    barOpen,
    barItem,
    barDropdown,
    barButton,
    barButtonBox,
    barVisibleBox,
    link,
    active,
    linkContent,
    barButtonChevron,
    barButtonDots,
} from './dashboard-navigation.module.scss';
import ChevronIcon from '../../assets/images/svg/chevron-down.svg';
import { IRouteData } from '../../models/router.model';
import { getRouteNodeAbsoluteNav } from '../../routes';

import FlexBar from './flex-bar';
import CustomLink from '../hoc/custom-link';

interface IDashboardNavigationProps {
    className?: string;
    routeData: IRouteData;
    uri: string;
    preventScroll?: boolean;
}

interface IDashboardNavItem extends IRouteData {
    absolutePath: string;
}

const DashboardNavigation: React.FC<IDashboardNavigationProps> = ({
    className = '',
    routeData,
    uri,
    preventScroll = false,
}) => {
    const [activeRouteIndex, setActiveRouteIndex] = useState<number | null>(null);
    const navItems: IDashboardNavItem[] = getRouteNodeAbsoluteNav(routeData.key);

    const barClasses = {
        bar: bar,
        barOpen: barOpen,
        item: barItem,
        button: barButton,
        buttonBox: barButtonBox,
        dropdown: barDropdown,
        visibleBox: barVisibleBox,
    };

    const buttonContent = (
        <>
            <div className={barButtonChevron}>
                <ChevronIcon />
            </div>
            <span className={barButtonDots}>...</span>
        </>
    );

    useEffect(() => {
        const getActiveRouteIndex = () => {
            if (uri === window.location.pathname) {
                return 0;
            }
            if (!routeData.routes) return null;
            return routeData.routes.findIndex((route) =>
                window.location.pathname.includes(route.path)
            );
        };
        setActiveRouteIndex(getActiveRouteIndex());
    }, [uri, routeData.routes]);

    return (
        <div className={`${container} ${className}`}>
            {!routeData.hideNav && navItems.length > 1 && (
                <FlexBar
                    classes={barClasses}
                    buttonContent={buttonContent}
                    activeItemIndex={activeRouteIndex}
                    isActiveItemInVisible={false}
                >
                    {navItems.map((item, index) => {
                        if (item.hideInNav) return null;
                        return (
                            <CustomLink
                                to={item.absolutePath}
                                className={link}
                                currentClassName={active}
                                partiallyCurrentClassName={active}
                                key={`account-dashboard-link-box-${index}`}
                                state={{ preventScroll }}
                            >
                                <div className={linkContent}>{item.label}</div>
                            </CustomLink>
                        );
                    })}
                </FlexBar>
            )}
        </div>
    );
};

export default DashboardNavigation;
