// extracted by mini-css-extract-plugin
export var button = "modal-module--button--+KMvx";
export var closed = "modal-module--closed--Hfd9J";
export var container = "modal-module--container--WrZuL";
export var content = "modal-module--content--mlJtn";
export var error = "modal-module--error--fAgNa";
export var fullWidth = "modal-module--full-width--lBycT";
export var icons = "modal-module--icons--NTw2z";
export var modal = "modal-module--modal--SoQ-i";
export var small = "modal-module--small--mbxFS";
export var title = "modal-module--title--NUlGi";