import React from 'react';
import { errorHandler } from '../../communication/utils';
import AddMealModal from '../../components/organisms/custom-modals/add-meal-modal';
import AddMealTypeModal from '../../components/organisms/custom-modals/add-meal-type-modal';
import AddShoppingListModal from '../../components/organisms/custom-modals/add-shopping-list-modal';
import ChoosePlanModal from '../../components/organisms/custom-modals/choose-plan-modal';
import MealModal from '../../components/organisms/custom-modals/meal-modal';
import RecipeModal from '../../components/organisms/custom-modals/recipe-modal';
import RecipeSearchModal from '../../components/organisms/custom-modals/recipe-search-modal';
import { config } from '../../config';
import { addDays, subtractDays } from '../../utills/date-utils';
import { downloadBlob } from '../../utills/download-blob';
import { getMealTypesForSelect } from '../../utills/get-meal-types-for-select';
import {
    delPositionsByMelaType,
    excludedTypesArr,
    generateAndPopulateUnits,
    generateColumns,
    generateDatesArray,
    generateRows,
    mapArrayToObj,
    posKey,
    updateObjInArray,
} from '../../utills/meal-planer';
import { addModalAction, removeModalAction } from '../actions/actions-modals';
import { clearAllAlerts, setAlert } from '../alerts/alerts.actions';
import {
    addMeal,
    addNote,
    delNote,
    fetchPlanerData,
    getPlanerData,
    getPos,
    MEAL_PLANER,
    MEAL_PLANER_POS,
    MEAL_PLANER_REPORT,
    MEAL_PLANER_SHOPPING_LIST,
    searchRecipes,
    sendAddItem,
    sendDelMealType,
    sendDelPosition,
    sendNoteAction,
    sendPlanerAction,
    sendReport,
    setPlanerData,
    types,
    updateItem,
} from './meal-planer.actions';
import { getCurrentProfileId } from '../profile/profile.selectors';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';
import { navigate } from 'gatsby';

const { mealTypesMap, coordinates } = config.mealPlanner;

const { endpoints, formsStatusMap } = config;

const successMsg = {
    type: 'success',
    content: `Udało się zaktualizować planer`,
};

const shoppingListSuccessMsg = {
    type: 'success',
    content: `Udało się dodać składniki do listy zakupowej`,
};

const plannerReportSuccessMsg = {
    type: 'success',
    content: `'Brawo! Twój raport został wysłany. Sprawdzimy go jak najszybciej i wrócimy z informacją zwrotną.☺️!'`,
};

const plannerCopySuccessMsg = {
    type: 'success',
    content: 'Menu obecnego tygodnia skopiowano na następny tydzień.',
};

const mealPlanerMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;
    next(action);

    let { dateFrom, dateTo, planerUnits, rows, positions } = getState().mealPlaner;
    let currentProfileId = getCurrentProfileId(getState());
    let error, dates, columns, units, fetchConfig, newRows, newPositions, dispatchData;

    switch (type) {
        // trigger actions
        case types.ADD_ITEM:
            dispatch(searchRecipes(payload));
            break;

        // modal actions
        case types.SEARCH_RECIPES:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <RecipeSearchModal
                            modalId={modalId}
                            onAddOwnMeal={() => {
                                dispatch(removeModalAction(modalId));
                                dispatch(addMeal(payload));
                            }}
                            onRecipeSelect={(recipe) => {
                                dispatch(sendAddItem({ ...recipe, ...payload }, { modalId }));
                            }}
                        />
                    ),
                })
            );
            break;

        case types.ADD_MEAL:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <AddMealModal
                            modalId={modalId}
                            onReturn={() => {
                                dispatch(removeModalAction(modalId));
                                dispatch(searchRecipes(payload));
                            }}
                            onSubmitNewMeal={(formikValues, formikBag, modalId) => {
                                dispatch({
                                    type: types.FETCH_MEAL,
                                    payload: {
                                        method: 'post',
                                        body: {
                                            name: formikValues.name,
                                            description: formikValues.description,
                                            photoBase64: formikValues.mealPhoto[0]?.content,
                                        },
                                        url: `${endpoints.mealPlanner.planer}/${currentProfileId}/meals`,
                                    },
                                    meta: {
                                        action: 'add',
                                        modalId,
                                        dateFrom,
                                        dateTo,
                                        date: payload.date,
                                        sequence: payload.sequence,
                                        mealTypeId: payload.mealTypeId,
                                        formikBag: formikBag,
                                    },
                                });
                            }}
                        />
                    ),
                })
            );
            break;

        case types.DEL_ITEM:
            dispatch(
                addModalAction({
                    modalKey: 'CONFIRMATION_MODAL',
                    modalProps: {
                        content: 'Chcesz usunąć tą pozycję?',
                        onConfirm: () => {
                            dispatch(sendDelPosition(payload));
                        },
                    },
                })
            );
            break;

        case types.DEL_MEAL_TYPE:
            dispatch(
                addModalAction({
                    modalKey: 'CONFIRMATION_MODAL',
                    modalProps: {
                        content: 'Chcesz usunąć wszystkie pozycje z tego typu posiłku?',
                        onConfirm: () => {
                            dispatch(sendDelMealType(payload));
                        },
                    },
                })
            );
            break;

        case types.GENERATE_PLANER_SHOPPING_LIST:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <AddShoppingListModal
                            modalId={modalId}
                            onNewListSubmit={(data, formikBag) => {
                                formikBag.setStatus(formsStatusMap.loading);
                                dispatch({
                                    type: types.SEND_SHOPPING_LIST_ACTION,
                                    payload: {
                                        body: {
                                            listId: null,
                                            listName: data.name,
                                        },
                                    },
                                    meta: { modalId },
                                });
                            }}
                            onEditListSubmit={(data, formikBag) => {
                                formikBag.setStatus(formsStatusMap.loading);
                                dispatch({
                                    type: types.SEND_SHOPPING_LIST_ACTION,
                                    payload: {
                                        body: {
                                            listId: data.selectedList.value,
                                            listName: null,
                                        },
                                    },
                                    meta: { modalId },
                                });
                            }}
                        />
                    ),
                })
            );
            break;

        case types.CHOOSE_PLAN:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <ChoosePlanModal
                            modalId={modalId}
                            confirm={true}
                            onPlanSelectApproval={(data) => {
                                dispatch({
                                    type: types.SEND_CLEAR_POSITIONS,
                                    meta: {
                                        modalId,
                                        dateFrom,
                                        dateTo,
                                        templateId: data.templateId,
                                    },
                                });
                            }}
                        />
                    ),
                })
            );
            break;

        case types.SHOW_ITEM_PREVIEW:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => {
                        if (positions[posKey(payload.positionId)].mealId) {
                            return (
                                <MealModal
                                    modalId={modalId}
                                    id={payload.positionId}
                                    onSubmitEditMeal={(
                                        formikValues,
                                        formikBag,
                                        mealId,
                                        modalId
                                    ) => {
                                        dispatch({
                                            type: types.FETCH_MEAL,
                                            payload: {
                                                method: 'patch',
                                                body: {
                                                    name: formikValues.name,
                                                    description: formikValues.description,
                                                    photoBase64: formikValues.mealPhoto[0]?.content,
                                                },
                                                url: `${endpoints.mealPlanner.planer}/${currentProfileId}/meals/${mealId}`,
                                            },
                                            meta: {
                                                modalId,
                                                dateFrom,
                                                dateTo,
                                                formikBag: formikBag,
                                            },
                                        });
                                    }}
                                />
                            );
                        } else {
                            return (
                                <RecipeModal
                                    modalId={modalId}
                                    id={payload.positionId}
                                    unitId={payload.unitId}
                                    onGetRecipe={() => {
                                        dispatch(
                                            getPos(payload.positionId, {
                                                modalId,
                                            })
                                        );
                                    }}
                                    onNoteSubmit={(data) => {
                                        dispatch(addNote(data));
                                    }}
                                    onNoteDelete={(data) => {
                                        dispatch(delNote(data));
                                    }}
                                    onPortionsUpdate={(data) => {
                                        dispatch(updateItem(data, { expand: true }));
                                    }}
                                />
                            );
                        }
                    },
                })
            );
            break;

        case types.ADD_MEAL_TYPE:
            dispatch(
                addModalAction({
                    renderComponent: (modalId) => (
                        <AddMealTypeModal
                            modalId={modalId}
                            options={getMealTypesForSelect(
                                mealTypesMap,
                                true,
                                excludedTypesArr(rows)
                            )}
                            onSubmit={(formValues) => {
                                dispatch(removeModalAction(modalId));
                                dispatch(
                                    setPlanerData({
                                        rows: {
                                            ...rows,
                                            [formValues.mealType.key]: {
                                                ...rows[formValues.mealType.key],
                                                dimension: 'auto',
                                            },
                                        },
                                    })
                                );
                            }}
                        />
                    ),
                })
            );
            break;

        case types.GENERATE_PLANNER_REPORT:
            dispatch(
                addModalAction({
                    modalKey: 'PLANNER_REPORT_MODAL',
                    modalProps: {
                        onSubmit: (values, helpers, modalId) => {
                            helpers.setStatus(formsStatusMap.loading);
                            dispatch(
                                sendReport(values, {
                                    formikBag: helpers,
                                    modalId,
                                    redirectUrl: meta.redirectUrl,
                                    redirectState: meta.redirectState,
                                })
                            );
                        },
                    },
                })
            );
            break;

        // fetch triggers
        case types.GET_PLANER_DATA:
            fetchConfig = {
                url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${payload.dateFrom}/${payload.dateTo}`,
            };

            dispatch(
                fetchPlanerData(fetchConfig, {
                    initial: true,
                    dateFrom: payload.dateFrom,
                    dateTo: payload.dateTo,
                })
            );
            break;

        case types.GET_NEXT_WEEK:
            dispatch(
                getPlanerData({
                    dateFrom: addDays(dateTo, 1),
                    dateTo: addDays(dateTo, 7),
                })
            );
            break;

        case types.GET_PREV_WEEK:
            dispatch(
                getPlanerData({
                    dateFrom: subtractDays(dateFrom, 7),
                    dateTo: subtractDays(dateFrom, 1),
                })
            );
            break;

        case types.COPY_PLAN_TO_NEXT_WEEK:
            dispatch(clearAllAlerts());
            dispatch({
                type: types.SEND_CLEAR_POSITIONS,
                meta: { ...payload, dateFrom: payload.copyStart, dateTo: payload.copyFinish },
            });
            break;

        case types.GENERATE_PLANER_PDF:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.FETCH_PLANNER_PDF_REQUEST,
                    types.FETCH_PLANNER_PDF_SUCCESS,
                    types.FETCH_PLANNER_PDF_FAIL,
                ],
                payload: {
                    request: {
                        url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${dateFrom}/${dateTo}/pdf`,
                        headers: { ...getAuthorizationBearerHeader() },
                        responseType: 'blob',
                    },
                },
                meta: {
                    entity: MEAL_PLANER,
                    ...action.meta,
                },
            });
            break;

        case types.FETCH_PLANNER_PDF_SUCCESS:
            const pdfFileBlob = new Blob([payload.data], {
                type: 'application/pdf',
            });
            const fileName = `Planer posiłków od ${dateFrom} do ${dateTo}`;
            downloadBlob(pdfFileBlob, fileName);
            break;

        case types.SEND_REPORT_ACTION:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_REPORT_REQUEST,
                    types.SEND_REPORT_SUCCESS,
                    types.SEND_REPORT_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${dateFrom}/${dateTo}/pdf-send`,
                        headers: { ...getAuthorizationBearerHeader() },
                        data: payload,
                    },
                },
                meta: {
                    entity: MEAL_PLANER_REPORT,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_REPORT_SUCCESS:
            if (action.meta.previousAction.meta?.modalId) {
                dispatch(removeModalAction(action.meta.previousAction.meta.modalId));
                dispatch(setAlert(plannerReportSuccessMsg, MEAL_PLANER_REPORT));
            }
            if (action.meta.previousAction.meta?.redirectUrl) {
                navigate(action.meta.previousAction.meta.redirectUrl, {
                    replace: true,
                    state: action.meta.previousAction.meta?.redirectState,
                });
            }
            break;

        case types.SEND_SHOPPING_LIST_ACTION:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_SHOPPING_LIST_REQUEST,
                    types.SEND_SHOPPING_LIST_SUCCESS,
                    types.SEND_SHOPPING_LIST_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${dateFrom}/shopping-list`,
                        data: payload.body,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER_SHOPPING_LIST,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_SHOPPING_LIST_SUCCESS:
            dispatch(setAlert(shoppingListSuccessMsg, MEAL_PLANER_SHOPPING_LIST));
            dispatch(removeModalAction(action.meta.previousAction.meta.modalId));
            break;

        case types.SEND_ADD_RECIPE:
            fetchConfig = {
                body: [
                    {
                        action: 'add',
                        date: payload.date,
                        mealTypeId: payload.mealTypeId,
                        recipeId: payload.recipeId,
                        mealId: payload.mealId,
                        portions: 1,
                        sequence: payload.sequence || 0,
                    },
                ],
            };
            dispatch(sendPlanerAction(fetchConfig, action.meta));
            break;

        case types.UPDATE_ITEM:
            fetchConfig = {
                body: [
                    {
                        action: 'update',
                        positionId: payload.positionId,
                        date: payload.date,
                        portions: payload.portions,
                        mealTypeId: payload.mealTypeId,
                        sequence: payload.sequence,
                    },
                ],
            };
            dispatch(sendPlanerAction(fetchConfig, action.meta));
            break;

        case types.SEND_DEL_POSITION:
            fetchConfig = {
                body: [
                    {
                        action: 'delete',
                        positionId: payload,
                    },
                ],
            };
            dispatch(sendPlanerAction(fetchConfig));
            break;

        case types.SEND_DEL_MEAL_TYPE:
            fetchConfig = {
                body: delPositionsByMelaType(payload, planerUnits),
            };
            dispatch(sendPlanerAction(fetchConfig));
            break;

        case types.ADD_POSITION_NOTE:
            fetchConfig = {
                method: 'post',
                url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${payload.id}/notes`,
                body: payload.formValues,
            };
            dispatch(
                sendNoteAction(fetchConfig, {
                    positionId: payload.id,
                    unitId: payload.unitId,
                    formikBag: payload.formikBag,
                })
            );
            break;

        case types.DEL_POSITION_NOTE:
            fetchConfig = {
                method: 'delete',
                url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${payload.positionId}/notes/${payload.id}`,
            };
            dispatch(
                sendNoteAction(fetchConfig, {
                    positionId: payload.positionId,
                    unitId: payload.unitId,
                })
            );
            break;

        // fetch
        case types.FETCH_PLANER_DATA:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_PLANER_REQUEST,
                    types.SEND_PLANER_SUCCESS,
                    types.SEND_PLANER_FAIL,
                ],
                payload: {
                    request: {
                        method: payload.method ? payload.method : 'get',
                        data: payload.body ? payload.body : null,
                        url: payload.url,
                        params: {
                            expand: 'notes',
                        },
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_PLANER_SUCCESS:
            dates = generateDatesArray(
                meta.previousAction.meta.dateFrom,
                meta.previousAction.meta.dateTo
            );
            columns = generateColumns(dates, coordinates);
            newPositions = mapArrayToObj(payload.data);
            units = generateAndPopulateUnits(mealTypesMap, dates, payload.data);
            newRows = generateRows(units, mealTypesMap, coordinates, payload.data);

            if (action.meta.previousAction.meta?.modalId) {
                dispatch(removeModalAction(action.meta.previousAction.meta.modalId));
            }
            dispatch(
                setPlanerData({
                    planerUnits: units,
                    columns,
                    rows: newRows,
                    positions: newPositions,
                    dateFrom: meta.previousAction.meta.dateFrom,
                    dateTo: meta.previousAction.meta.dateTo,
                })
            );
            break;

        case types.SEND_CLEAR_POSITIONS:
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_CLEAR_POSITIONS_REQUEST,
                    types.SEND_CLEAR_POSITIONS_SUCCESS,
                    types.SEND_CLEAR_POSITIONS_FAIL,
                ],
                payload: {
                    request: {
                        method: 'delete',
                        url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${meta.dateFrom}/${meta.dateTo}`,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_CLEAR_POSITIONS_SUCCESS:
            // the presence of templateId means that we want to fill the week with the selected template
            if (meta?.previousAction?.meta?.templateId) {
                fetchConfig = {
                    method: 'post',
                    body: {
                        templateId: meta.previousAction.meta.templateId,
                        dateFrom: meta.previousAction.meta.dateFrom,
                        dateTo: meta.previousAction.meta.dateTo,
                    },
                    url: `${endpoints.mealPlanner.planer}/${currentProfileId}`,
                };
                dispatch(fetchPlanerData(fetchConfig, { ...meta.previousAction.meta }));
            }

            // the presence of copyStart means that we want to copy the current week to the next week
            if (meta?.previousAction?.meta?.copyStart) {
                dispatch({
                    types: [
                        types.SEND_COPY_PLAN_TO_NEXT_WEEK_REQUEST,
                        types.SEND_COPY_PLAN_TO_NEXT_WEEK_SUCCESS,
                        types.SEND_COPY_PLAN_TO_NEXT_WEEK_FAIL,
                    ],
                    payload: {
                        request: {
                            url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${dateFrom}/${dateTo}/copy`,
                            method: 'POST',
                            data: {
                                copyStart: meta.previousAction.meta.copyStart,
                                copyFinish: meta.previousAction.meta.copyFinish,
                            },
                            headers: { ...getAuthorizationBearerHeader() },
                        },
                    },
                    meta: {
                        entity: MEAL_PLANER,
                        ...action.meta,
                    },
                });
            }

            break;

        case types.SEND_COPY_PLAN_TO_NEXT_WEEK_SUCCESS:
            dispatch(setAlert(plannerCopySuccessMsg, MEAL_PLANER));
            break;

        case types.FETCH_MEAL:
            if (meta.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch(clearAllAlerts());
            dispatch({
                types: [types.SEND_MEAL_REQUEST, types.SEND_MEAL_SUCCESS, types.SEND_MEAL_FAIL],
                payload: {
                    request: {
                        method: payload.method ? payload.method : 'get',
                        data: payload.body ? payload.body : null,
                        url: payload.url,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_MEAL_SUCCESS:
            dispatch(
                sendPlanerAction(
                    {
                        body: [
                            {
                                action: meta.previousAction.meta.action,
                                date: meta.previousAction.meta.date,
                                mealTypeId: meta.previousAction.meta.mealTypeId,
                                mealId: payload.data.id,
                                portions: 1,
                                sequence: meta.previousAction.meta.sequence,
                            },
                        ],
                    },
                    { ...meta.previousAction.meta }
                )
            );
            break;

        case types.SEND_PLANER_ACTION:
            dispatch(clearAllAlerts());

            if (meta?.formikBag) {
                meta.formikBag.setStatus(formsStatusMap.loading);
            }

            dispatch({
                types: [
                    types.SEND_PLANER_ACTION_REQUEST,
                    types.SEND_PLANER_ACTION_SUCCESS,
                    types.SEND_PLANER_ACTION_FAIL,
                ],
                payload: {
                    request: {
                        method: 'post',
                        data: payload.body ? payload.body : null,
                        url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${dateFrom}/${dateTo}`,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_PLANER_ACTION_SUCCESS:
            dates = generateDatesArray(dateFrom, dateTo);
            columns = generateColumns(dates, coordinates);
            newPositions = mapArrayToObj(payload.data, getState().mealPlaner.positions);
            units = generateAndPopulateUnits(mealTypesMap, dates, payload.data);
            newRows = generateRows(units, mealTypesMap, coordinates, payload.data);

            if (action.meta.previousAction.meta?.formikBag) {
                action.meta.previousAction.meta.formikBag.setStatus(formsStatusMap.success);
            }

            if (action.meta.previousAction.meta?.modalId) {
                dispatch(removeModalAction(action.meta.previousAction.meta.modalId));
            }

            dispatch(setAlert(successMsg));

            dispatch(
                setPlanerData({
                    planerUnits: units,
                    positions: newPositions,
                    rows: newRows,
                    columns,
                })
            );
            break;

        case types.FETCH_POSITION:
            fetchConfig = {
                url: `${endpoints.mealPlanner.planer}/${currentProfileId}/${payload}`,
                params: {
                    expand: `recipe,recipe.preparationSteps,recipe.ingredients,meal,notes`,
                },
                headers: { ...getAuthorizationBearerHeader() },
            };
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_PLANER_POSITION_REQUEST,
                    types.SEND_PLANER_POSITION_SUCCESS,
                    types.SEND_PLANER_POSITION_FAIL,
                ],
                payload: {
                    request: { ...fetchConfig },
                },
                meta: {
                    entity: MEAL_PLANER_POS,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_PLANER_POSITION_SUCCESS:
            dispatchData = {
                positions: {
                    ...positions,
                    [posKey(payload.data.id)]: { ...payload.data },
                },
            };

            if (meta?.previousAction?.meta?.unitId) {
                dispatchData.planerUnits = {
                    ...planerUnits,
                    [meta.previousAction.meta.unitId]: {
                        ...planerUnits[meta.previousAction.meta.unitId],
                        positions: updateObjInArray(
                            planerUnits[meta.previousAction.meta.unitId].positions,
                            payload.data,
                            'positionId'
                        ),
                    },
                };
            }

            dispatch(setPlanerData(dispatchData));
            break;

        case types.SEND_PLANER_POSITION_FAIL:
            error = errorHandler(action.error);
            error.map((err) => dispatch(setAlert(err, MEAL_PLANER)));

            dispatch(removeModalAction(meta.previousAction.meta.modalId));
            break;

        case types.SEND_POSITION_NOTE_ACTION:
            if (action.meta?.formikBag) {
                action.meta.formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch(clearAllAlerts());
            dispatch({
                types: [
                    types.SEND_POSITION_NOTE_REQUEST,
                    types.SEND_POSITION_NOTE_SUCCESS,
                    types.SEND_POSITION_NOTE_FAIL,
                ],
                payload: {
                    request: {
                        method: payload.method ? payload.method : 'get',
                        data: payload.body ? payload.body : null,
                        url: payload.url,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MEAL_PLANER,
                    ...action.meta,
                },
            });
            break;

        case types.SEND_POSITION_NOTE_SUCCESS:
            dispatch(
                getPos(meta.previousAction.meta.positionId, {
                    unitId: meta.previousAction.meta.unitId,
                })
            );
            break;

        case types.SEND_PLANER_ACTION_FAIL:
        case types.SEND_PLANER_FAIL:
        case types.SEND_CLEAR_POSITIONS_FAIL:
        case types.FETCH_PLANNER_PDF_FAIL:
        case types.SEND_MEAL_FAIL:
        case types.SEND_POSITION_NOTE_FAIL:
        case types.SEND_SHOPPING_LIST_FAIL:
        case types.SEND_REPORT_FAIL:
        case types.SEND_COPY_PLAN_TO_NEXT_WEEK_FAIL:
            if (action.meta.previousAction.meta?.formikBag) {
                action.meta.previousAction.meta.formikBag.setStatus(formsStatusMap.fail);
            }

            error = errorHandler(action.error);
            error.map((err) => dispatch(setAlert(err, MEAL_PLANER)));
            break;

        // no default
    }
};

export default mealPlanerMiddleware;
