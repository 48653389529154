import './src/style/global-style.scss';
import './static/ck-edytor-content.css';

export { default as wrapRootElement } from './src/redux/store';

export const shouldUpdateScroll = ({ pathname, prevRouterProps, routerProps }) => {
    const prevPathname = prevRouterProps?.location.pathname;
    const preventScroll = routerProps.location.state?.preventScroll;
    return !(prevPathname === pathname || preventScroll);
};
