import React from 'react';
import { useDispatch } from 'react-redux';

import {
    container,
    name,
    role,
    button,
    description,
} from './transition-team-member-card.module.scss';
import { ITransitionTeamMember } from '../../models/transition.model';
import { addModalAction } from '../../redux/actions/actions-modals';

import Avatar from '../atoms/avatar';
import Button from '../atoms/button';

interface ITransitionTeamMemberCardProps {
    className?: string;
    teamMember: ITransitionTeamMember;
    showButton?: boolean;
    showDescription?: boolean;
}

const TransitionTeamMemberCard: React.FC<ITransitionTeamMemberCardProps> = ({
    className = '',
    teamMember,
    showButton = true,
    showDescription = false,
}) => {
    const dispatch = useDispatch();

    const handleShowMore = () => {
        dispatch(
            addModalAction({
                modalKey: 'TRANSITION_TEAM_MEMBER_MODAL',
                modalProps: { teamMember },
            })
        );
    };

    return (
        <div className={`${container} ${className}`}>
            <Avatar avatarUri={teamMember.photoUrl} hasShadow={false} size="big" />
            <h3 className={name}>{teamMember.displayName}</h3>
            <p className={role}>{teamMember.role}</p>
            {showDescription && <p className={description}>{teamMember.description}</p>}
            {showButton && (
                <Button
                    className={button}
                    size="small"
                    color="blankYellow"
                    onClick={handleShowMore}
                >
                    {copy.more}
                </Button>
            )}
        </div>
    );
};

const copy = {
    more: 'Więcej o ekspercie',
};

export default TransitionTeamMemberCard;
