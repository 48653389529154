import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import {
    title,
    wrapper,
    loader,
    scrolled as scrolledClass,
    actions,
    holder,
    planer,
    actionBtns,
    rangeSelector,
    dateSwitcher,
    weekFromParams,
} from './meal-planer.module.scss';
import { config } from '../../config';
import Bowl from '../../assets/images/svg/icon-hot-bowl.svg';
import Pdf from '../../assets/images/svg/icon-pdf.svg';
import Plus from '../../assets/images/svg/icon-plus.svg';
import mealTypesEmotics from '../../assets/images/svg/meal-planner-emotics.svg';
import List from '../../assets/images/svg/icon-list.svg';
import Envelope from '../../assets/images/svg/envelope-paper.svg';
import Copy from '../../assets/images/svg/copy.svg';
import {
    addMealType,
    choosePlan,
    copyPlanToNextWeek,
    generatePdf,
    generateReport,
    generateShoppingList,
    getPlanerData,
    MEAL_PLANER,
} from '../../redux/meal-planer/meal-planer.actions';
import { selectLoaderByEntity } from '../../redux/ui/ui.selectors';
import { generateDatesArrayFromState } from '../../utills/meal-planer';
import { addDays } from '../../utills/date-utils';
import { addModalAction } from '../../redux/actions/actions-modals';

import Emotic from '../../components/atoms/emotic';
import Loader from '../../components/atoms/loader';
import Title from '../../components/atoms/title';
import ActionButtons from '../../components/molecules/meal-planner/action-buttons';
import DayScroller from '../../components/molecules/meal-planner/meal-day-scroller';
import WeekSwitcher from '../../components/molecules/meal-planner/week-switcher';
import MealPlannerGrid from '../../components/organisms/meal-planner/meal-planer-grid';
import SEO from '../../components/seo';
import Main from '../../layouts/main';
import { getUrlParamValue } from '../../utills/get-url-param-value';
import { getAbsolutePath } from '../../routes';

const MealPlaner = ({ routeData }) => {
    const dispatch = useDispatch();
    const paramFrom = getUrlParamValue('from');
    const paramTo = getUrlParamValue('to');
    const submitType = getUrlParamValue('submit');
    const isWeekFromParams = !!(paramFrom && paramTo);
    const generateReportRedirectUrl = getAbsolutePath('APP_MY_TRANSITION_WEEKLY');

    const [scrolled, setScrolled] = useState(false);

    useLayoutEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const buttons = [
        {
            id: 3,
            action: () =>
                dispatch(
                    generateReport({
                        redirectUrl: generateReportRedirectUrl,
                        redirectState: { from: paramFrom, to: paramTo, submitType },
                    })
                ),
            title: 'Wyślij menu',
            color: 'yellow',
            icon: Envelope,
        },
        {
            id: 1,
            action: () => dispatch(choosePlan()),
            title: 'Skorzystaj z jadłospisów Strefy Przemian',
            color: 'blank',
            icon: Bowl,
        },
        {
            id: 2,
            action: () => dispatch(addMealType()),
            title: 'Dodaj typ posiłku',
            color: 'blank',
            icon: Plus,
        },
        {
            id: 4,
            action: () => dispatch(generatePdf()),
            title: 'Zapisz do pdf',
            color: 'blank',
            icon: Pdf,
        },
        {
            id: 5,
            action: () => dispatch(generateShoppingList()),
            title: 'Wygeneruj listę zakupów',
            color: 'blank',
            icon: List,
        },
        {
            id: 6,
            action: () => {
                dispatch(
                    addModalAction({
                        modalKey: 'CONFIRMATION_MODAL',
                        modalProps: {
                            title: 'Na pewno?',
                            content:
                                'Zawartość planera w następnym tygodniu zostanie całkowicie zastąpiona menu obecnego tygodnia.',
                            onConfirm: () => {
                                dispatch(
                                    copyPlanToNextWeek({
                                        copyStart: addDays(state.dateFrom, 7),
                                        copyFinish: addDays(state.dateTo, 7),
                                    })
                                );
                            },
                        },
                    })
                );
            },
            title: 'Skopiuj na następny tydzień',
            color: 'blank',
            icon: Copy,
        },
    ];

    const state = useSelector((state) => ({
        dateFrom: state.mealPlaner.dateFrom,
        dateTo: state.mealPlaner.dateTo,
        rows: state.mealPlaner.rows,
        columns: state.mealPlaner.columns,
        dates: generateDatesArrayFromState(state),
        planerUnits: state.mealPlaner.planerUnits,
        status: selectLoaderByEntity(state, MEAL_PLANER),
    }));

    useEffect(() => {
        if (isWeekFromParams) {
            dispatch(
                addModalAction({
                    type: 'info',
                    title: 'Informacje z raportu tygodniowego zostały zapisane',
                    content: (
                        <>
                            Zaktualizuj menu i następnie kliknij w <strong>"Wyślij menu"</strong>{' '}
                            żeby wrócić do tworzenia raportu tygodniowego
                        </>
                    ),
                    buttonText: 'Rozumiem',
                    noEmotics: true,
                    size: 'small',
                })
            );
        }
    }, [dispatch, isWeekFromParams]);

    useEffect(() => {
        dispatch(
            getPlanerData({
                dateFrom: paramFrom || state.dateFrom,
                dateTo: paramTo || state.dateTo,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Main
            className={`${wrapper} ${isWeekFromParams ? weekFromParams : ''}`}
            hasNewsletterForm={false}
            isPaddingEqualToHeader
        >
            <SEO title={routeData.label} />
            <Helmet>
                {/* eslint-disable-next-line jsx-a11y/html-has-lang*/}
                <html className={'no-overflow'} />
            </Helmet>
            <section className={title}>
                <Emotic emotic={mealTypesEmotics} />
                <Title>Planer menu</Title>
            </section>
            <section className={holder}>
                <div
                    className={`
                        ${actions} 
                        ${scrolled ? scrolledClass : ''}
                    `}
                >
                    <WeekSwitcher className={dateSwitcher} />
                    <DayScroller className={rangeSelector} />
                    <ActionButtons className={actionBtns} buttons={buttons} />
                </div>
                {state.status === config.apiStatusMap.loading && <Loader className={loader} />}
                <MealPlannerGrid className={` ${planer} `} scrolled={scrolled} state={state} />
            </section>
        </Main>
    );
};

export default MealPlaner;
