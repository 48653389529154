import { types } from './info-bars.actions';

const { SET_INFO_BARS, RESTORE_INFO_BARS_INITIAL_STATE } = types;

export const initialState = [];

export default function infoBarsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_INFO_BARS:
            return payload;
        case RESTORE_INFO_BARS_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
}
