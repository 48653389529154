import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';

import {
    container,
    header,
    title,
    content,
    list,
    loader,
    loading,
    errorText,
} from './transition-daily.module.scss';
import { IDailyReport } from '../../../models/transition.model';
import { TStatus } from '../../../models/status.model';
import { addModalAction } from '../../../redux/actions/actions-modals';
import { getWeek } from '../../../utills/get-week';
import { getIsDateInDateRange } from '../../../utills/get-is-date-in-date-range';
import {
    clearTransitionDailyReports,
    deleteTransitionDailyReport,
    getTransitionDailyReports,
} from '../../../redux/transition/transition.actions';
import { getFormattedDate } from '../../../utills/date-utils';
import {
    selectTransitionDailyReports,
    selectTransitionDailyReportsStatus,
    selectTransitionDailyReportStatus,
} from '../../../redux/transition/transition.selectors';
import { getDateRangeName } from '../../../utills/get-date-range-name';

import TransitionWeekSwitcher from '../../molecules/transition-week-switcher';
import DailyReportCard, { IDailyReportCardProps } from '../../molecules/daily-report-card';
import WeekPicker, { IWeekPickerProps } from '../../atoms/week-picker';
import Loader from '../../atoms/loader';

const TRANSITION_START_DATE = '2024-11-25';

const TransitionDaily: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const locationStateWeek = location.state?.week as [Date, Date] | undefined;
    const currentWeek = getWeek(new Date());
    const [week, setWeek] = useState(locationStateWeek || currentWeek);
    const prevWeek = getPrevWeek(week);
    const isPrevWeekBeforeStart = prevWeek[1].getTime() < new Date(TRANSITION_START_DATE).getTime();
    const dateFrom = getFormattedDate(week[0]);
    const dateTo = getFormattedDate(week[1]);
    const dailyReports: IDailyReport[] = useSelector(selectTransitionDailyReports);
    const dailyReportsStatus: TStatus | undefined = useSelector(selectTransitionDailyReportsStatus);
    const dailyReportStatus: TStatus | undefined = useSelector(selectTransitionDailyReportStatus);

    const handleFillReport: IDailyReportCardProps['onFillReport'] = (report) => {
        dispatch(
            addModalAction({
                modalKey: 'DAILY_REPORT_FORM_MODAL',
                modalProps: { report },
            })
        );
    };

    const handleDeleteReport: IDailyReportCardProps['onDeleteReport'] = (report) => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: copy.deleteTitle,
                    content: copy.deleteContent,
                    onConfirm: () => {
                        dispatch(deleteTransitionDailyReport(report.date));
                    },
                },
            })
        );
    };

    const handleWeekSelect: IWeekPickerProps['onWeekSelect'] = (selectedWeek) => {
        setWeek(selectedWeek);
    };

    const handlePrevWeek = () => {
        setWeek((currentWeek) => getPrevWeek(currentWeek));
    };

    const handleNextWeek = () => {
        const nextWeekStart = new Date(week[0]);
        nextWeekStart.setDate(week[0].getDate() + 7);
        const nextWeekEnd = new Date(week[1]);
        nextWeekEnd.setDate(week[1].getDate() + 7);
        setWeek([nextWeekStart, nextWeekEnd]);
    };

    useEffect(() => {
        dispatch(getTransitionDailyReports({ from: dateFrom, to: dateTo }));
    }, [dateFrom, dateTo, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearTransitionDailyReports());
        };
    }, [dispatch]);

    if (!dailyReportsStatus || (dailyReportsStatus === 'loading' && dailyReports.length === 0)) {
        return <Loader className={loader} />;
    }

    if (dailyReportsStatus === 'fail') {
        return <p className={errorText}>{copy.error}</p>;
    }

    return (
        <div
            className={`${container} ${
                dailyReportsStatus === 'loading' || dailyReportStatus === 'loading' ? loading : ''
            }`}
        >
            <div className={header}>
                <h3 className={title}>{getDateRangeName(week)}</h3>
                <WeekPicker
                    week={week}
                    onWeekSelect={handleWeekSelect}
                    minDate={new Date(TRANSITION_START_DATE)}
                    maxDate={currentWeek[1]}
                />
            </div>
            <div className={content}>
                <TransitionWeekSwitcher
                    dailyReports={dailyReports}
                    isPrevDisabled={isPrevWeekBeforeStart}
                    isNextDisabled={getIsDateInDateRange(new Date(), week)}
                    onDaySelect={handleFillReport}
                    onPrev={handlePrevWeek}
                    onNext={handleNextWeek}
                />
                <div className={list}>
                    {dailyReports.map((dailyReport) => {
                        return (
                            <DailyReportCard
                                key={`report-${dailyReport.date}`}
                                dailyReport={dailyReport}
                                onFillReport={handleFillReport}
                                onDeleteReport={handleDeleteReport}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

function getPrevWeek(week: [Date, Date]): [Date, Date] {
    const prevWeekStart = new Date(week[0]);
    prevWeekStart.setDate(week[0].getDate() - 7);
    const prevWeekEnd = new Date(week[1]);
    prevWeekEnd.setDate(week[1].getDate() - 7);
    return [prevWeekStart, prevWeekEnd];
}

const copy = {
    error: 'Nie udało się pobrać raportów. Spróbuj ponownie później...',
    deleteTitle: 'Usunąć raport dzienny?',
    deleteContent: 'Dane raportu dziennego zostaną nieodwracalnie usunięte.',
};

export default TransitionDaily;
