import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import AnimateHeight from 'react-animate-height';

import {
    container,
    inner,
    timer,
    content,
    header,
    headerContent,
    lead,
    button,
    toggleButton,
    desktop,
    mobile,
    actions,
    closeButton,
    open,
} from './info-bar.module.scss';
import { mainGrid } from '../../style/grid.module.scss';
import ChevronIcon from '../../assets/images/svg/chevron-down.svg';
import CloseIcon from '../../assets/images/svg/icon-close.svg';
import { IInfoBar } from '../../models/info-bar.model';

import Markdown from '../hoc/markdown';

export interface IInfoBarProps {
    className?: string;
    infoBar: IInfoBar;
    onClose?(infoBar: IInfoBar): void;
    onAnimationEnd?(): void;
}

// const LEAD_DESKTOP_MAX = 189;
const LEAD_DESKTOP_MAX_WIDTH = 1500;
const LEAD_DESKTOP_START_PX_FONT_SIZE = 18;
const LEAD_DESKTOP_FONT_SIZE_RATIO = LEAD_DESKTOP_START_PX_FONT_SIZE / LEAD_DESKTOP_MAX_WIDTH;

// const LEAD_MOBILE_MAX = 59;
const MOBILE_BREAKPOINT = 585;
const LEAD_MOBILE_MAX_WIDTH = 410;
const LEAD_MOBILE_START_PX_FONT_SIZE = 16;
const LEAD_MOBILE_FONT_SIZE_RATIO = LEAD_MOBILE_START_PX_FONT_SIZE / LEAD_MOBILE_MAX_WIDTH;

const InfoBar: React.FC<IInfoBarProps> = ({ className = '', infoBar, onClose, onAnimationEnd }) => {
    const [countdown, setCountdown] = useState(getCountdown(infoBar.countDownTo));
    const [isOpen, setIsOpen] = useState(false);
    const [fontSize, setFontSize] = useState(LEAD_DESKTOP_START_PX_FONT_SIZE);

    const leadRef = useRef<HTMLDivElement | null>(null);

    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleClose = () => {
        if (infoBar.closePolicy === 'session') {
            const seenInfoBarsString = sessionStorage.getItem('seenInfoBars') || '';
            const seenInfoBars = seenInfoBarsString
                .split(',')
                .filter((id) => !!id)
                .map((id) => Number(id));
            sessionStorage.setItem('seenInfoBars', [...seenInfoBars, infoBar.infoBarId].join(','));
            if (typeof onClose === 'function') {
                onClose(infoBar);
            }
        }
        if (infoBar.closePolicy === 'permanent') {
            const seenInfoBarsString = localStorage.getItem('seenInfoBars') || '';
            const seenInfoBars = seenInfoBarsString
                .split(',')
                .filter((id) => !!id)
                .map((id) => Number(id));
            localStorage.setItem('seenInfoBars', [...seenInfoBars, infoBar.infoBarId].join(','));
            if (typeof onClose === 'function') {
                onClose(infoBar);
            }
        }
    };

    const handleRedirect = async () => {
        handleClose();
        await navigate(infoBar.actionUrl);
    };

    useEffect(() => {
        const handleResize = () => {
            const leadEl = leadRef.current;
            if (!leadEl) return;
            const width = leadEl.getBoundingClientRect().width;
            const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
            const ratio = isMobile ? LEAD_MOBILE_FONT_SIZE_RATIO : LEAD_DESKTOP_FONT_SIZE_RATIO;
            const maxFontSize = isMobile
                ? LEAD_MOBILE_START_PX_FONT_SIZE
                : LEAD_DESKTOP_START_PX_FONT_SIZE;
            const newFontSize = width * ratio;
            setFontSize(newFontSize > maxFontSize ? maxFontSize : newFontSize);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(getCountdown(infoBar.countDownTo));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [infoBar.countDownTo]);

    return (
        <div className={`${container} ${mainGrid} ${className} ${isOpen ? open : ''}`}>
            <div className={inner}>
                {countdown && <div className={timer}>{countdown}</div>}
                <div className={header}>
                    <div
                        className={headerContent}
                        ref={leadRef}
                        style={{ fontSize: `${fontSize}px` }}
                    >
                        <div className={`${lead} ${desktop}`}>
                            <Markdown>{infoBar.leadDesktop}</Markdown>
                        </div>
                        <Markdown className={`${lead} ${mobile}`}>{infoBar.leadMobile}</Markdown>
                        {infoBar.contentDesktop && (
                            <button className={`${toggleButton} ${desktop}`} onClick={handleToggle}>
                                <ChevronIcon />
                            </button>
                        )}
                        {infoBar.contentMobile && (
                            <button className={`${toggleButton} ${mobile}`} onClick={handleToggle}>
                                <ChevronIcon />
                            </button>
                        )}
                    </div>
                    <div className={actions}>
                        <button className={button} onClick={handleRedirect}>
                            {infoBar.buttonText}
                        </button>
                        {infoBar.closePolicy !== 'restrict' && (
                            <button className={closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </button>
                        )}
                    </div>
                </div>
                <AnimateHeight
                    className={content}
                    height={isOpen ? 'auto' : 0}
                    onAnimationEnd={onAnimationEnd}
                >
                    {infoBar.contentDesktop && (
                        <Markdown className={desktop}>{infoBar.contentDesktop}</Markdown>
                    )}
                    {infoBar.contentMobile && (
                        <Markdown className={mobile}>{infoBar.contentMobile}</Markdown>
                    )}
                </AnimateHeight>
            </div>
        </div>
    );
};

const DAY_IN_MS = 86400000;
const HOUR_IN_MS = 3600000;
const MINUTE_IN_MS = 60000;

function getCountdown(date: string | null) {
    if (!date) return null;
    const to = new Date(date).getTime();
    const now = new Date().getTime();
    let left = to - now > 0 ? to - now : 0;
    const days = Math.floor(left / DAY_IN_MS);
    left = left % DAY_IN_MS;
    const hours = Math.floor(left / HOUR_IN_MS);
    left = left % HOUR_IN_MS;
    const minutes = Math.floor(left / MINUTE_IN_MS);
    left = left % MINUTE_IN_MS;
    const seconds = Math.floor(left / 1000);
    const arr = [days, hours, minutes, seconds].map((item) => (item < 10 ? `0${item}` : item));
    return (
        <>
            {arr[0]} <span>dni</span> : {arr[1]} <span>godzin</span> : {arr[2]} <span>minut</span> :{' '}
            {arr[3]} <span>sekund</span>
        </>
    );
}

export default InfoBar;
