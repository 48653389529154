import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import QueryEdgeNode from '../models/query-edge-node.model';
import { ITransitionTeamMember } from '../models/transition.model';
import getNodes from '../utills/get-nodes';

interface IUseTransitionTeamMembersQueryResult {
    allTransitionTeamMember: QueryEdgeNode<ITransitionTeamMember>;
}

export const useTransitionTeamMembers = () => {
    const { allTransitionTeamMember } = useStaticQuery<IUseTransitionTeamMembersQueryResult>(query);
    return useMemo(() => getNodes(allTransitionTeamMember) || [], [allTransitionTeamMember]);
};

const query = graphql`
    query {
        allTransitionTeamMember {
            edges {
                node {
                    profileId
                    photoUrl
                    displayName
                    role
                    description
                }
            }
        }
    }
`;
