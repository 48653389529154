import { graphql, useStaticQuery } from 'gatsby';

import { IOption } from '../models/option.model';

interface IUseTransitionEmotionOptionsQueryResult {
    transitionEmotions: {
        positive: IOption[];
        neutral: IOption[];
        negative: IOption[];
    } | null;
}

export const useTransitionEmotionOptions = () => {
    const { transitionEmotions } = useStaticQuery<IUseTransitionEmotionOptionsQueryResult>(query);
    return transitionEmotions || { positive: [], neutral: [], negative: [] };
};

const query = graphql`
    query {
        transitionEmotions {
            positive {
                label
                value
            }
            neutral {
                label
                value
            }
            negative {
                label
                value
            }
        }
    }
`;
