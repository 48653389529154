import React from 'react';

import { config } from './config';
import { entity } from './rbac/permissions';
import isGamificationEnabled from './utills/gamification/is-gamification-enabled';
import isPartnersZoneEnabled from './utills/partners-zone/is-partners-zone-enabled';

import PrivateRoute from './components/hoc/private-route';
import MyAccount from './templates/my-account';
import Welcome from './components/organisms/my-account/welcome';
import AccountData from './components/organisms/my-account/account-data';
import Culinary from './components/organisms/my-account/culinary';
import ShoppingHistory from './components/organisms/my-account/order-history/shopping-history';
import SocialZone from './components/organisms/my-account/social-zone';
import FilesToDownload from './components/organisms/my-account/files-to-download';
import Dashboard from './components/organisms/my-account/dashboard';
import ChangePassword from './components/organisms/my-account/change-password';
import MySubscriptions from './components/organisms/my-account/my-subscriptions.tsx';
import Newsletter from './components/organisms/my-account/newsletter/newsletter';
import Exercises from './components/organisms/my-account/exercises';
import ExerciseAtlas from './templates/exercise-atlas';
import TrainingAtlas from './templates/training-atlas';
import ExercisePage from './templates/exercise-page';
import MySurveys from './components/organisms/my-account/my-surveys';
import Survey from './components/organisms/survey/survey';
import SurveyPage from './templates/survey-page';
import ChooseProfileTemplate from './templates/choose-profile-template';
import TrainingPage from './templates/training-page';
import PersonalTrainingPage from './templates/personal-training-page';
import PersonalTraining from './components/organisms/personal-training';
import PersonalTrainingPreviousList from './components/organisms/personal-training/previous/list/personal-training-previous-list';
import Facebook from './templates/facebook';
import RecipeList from './templates/recipe-list';
import MealPlaner from './templates/meal-planer/meal-planer';
import Recipe from './templates/recipe';
import RecipeAdd from './templates/recipe-add';
import MyShoppingLists from './components/organisms/my-account/my-shopping-lists';
import ShoppingList from './components/organisms/my-account/shopping-list';
import AddShoppingList from './components/organisms/add-shopping-list';
import Forbidden from './templates/forbidden-403';
import CreditCardPage from './templates/credit-card-data';
import OrderSummary from './components/organisms/my-account/order-history/order-summary';
import Messages from './components/organisms/my-account/messenger/messages/messages';
import Attachments from './components/organisms/my-account/messenger/attachments/attachments';
import Course from './components/organisms/course/course';
import MealPlanerTemplates from './templates/meal-planer-template/meal-planer-templates';
import OrderResult from './templates/order-result';
import IntroVideoPage from './templates/intro-video-page';
import Addresses from './components/organisms/my-account/addresses';
import AddressAdd from './components/organisms/my-account/address-add';
import Notifications from './components/organisms/my-account/notifications';
import NotificationSettings from './components/organisms/my-account/notification-settings';
import GamificationIntroduction from './templates/gamification/gamification-introduction';
import GamificationHistory from './templates/gamification/gamification-history';
import GamificationTariff from './templates/gamification/gamification-tariff';
import GamificationBusinessPartnersZone from './templates/gamification/gamification-business-partners-zone';
import GamificationPartnersCodes from './templates/gamification/gamification-partners-codes';
import Forum from './components/organisms/my-account/forum';
import ForumContainer from './components/organisms/my-account/forum-container';
import Thread from './components/organisms/my-account/thread';
import Courses from './components/organisms/my-account/courses';
import ShopOrderSummary from './templates/shop-order-summary';
import TransitionDashboard from './components/organisms/my-account/transition-dashboard';
import TransitionDaily from './components/organisms/my-account/transition-daily';
import TransitionWeekly from './components/organisms/my-account/transition-weekly';
import TransitionHistory from './components/organisms/my-account/transition-history';
import TransitionWeeklyReportDetails from './components/organisms/my-account/transition-weekly-report-details';

const PARTNERS_ZONE = [
    {
        path: 'strefa-partnerow',
        label: 'Strefa partnerów',
        key: 'APP_MY_ACCOUNT_YOUR_KETONS_PARTNERS_ZONE',
        Component: GamificationBusinessPartnersZone,
    },
    {
        path: 'partnerzy-kody',
        label: 'Partnerzy - kody',
        key: 'APP_MY_ACCOUNT_YOUR_KETONS_PARTNERS_CODES',
        Component: GamificationPartnersCodes,
    },
];

const GAMIFICATION = [
    {
        path: 'twoje-ketony',
        label: 'Twoje nagrody',
        key: 'APP_MY_ACCOUNT_YOUR_KETONS',
        Component: Dashboard,
        redirect: {
            from: '/app/moje-konto/twoje-ketony',
            to: '/app/moje-konto/twoje-ketony/wstep',
        },
        routes: [
            {
                path: 'wstep',
                label: 'Wstęp',
                key: 'APP_MY_ACCOUNT_YOUR_KETONS_INTRO',
                Component: GamificationIntroduction,
            },
            {
                path: 'taryfikator',
                label: 'Taryfikator',
                key: 'APP_MY_ACCOUNT_YOUR_KETONS_TARIFF',
                Component: GamificationTariff,
            },
            {
                path: 'historia',
                label: 'Historia',
                key: 'APP_MY_ACCOUNT_YOUR_KETONS_HISTORY',
                Component: GamificationHistory,
            },
            ...(isPartnersZoneEnabled() ? PARTNERS_ZONE : []),
        ],
    },
];

export const ROUTES = [
    {
        path: 'app',
        label: '',
        key: 'APP',
        redirect: {
            from: '/app',
            to: '/app/moje-konto',
        },
        Component: null,
        routes: [
            {
                path: 'wybor-profilu',
                label: 'Wybór profilu',
                key: 'APP_CHOOSE_PROFILE',
                Component: ChooseProfileTemplate,
                routes: [],
            },
            {
                path: 'kurs',
                key: 'APP_COURSE',
                Component: null,
                routes: [
                    {
                        path: ':id',
                        key: 'APP_COURSE_VIEW',
                        Component: Course,
                    },
                ],
            },
            {
                path: 'moje-konto',
                label: 'Moje konto',
                key: 'APP_MY_ACCOUNT',
                redirect: {
                    from: '/app/moje-konto',
                    to: '/app/moje-konto/strefa-uzytkownika/witaj',
                },
                Component: MyAccount,
                routes: [
                    {
                        path: 'strefa-uzytkownika',
                        label: 'Strefa użytkownika',
                        key: 'APP_MY_ACCOUNT_USER_ZONE',
                        Component: Dashboard,
                        redirect: {
                            from: '/app/moje-konto/strefa-uzytkownika',
                            to: '/app/moje-konto/strefa-uzytkownika/witaj',
                        },
                        routes: [
                            {
                                path: 'witaj',
                                label: 'Witaj',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_HOME',
                                Component: Welcome,
                                routes: [],
                            },
                            {
                                path: 'twoja-ankieta',
                                label: 'Twoja ankieta',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_SURVEY',
                                Component: null,
                                hideNav: true,
                                routes: [
                                    {
                                        path: '/',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_SURVEY_HOME',
                                        Component: MySurveys,
                                    },
                                    {
                                        path: 'wypelnij',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_SURVEY_FILL',
                                        Component: Survey,
                                    },
                                    {
                                        path: 'edycja/:surveyToProfileId',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_SURVEY_EDIT',
                                        Component: Survey,
                                    },
                                    {
                                        path: 'podglad/:surveyToProfileId',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_SURVEY_PREVIEW',
                                        Component: Survey,
                                        routeProps: {
                                            preview: true,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'historia-platnosci',
                                label: 'Historia płatności',
                                key: 'APP_MY_ACCOUNT_SHOPPING_HISTORY',
                                Component: null,
                                hideNav: true,
                                routes: [
                                    {
                                        path: '/',
                                        label: 'Historia płatności',
                                        key: 'APP_MY_ACCOUNT_SHOPPING_HISTORY_HOME',
                                        Component: ShoppingHistory,
                                        routes: [],
                                    },
                                    {
                                        path: ':id',
                                        label: 'Zamówienie',
                                        key: 'APP_MY_ACCOUNT_SHOPPING_HISTORY_ORDER',
                                        Component: OrderSummary,
                                    },
                                ],
                            },
                            {
                                path: 'twoj-abonament',
                                label: 'Twój abonament',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_SUBSCRIPTION',
                                Component: MySubscriptions,
                                routes: [],
                            },
                            {
                                path: 'pliki-do-pobrania',
                                label: 'Pliki do pobrania',
                                key: 'APP_MY_ACCOUNT_FILES',
                                Component: FilesToDownload,
                            },
                            {
                                path: 'zmien-haslo',
                                label: 'Zmień hasło',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_CHANGE_PASSWORD',
                                Component: ChangePassword,
                                routes: [],
                            },
                            {
                                path: 'dane-konta',
                                label: 'Dane konta',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_ACCOUNT_DATA',
                                Component: AccountData,
                                routes: [],
                            },
                            {
                                path: 'newsletter',
                                label: 'Newsletter',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_NEWSLETTER',
                                Component: Newsletter,
                                routes: [],
                            },
                            {
                                path: 'dane-do-faktury',
                                label: 'Dane do faktury',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESSES_INVOICE',
                                Component: null,
                                hideNav: true,
                                routes: [
                                    {
                                        path: '/',
                                        label: 'Twoje dane',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESSES_INVOICE_HOME',
                                        Component: Addresses,
                                        routeProps: {
                                            addressType: config.addressTypes.invoice,
                                        },
                                        routes: [],
                                    },
                                    {
                                        path: 'dodaj-adres',
                                        label: 'Dodaj adres',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESS_INVOICE_ADD',
                                        Component: AddressAdd,
                                        routeProps: {
                                            addressType: config.addressTypes.invoice,
                                        },
                                        routes: [],
                                    },
                                    {
                                        path: 'edytuj/:id',
                                        label: 'Edytuj adres',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESS_INVOICE_EDIT',
                                        Component: AddressAdd,
                                        routeProps: {
                                            addressType: config.addressTypes.invoice,
                                        },
                                        routes: [],
                                    },
                                ],
                            },
                            {
                                path: 'dane-adresowe',
                                label: 'Dane adresowe',
                                key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESSES_DELIVERY',
                                Component: null,
                                hideNav: true,
                                routes: [
                                    {
                                        path: '/',
                                        label: 'Twoje adresy',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESSES_DELIVERY_HOME',
                                        Component: Addresses,
                                        routeProps: {
                                            addressType: config.addressTypes.delivery,
                                        },
                                        routes: [],
                                    },
                                    {
                                        path: 'dodaj-adres',
                                        label: 'Dodaj adres',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESS_DELIVERY_ADD',
                                        Component: AddressAdd,
                                        routeProps: {
                                            addressType: config.addressTypes.delivery,
                                        },
                                        routes: [],
                                    },
                                    {
                                        path: 'edytuj/:id',
                                        label: 'Edytuj adres',
                                        key: 'APP_MY_ACCOUNT_USER_ZONE_ADDRESS_DELIVERY_EDIT',
                                        Component: AddressAdd,
                                        routeProps: {
                                            addressType: config.addressTypes.delivery,
                                        },
                                        routes: [],
                                    },
                                ],
                            },
                            {
                                path: 'strefa-social',
                                label: 'Strefa social',
                                key: 'APP_MY_ACCOUNT_SOCIAL_ZONE',
                                Component: SocialZone,
                            },
                        ],
                    },
                    {
                        path: 'dieta',
                        label: 'Dieta',
                        key: 'APP_MY_ACCOUNT_CULINARY',
                        Component: Dashboard,
                        redirect: {
                            from: '/app/moje-konto/dieta',
                            to: '/app/moje-konto/dieta/przepisy',
                        },
                        routes: [
                            {
                                path: 'przepisy',
                                label: 'Przepisy',
                                key: 'APP_MY_ACCOUNT_CULINARY_HOME',
                                Component: Culinary,
                                routes: [],
                            },
                            {
                                path: 'lista-zakupowa',
                                label: 'Lista zakupowa',
                                key: 'APP_MY_ACCOUNT_SOPPING_LIST',
                                Component: null,
                                hideNav: true,
                                routes: [
                                    {
                                        path: '/',
                                        label: 'Lista zakupowa',
                                        key: 'APP_MY_ACCOUNT_SOPPING_LIST_HOME',
                                        Component: MyShoppingLists,
                                    },
                                    {
                                        path: ':id',
                                        label: 'Lista zakupowa',
                                        key: 'APP_MY_ACCOUNT_SOPPING_LIST_VIEW',
                                        Component: ShoppingList,
                                    },
                                    {
                                        path: 'dodaj',
                                        label: 'Lista zakupowa',
                                        key: 'APP_MY_ACCOUNT_SOPPING_LIST_ADD',
                                        Component: AddShoppingList,
                                    },
                                    {
                                        path: 'edytuj',
                                        label: 'Lista zakupowa',
                                        key: 'APP_MY_ACCOUNT_SOPPING_LIST_EDIT',
                                        Component: null,
                                        routes: [
                                            {
                                                path: ':id',
                                                label: 'Lista zakupowa',
                                                key: 'APP_MY_ACCOUNT_SOPPING_LIST_EDIT_VIEW',
                                                Component: AddShoppingList,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'przemiana',
                        label: 'Moja przemiana',
                        key: 'APP_MY_TRANSITION',
                        Component: Dashboard,
                        hideNav: true,
                        routes: [
                            {
                                path: '/',
                                label: '',
                                key: 'APP_MY_TRANSITION_DASHBOARD',
                                Component: TransitionDashboard,
                                redirect: {
                                    from: '/app/moje-konto/przemiana',
                                    to: '/app/moje-konto/przemiana/raport-dzienny',
                                },
                                routes: [
                                    {
                                        path: 'raport-dzienny',
                                        label: 'Raport dzienny',
                                        key: 'APP_MY_TRANSITION_DAILY',
                                        Component: TransitionDaily,
                                    },
                                    {
                                        path: 'raport-tygodniowy',
                                        label: 'Raport tygodniowy',
                                        key: 'APP_MY_TRANSITION_WEEKLY',
                                        Component: TransitionWeekly,
                                    },
                                    {
                                        path: 'historia',
                                        label: 'Historia raportów',
                                        key: 'APP_MY_TRANSITION_HISTORY',
                                        Component: TransitionHistory,
                                    },
                                ],
                            },
                            {
                                path: 'raport',
                                label: '',
                                key: 'APP_MY_TRANSITION_REPORT',
                                Component: null,
                                routes: [
                                    {
                                        path: '/:id',
                                        label: '',
                                        key: 'APP_MY_TRANSITION_REPORT_VIEW',
                                        Component: TransitionWeeklyReportDetails,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'strefa-rozwoju',
                        label: 'Strefa rozwoju',
                        key: 'APP_MY_ACCOUNT_EXERCISES',
                        hideNav: true,
                        Component: Dashboard,
                        routes: [
                            {
                                path: '/',
                                label: 'Ćwiczenia',
                                key: 'APP_MY_ACCOUNT_EXERCISES_HOME',
                                Component: Exercises,
                                routes: [],
                            },
                            {
                                path: 'kursy',
                                label: 'Kursy',
                                key: 'APP_MY_ACCOUNT_COURSES',
                                Component: null,
                                redirect: {
                                    from: '/app/moje-konto/strefa-rozwoju/kursy',
                                    to: '/app/moje-konto/strefa-rozwoju/kursy/moje',
                                },
                                routes: [
                                    {
                                        path: 'moje',
                                        label: 'Kursy',
                                        key: 'APP_MY_ACCOUNT_COURSES_MY',
                                        Component: Courses,
                                        routeProps: {
                                            courseType: 'course',
                                            isMine: true,
                                        },
                                    },
                                    {
                                        path: 'dodatkowe',
                                        label: 'Kursy',
                                        key: 'APP_MY_ACCOUNT_COURSES_NOT_MINE',
                                        Component: Courses,
                                        routeProps: {
                                            courseType: 'course',
                                            isMine: false,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'programy-treningowe',
                                label: 'Programy treningowe',
                                key: 'APP_MY_ACCOUNT_TRAINING_PROGRAMS',
                                Component: null,
                                redirect: {
                                    from: '/app/moje-konto/strefa-rozwoju/programy-treningowe',
                                    to: '/app/moje-konto/strefa-rozwoju/programy-treningowe/moje',
                                },
                                routes: [
                                    {
                                        path: 'moje',
                                        label: 'Programy treningowe',
                                        key: 'APP_MY_ACCOUNT_TRAINING_PROGRAMS_MY',
                                        Component: Courses,
                                        routeProps: {
                                            courseType: 'trainingProgram',
                                            isMine: true,
                                        },
                                    },
                                    {
                                        path: 'dodatkowe',
                                        label: 'Programy treningowe',
                                        key: 'APP_MY_ACCOUNT_TRAINING_PROGRAMS_NOT_MINE',
                                        Component: Courses,
                                        routeProps: {
                                            courseType: 'trainingProgram',
                                            isMine: false,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'wyzwania',
                                label: 'Wyzwania',
                                key: 'APP_MY_ACCOUNT_CHALLENGES',
                                Component: null,
                                redirect: {
                                    from: '/app/moje-konto/strefa-rozwoju/wyzwania',
                                    to: '/app/moje-konto/strefa-rozwoju/wyzwania/moje',
                                },
                                routes: [
                                    {
                                        path: 'moje',
                                        label: 'Wyzwania',
                                        key: 'APP_MY_ACCOUNT_CHALLENGES_MY',
                                        Component: Courses,
                                        routeProps: {
                                            courseType: 'challenge',
                                            isMine: true,
                                        },
                                    },
                                    {
                                        path: 'dodatkowe',
                                        label: 'Wyzwania',
                                        key: 'APP_MY_ACCOUNT_CHALLENGES_NOT_MINE',
                                        Component: Courses,
                                        routeProps: {
                                            courseType: 'challenge',
                                            isMine: false,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'grupa',
                        label: 'Grupa',
                        key: 'APP_MY_ACCOUNT_FORUM',
                        Component: Dashboard,
                        redirect: {
                            from: '/app/moje-konto/grupa',
                            to: '/app/moje-konto/grupa/posty',
                        },
                        routes: [
                            {
                                path: 'posty',
                                label: 'Posty',
                                key: 'APP_MY_ACCOUNT_FORUM_HOME',
                                Component: ForumContainer,
                                routes: [
                                    {
                                        path: ':threadCategoryId',
                                        key: 'APP_MY_ACCOUNT_FORUM_CATEGORY',
                                        Component: Forum,
                                    },
                                ],
                            },
                            {
                                path: 'ulubione',
                                label: 'Ulubione',
                                key: 'APP_MY_ACCOUNT_FORUM_FAVORITE',
                                Component: ForumContainer,
                                routeProps: {
                                    isFavorite: true,
                                },
                                routes: [
                                    {
                                        path: ':threadCategoryId',
                                        key: 'APP_MY_ACCOUNT_FORUM_FAVORITE_CATEGORY',
                                        Component: Forum,
                                        routeProps: {
                                            isFavorite: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'grupa/post',
                        key: 'APP_MY_ACCOUNT_FORUM_THREAD_HOME',
                        hideNav: true,
                        hideInNav: true,
                        Component: null,
                        routes: [
                            {
                                path: ':threadId',
                                key: 'APP_MY_ACCOUNT_FORUM_THREAD_VIEW',
                                Component: Thread,
                            },
                        ],
                    },
                    {
                        path: 'wiadomosci',
                        label: 'Wiadomości',
                        key: 'APP_MY_ACCOUNT_MESSENGER',
                        Component: Dashboard,
                        hideInNav: true,
                        redirect: {
                            from: '/app/moje-konto/wiadomosci',
                            to: '/app/moje-konto/wiadomosci/konwersacja',
                        },
                        routeProps: {
                            counter: config.counters.messages,
                        },
                        routes: [
                            {
                                path: 'konwersacja',
                                label: 'Wiadomości',
                                key: 'APP_MY_ACCOUNT_MESSENGER_MESSAGES',
                                Component: Messages,
                            },
                            {
                                path: 'zalaczniki',
                                label: 'Załączniki',
                                key: 'APP_MY_ACCOUNT_MESSENGER_ATTACHMENTS',
                                Component: Attachments,
                            },
                        ],
                    },
                    {
                        path: 'powiadomienia',
                        label: 'Powiadomienia',
                        key: 'APP_MY_ACCOUNT_NOTIFICATIONS',
                        Component: Dashboard,
                        redirect: {
                            from: '/app/moje-konto/powiadomienia',
                            to: '/app/moje-konto/powiadomienia/lista',
                        },
                        routeProps: {
                            counter: config.counters.notifications,
                        },
                        routes: [
                            {
                                path: 'lista',
                                label: 'Lista powiadomień',
                                key: 'APP_MY_ACCOUNT_NOTIFICATIONS_HOME',
                                Component: Notifications,
                            },
                            {
                                path: 'ustawienia',
                                label: 'Ustawienia',
                                key: 'APP_MY_ACCOUNT_NOTIFICATIONS_SETTINGS',
                                Component: NotificationSettings,
                            },
                        ],
                    },
                    ...(isGamificationEnabled() ? GAMIFICATION : []),
                ],
            },
            {
                path: 'twoja-ankieta',
                key: 'APP_SURVEY',
                Component: SurveyPage,
            },
            {
                path: 'cwiczenia',
                label: 'Ćwiczenia',
                key: 'APP_EXERCISE',
                Component: null,
                routes: [
                    {
                        path: '/',
                        label: 'Witaj',
                        key: 'APP_EXERCISE_LIST',
                        Component: ExerciseAtlas,
                        routes: [],
                    },
                    {
                        path: '/:id',
                        label: 'Ćwiczenie',
                        key: 'APP_EXERCISE_VIEW',
                        Component: ExercisePage,
                        routes: [],
                    },
                ],
            },
            {
                path: 'trening-personalny',
                label: 'Trening personalny',
                key: 'APP_MY_ACCOUNT_PERSONAL_TRAINING',
                Component: PersonalTrainingPage,
                rbacAction: entity.PERSONAL_TRAININGS,
                redirect: {
                    from: '/app/trening-personalny',
                    to: '/app/trening-personalny/biezacy-trening',
                },
                routes: [
                    {
                        path: 'biezacy-trening',
                        label: 'Trening personalny biezący',
                        key: 'APP_MY_ACCOUNT_PERSONAL_TRAINING_CURRENT',
                        Component: PersonalTraining,
                        routes: [],
                    },
                    {
                        path: 'poprzedni-trening',
                        label: 'Trening personalny poprzednie',
                        key: 'APP_MY_ACCOUNT_PERSONAL_TRAINING_PREVIOUS',
                        Component: null,
                        routes: [
                            {
                                path: '/',
                                label: 'Trening personalny poprzedni wybrany',
                                key: 'APP_MY_ACCOUNT_PERSONAL_TRAINING_PREVIOUS_LIST',
                                Component: PersonalTrainingPreviousList,
                                routes: [],
                            },
                            {
                                path: '/:id',
                                label: 'Trening personalny poprzedni wybrany',
                                key: 'APP_MY_ACCOUNT_PERSONAL_TRAINING_PREVIOUS_CHOOSEN',
                                Component: PersonalTraining,
                                routes: [],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'treningi',
                label: 'Treningi',
                key: 'APP_TRAININGS',
                Component: null,
                routes: [
                    {
                        path: '/',
                        label: 'Lista treningów',
                        key: 'APP_TRAININGS_LIST',
                        Component: TrainingAtlas,
                        routes: [],
                    },
                    {
                        path: '/:id',
                        label: 'Trening',
                        key: 'APP_TRAININGS_VIEW',
                        Component: TrainingPage,
                        routes: [],
                    },
                ],
            },
            {
                path: 'wszystkie-przepisy',
                label: 'Wszystkie przepisy',
                key: 'APP_PUBLIC_RECIPES',
                Component: RecipeList,
                routeProps: {
                    type: config.recipeTypesMap.public,
                    description: `Baza receptur dostępnych na platformie Strefa Przemian`,
                },
            },
            {
                path: 'twoje-przepisy',
                label: 'Twoje przepisy',
                key: 'APP_YOUR_RECIPES',
                Component: RecipeList,
                rbacAction: entity.RECIPES,
                routeProps: {
                    type: config.recipeTypesMap.your,
                    description: `Lista stworzonych lub dodanych przez Ciebie receptur`,
                },
            },
            {
                path: 'ulubione-przepisy',
                label: 'Ulubione przepisy',
                key: 'APP_FAVORITE_RECIPES',
                Component: RecipeList,
                routeProps: {
                    type: config.recipeTypesMap.favorite,
                    description: `Baza Twoich ulubionych receptur`,
                },
            },
            {
                path: 'przepis',
                key: 'APP_RECIPE',
                Component: null,
                routes: [
                    {
                        path: ':id',
                        key: 'APP_RECIPE_VIEW',
                        Component: Recipe,
                    },
                ],
            },
            {
                path: 'twoj-przepis',
                key: 'APP_RECIPE_YOUR',
                Component: null,
                rbacAction: entity.RECIPES,
                routes: [
                    {
                        path: ':id',
                        key: 'APP_RECIPE_YOUR_VIEW',
                        Component: Recipe,
                        routeProps: {
                            type: config.recipeTypesMap.your,
                        },
                    },
                    {
                        path: 'dodaj',
                        key: 'APP_RECIPE_YOUR_ADD',
                        Component: RecipeAdd,
                    },
                    {
                        path: 'edytuj',
                        key: 'APP_RECIPE_YOUR_EDIT',
                        Component: null,
                        routes: [
                            {
                                path: '/:id',
                                key: 'APP_RECIPE_YOUR_EDIT_VIEW',
                                Component: RecipeAdd,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'planer-posilkow',
                label: 'Planer posiłków',
                key: 'APP_MEAL_PLANER',
                Component: MealPlaner,
            },
            {
                path: 'menu-planer-posilkow',
                label: 'Menu planera posiłków',
                key: 'APP_MEAL_PLANER_TEMPLATE',
                rbacAction: entity.MEAL_PLANER_TEMPLATE,
                Component: null,
                routes: [
                    {
                        path: '/',
                        label: 'Menu planera posiłków',
                        key: 'APP_MEAL_PLANER_TEMPLATE_LIST',
                        Component: MealPlanerTemplates,
                    },
                    {
                        path: '/:id',
                        label: 'Edycja szablonu posiłku',
                        key: 'APP_MEAL_PLANER_TEMPLATE_ID',
                        Component: MealPlanerTemplates,
                    },
                ],
            },
            {
                path: '/facebook',
                label: 'facebook',
                key: 'APP_FACEBOOK',
                Component: Facebook,
                routes: [],
            },
            {
                path: 'brak-dostepu',
                label: 'Brak dostępu',
                key: 'APP_FORBIDDEN',
                Component: Forbidden,
                routes: [],
            },
            {
                path: 'film-wprowadzajacy',
                key: 'APP_ONBOARDING_MOVIE',
                Component: IntroVideoPage,
                label: 'Film wprowadzający',
                routes: [],
            },
        ],
    },
];

export const PUBLIC_ROUTES = [
    {
        path: 'public-app',
        key: 'PUBLIC_APP',
        Component: null,
        routes: [
            {
                path: 'kurs',
                key: 'PUBLIC_APP_COURSE',
                Component: null,
                routes: [
                    {
                        path: ':id',
                        key: 'PUBLIC_APP_COURSE_VIEW',
                        Component: Course,
                    },
                ],
            },
        ],
    },
];

export const ORDER_ROUTES = [
    {
        path: 'zamowienie',
        key: 'ORDER',
        Component: null,
        redirect: {
            from: '/zamowienie',
            to: '/zamowienie/podsumowanie',
        },
        routes: [
            {
                path: 'podsumowanie',
                key: 'ORDER_STEPS',
                Component: ShopOrderSummary,
            },
            {
                path: 'karta',
                key: 'ORDER_CREDIT_CARD',
                Component: null,
                routes: [
                    {
                        path: ':id',
                        key: 'ORDER_CREDIT_CARD_ID',
                        Component: CreditCardPage,
                    },
                ],
            },
            {
                path: 'karta-ponowienie',
                key: 'ORDER_CREDIT_CARD_RETRY',
                Component: null,
                routes: [
                    {
                        path: ':id',
                        key: 'ORDER_CREDIT_CARD_RETRY_ID',
                        Component: CreditCardPage,
                        routeProps: {
                            isRetry: true,
                        },
                    },
                ],
            },
            {
                path: 'wynik',
                key: 'ORDER_RESULT',
                label: 'Wynik zamówienia',
                Component: OrderResult,
            },
            {
                path: 'problem-z-weryfikacja-platnosci',
                key: 'ORDER_RESULT_VERIFICATION_ERROR',
                label: 'Wynik zamówienia',
                Component: OrderResult,
                routeProps: {
                    isVerificationError: true,
                },
            },
            {
                path: 'nowa-subskrypcja',
                label: 'Nowa subskrypcja',
                key: 'ORDER_SUBSCRIPTION_RESULT',
                Component: OrderResult,
                routeProps: {
                    isSubscriptionResult: true,
                },
            },
        ],
    },
];

export const renderRoutes = (routes, Component = PrivateRoute) => {
    return routes.map((route) => {
        if (route.routes && route.routes.length) {
            return (
                <Component
                    path={route.path}
                    key={route.key}
                    routeData={route}
                    Component={route.Component}
                    redirect={route.redirect}
                    rbacAction={route.rbacAction}
                    {...route.routeProps}
                >
                    {renderRoutes(route.routes, Component)}
                </Component>
            );
        }
        return (
            <Component
                path={route.path}
                key={route.key}
                routeData={route}
                Component={route.Component}
                redirect={route.redirect}
                rbacAction={route.rbacAction}
                {...route.routeProps}
            />
        );
    });
};

export const getAbsoluteNav = (routes, prefix = '') => {
    return routes.map((route) => {
        return {
            key: route.key,
            label: route.label,
            path: route.path,
            routeProps: route.routeProps,
            hideInNav: route.hideInNav,
            absolutePath:
                route.path === '/' || route.path === './' ? prefix : `${prefix}/${route.path}`,
            routes:
                route.routes?.length > 0 && !route.hideNav
                    ? getAbsoluteNav(route.routes, `${prefix}/${route.path}`)
                    : [],
        };
    });
};

export const getRouteNode = (routes, nodeKey, prefix) => {
    for (const route of routes) {
        if (route.key === nodeKey) {
            route.prefix =
                route.path === '/' || route.path === './' ? prefix : `${prefix}/${route.path}`;
            return route;
        }
        if (route.routes && routes.length > 0) {
            const childRoute = getRouteNode(
                route.routes,
                nodeKey,
                route.path === '/' || route.path === './' ? prefix : `${prefix}/${route.path}`
            );
            if (childRoute) {
                return childRoute;
            }
        }
    }
};

export const getRouteNodeAbsoluteNav = (nodeKey, routes = ROUTES) => {
    const node = getRouteNode(routes, nodeKey, '');
    return getAbsoluteNav(node.routes, node.prefix);
};

export const getAbsolutePath = (nodeKey, routes = ROUTES) => {
    const node = getRouteNode(routes, nodeKey, '');
    if (node.prefix.includes(node.path)) {
        return node.prefix;
    }
    return `${node.prefix}/${node.path}`;
};
