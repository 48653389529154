// extracted by mini-css-extract-plugin
export var back = "transition-weekly-report-details-module--back--Dr+qy";
export var backIcon = "transition-weekly-report-details-module--back-icon--MYgjU";
export var box = "transition-weekly-report-details-module--box--OWA+-";
export var boxes = "transition-weekly-report-details-module--boxes--y5yqt";
export var checkbox = "transition-weekly-report-details-module--checkbox--epiUE";
export var checkboxLabel = "transition-weekly-report-details-module--checkbox-label--U1esg";
export var container = "transition-weekly-report-details-module--container--uXeiq";
export var content = "transition-weekly-report-details-module--content--n-wNh";
export var errorText = "transition-weekly-report-details-module--error-text--zImXI";
export var header = "transition-weekly-report-details-module--header--abutU";
export var headerTop = "transition-weekly-report-details-module--header-top--MWVZY";
export var loader = "transition-weekly-report-details-module--loader--7ZYmh";
export var name = "transition-weekly-report-details-module--name--QQ49W";
export var sent = "transition-weekly-report-details-module--sent--AkyJ5";
export var statusBox = "transition-weekly-report-details-module--status-box--1jRhB";
export var statusDot = "transition-weekly-report-details-module--status-dot--RRwx-";