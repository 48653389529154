import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

import {
    container,
    read,
    icon,
    contentBox,
    titleText,
    contentText,
    date,
    button,
    blurBox,
} from './notification.module.scss';
import { markNotificationAsReadAction } from '../../redux/notifications/notifications.actions';

import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

const Notification = ({ className = '', item, TitleTag }) => {
    const dispatch = useDispatch();
    const { message, readAt, plannedAt, messageId } = item;
    const [isVisible, setIsVisible] = useState(!!readAt);

    const handleRead = () => {
        setIsVisible(true);
        dispatch(markNotificationAsReadAction(messageId));
    };

    useEffect(() => {
        if (readAt) {
            setIsVisible(true);
        }
    }, [readAt]);

    return (
        <div className={`${container} ${className} ${readAt ? read : ''}`}>
            {!isVisible && (
                <div className={blurBox}>
                    <Button className={button} onClick={handleRead} size="small">
                        {copy.read}
                    </Button>
                </div>
            )}
            <span className={icon}>i</span>
            <div className={contentBox}>
                <TitleTag className={titleText}>{message.title}</TitleTag>
                {message.content && <Markdown className={contentText}>{message.content}</Markdown>}
                <Moment className={date} unix={true} locale="pl" format="HH:MM • DD MMMM YYYY">
                    {plannedAt}
                </Moment>
            </div>
        </div>
    );
};

const copy = {
    read: 'Przeczytaj',
};

Notification.propTypes = {
    className: PropTypes.string,
    item: PropTypes.shape({
        message: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string,
        }),
        plannedAt: PropTypes.number.isRequired,
        readAt: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
    }).isRequired,
    TitleTag: PropTypes.string,
};

Notification.defaultProps = {
    className: '',
    TitleTag: 'h2',
};

export default Notification;
