import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, WindowLocation } from '@reach/router';

import {
    container,
    header,
    title,
    buttonInner,
    content,
    loader,
    errorText,
    loading,
} from './transition-weekly.module.scss';
import PaperPlaneIcon from '../../../assets/images/svg/paper-plane.svg';
import { IWeeklyReport } from '../../../models/transition.model';
import { TStatus } from '../../../models/status.model';
import { addModalAction } from '../../../redux/actions/actions-modals';
import {
    selectTransitionDailyReportStatus,
    selectTransitionWeeklyReports,
    selectTransitionWeeklyReportsStatus,
    selectTransitionWeeklyReportStatus,
} from '../../../redux/transition/transition.selectors';
import {
    clearTransitionWeeklyReports,
    getTransitionWeeklyReports,
} from '../../../redux/transition/transition.actions';
import { getDateRangeName } from '../../../utills/get-date-range-name';

import Button from '../../atoms/button';
import WeeklyReportCard from '../../molecules/weekly-report-card';
import Loader from '../../atoms/loader';

interface ITransitionWeeklyProps {
    className?: string;
    children?: React.ReactNode;
}

const TransitionWeekly: React.FC<ITransitionWeeklyProps> = () => {
    const dispatch = useDispatch();
    const weeklyReports: IWeeklyReport[] = useSelector(selectTransitionWeeklyReports);
    const weeklyReportsStatus: TStatus | undefined = useSelector(
        selectTransitionWeeklyReportsStatus
    );
    const weeklyReportStatus: TStatus | undefined = useSelector(selectTransitionWeeklyReportStatus);
    const dailyReportStatus: TStatus | undefined = useSelector(selectTransitionDailyReportStatus);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const location: WindowLocation<{
        from: string;
        to: string;
        submitType: 'all' | 'single';
    } | null> = useLocation();
    const locationFrom = location.state?.from;
    const locationTo = location.state?.to;
    const locationSubmit = location.state?.submitType;

    const handleSendAllReports = () => {
        dispatch(
            addModalAction({
                modalKey: 'SEND_WEEKLY_REPORT_MODAL',
            })
        );
    };

    useEffect(() => {
        if (!weeklyReports.length) return;
        if (!locationFrom || !locationTo || !locationSubmit) return;
        if (locationSubmit === 'all') {
            dispatch(
                addModalAction({
                    modalKey: 'SEND_WEEKLY_REPORT_MODAL',
                })
            );
        }
        if (locationSubmit === 'single') {
            const weekId = weeklyReports.find((report) => report.from === locationFrom)?.weekId;
            if (!weekId) return;
            dispatch(
                addModalAction({
                    modalKey: 'SEND_WEEKLY_REPORT_MODAL',
                    modalProps: { weekId },
                })
            );
        }
        location.state = null;
    }, [dispatch, location, locationFrom, locationSubmit, locationTo, weeklyReports]);

    useEffect(() => {
        dispatch(getTransitionWeeklyReports());

        return () => {
            dispatch(clearTransitionWeeklyReports());
        };
    }, [dispatch]);

    useEffect(() => {}, [dispatch]);

    if (
        !weeklyReportsStatus ||
        weeklyReportsStatus === 'idle' ||
        (weeklyReportsStatus === 'loading' && weeklyReports.length === 0)
    ) {
        return <Loader className={loader} />;
    }

    if (weeklyReportsStatus === 'fail') {
        return <p className={errorText}>{copy.error}</p>;
    }

    return (
        <div
            className={`${container} ${
                weeklyReportsStatus === 'loading' ||
                weeklyReportStatus === 'loading' ||
                dailyReportStatus === 'loading'
                    ? loading
                    : ''
            }`}
        >
            <div className={header}>
                <h3 className={title}>
                    {getDateRangeName([
                        new Date(weeklyReports[0]?.from),
                        new Date(weeklyReports[weeklyReports.length - 1]?.to),
                    ])}
                </h3>
                <Button color="yellow" size="small" onClick={handleSendAllReports}>
                    <span className={buttonInner}>
                        <PaperPlaneIcon />
                        {copy.sendAll}
                    </span>
                </Button>
            </div>
            <div className={content}>
                {weeklyReports.map((weeklyReport) => {
                    return (
                        <WeeklyReportCard
                            key={`weekly-report-${weeklyReport.from}-${weeklyReport.to}`}
                            report={weeklyReport}
                        />
                    );
                })}
            </div>
        </div>
    );
};

const copy = {
    error: 'Nie udało się pobrać raportów. Spróbuj ponownie później...',
    sendAll: 'Wyślij wszystkie raporty tygodniowe',
};

export default TransitionWeekly;
