// extracted by mini-css-extract-plugin
export var bottom = "weekly-report-card-module--bottom--jeWO9";
export var container = "weekly-report-card-module--container--NoFkQ";
export var content = "weekly-report-card-module--content--dt5kQ";
export var dailyReportButton = "weekly-report-card-module--daily-report-button--debjA";
export var dateText = "weekly-report-card-module--date-text--iiUsY";
export var dayButton = "weekly-report-card-module--day-button--WAEWh";
export var days = "weekly-report-card-module--days--ZLAMp";
export var daysList = "weekly-report-card-module--days-list--Hs+NR";
export var deleteButton = "weekly-report-card-module--delete-button--hkU37";
export var header = "weekly-report-card-module--header--pZaMV";
export var open = "weekly-report-card-module--open--fcWzi";
export var sendBox = "weekly-report-card-module--send-box--Xpdx6";
export var sendButton = "weekly-report-card-module--send-button--VtSQK";
export var sendButtonInner = "weekly-report-card-module--send-button-inner--566zs";
export var sendInner = "weekly-report-card-module--send-inner--o+vDH";
export var sent = "weekly-report-card-module--sent--8aygv";
export var timeToSend = "weekly-report-card-module--time-to-send--bGk1i";
export var toggleBox = "weekly-report-card-module--toggle-box--ASWJf";
export var toggleButton = "weekly-report-card-module--toggle-button--JCc0o";
export var toggleContent = "weekly-report-card-module--toggle-content--zL69k";
export var toggleIcon = "weekly-report-card-module--toggle-icon--A-O3g";