import React from 'react';

import { container, reports, header } from './transition-dashboard.module.scss';
import { IRouteData } from '../../../models/router.model';

import DashboardNavigation from '../../molecules/dashboard-navigation';
import TransitionScores from './transition-scores';
import TransitionMeasurements from './transition-measurements';
import TransitionGallery from './transition-gallery';
import TransitionTeamSlider from '../transition-team-slider';

interface ITransitionDashboardProps {
    className?: string;
    children?: React.ReactNode;
    readonly routeData: IRouteData;
    readonly uri: string;
}

const TransitionDashboard: React.FC<ITransitionDashboardProps> = ({ children, routeData, uri }) => {
    return (
        <div className={container}>
            <TransitionScores />
            <div className={reports}>
                <div className={header}>
                    <DashboardNavigation routeData={routeData} preventScroll={true} uri={uri} />
                </div>
                <div>{children}</div>
            </div>
            <TransitionMeasurements />
            <TransitionGallery />
            <TransitionTeamSlider />
        </div>
    );
};

export default TransitionDashboard;
