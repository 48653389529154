import React, { SVGFactory } from 'react';

import {
    container,
    link,
    logoBox,
    iconBox,
    contentBox,
    unread,
    titleText,
    text,
    closeButton,
} from './smoke.module.scss';
import LogoIcon from '../../assets/images/svg/logo-sygnet.svg';
import CloseIcon from '../../assets/images/svg/icon-close.svg';

export interface ISmokeProps {
    className?: string;
    title: string;
    content: string;
    Icon: SVGFactory;
    redirectUrl: string;
    unreadCount: number;
    onClose?(): void;
}

const Smoke: React.FC<ISmokeProps> = ({
    className = '',
    Icon,
    redirectUrl,
    title,
    unreadCount,
    content,
    onClose,
}) => {
    return (
        <div className={`${container} ${className}`}>
            <a
                className={link}
                href={redirectUrl}
                target="_blank"
                rel="noreferrer"
                onClick={onClose}
            >
                <div className={logoBox}>
                    <LogoIcon />
                    <div className={iconBox}>
                        <Icon />
                    </div>
                </div>
                <div className={contentBox}>
                    <p className={titleText}>{title}</p>
                    <p className={text}>{content}</p>
                    <p className={unread}>{getUnreadText(unreadCount)}</p>
                </div>
            </a>
            <button className={closeButton} onClick={onClose}>
                <CloseIcon />
            </button>
        </div>
    );
};

function getUnreadText(unreadCount: number) {
    let count = unreadCount - 1;
    let word = 'nieprzeczytanych';
    if (count <= 0) return;
    if (count > 99) {
        count = 99;
    }
    if (count === 1) {
        word = 'nieprzeczytana';
    }
    if (count < 5 && count > 21) {
        const lastDigit = count % 10;
        if (lastDigit > 1 && lastDigit < 5) {
            word = 'nieprzeczytane';
        }
    }
    return `+${count} ${word}`;
}

export default Smoke;
