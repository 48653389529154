import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    TimeScale,
    Chart,
    ChartDataset,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { AnyObject } from 'chart.js/dist/types/basic';

import { container, loader, errorText, loading, chartBox } from './transition-scores.module.scss';
import { TStatus } from '../../../models/status.model';
import { IDailyReport, IDailyReportScoreChartItem } from '../../../models/transition.model';
import { GenericListFilters } from '../../../models/generic-list.model';
import {
    selectTransitionDailyReport,
    selectTransitionDailyReportStatus,
    selectTransitionScores,
    selectTransitionScoresStatus,
} from '../../../redux/transition/transition.selectors';
import {
    clearTransitionDailyReport,
    clearTransitionScores,
    getTransitionDailyReport,
    getTransitionScores,
} from '../../../redux/transition/transition.actions';
import { getParamsFromFilters } from '../../../utills/filter-utils';

import Loader from '../../atoms/loader';
import TransitionChartHeader, {
    ITransitionChartHeaderProps,
} from '../../molecules/transition-chart-header';

import score01 from '../../../assets/images/transition-score-1.png';
import score02 from '../../../assets/images/transition-score-2.png';
import score03 from '../../../assets/images/transition-score-3.png';
import score04 from '../../../assets/images/transition-score-4.png';
import score05 from '../../../assets/images/transition-score-5.png';
import { getFormattedDate } from '../../../utills/date-utils';
import { addModalAction } from '../../../redux/actions/actions-modals';

const scoreLabels = [score01, score02, score03, score04, score05];

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    TimeScale
);

type TScoreType = 'sleep' | 'nutrition' | 'activity' | 'mood' | 'stress';

const scoreTypes: TScoreType[] = ['sleep', 'nutrition', 'activity', 'mood', 'stress'];

interface ITransitionScoresProps {
    className?: string;
}

interface ITransitionScoresState {
    filters: GenericListFilters;
    items: IDailyReportScoreChartItem[];
}

const TransitionScores: React.FC<ITransitionScoresProps> = ({ className = '' }) => {
    const dispatch = useDispatch();
    const scores: ITransitionScoresState = useSelector(selectTransitionScores);
    const status: TStatus | undefined = useSelector(selectTransitionScoresStatus);
    const dailyReport: IDailyReport | null = useSelector(selectTransitionDailyReport);
    const dailyReportStatus = useSelector(selectTransitionDailyReportStatus);
    const chartBoxRef = useRef<HTMLDivElement | null>(null);
    const [chartContainerStyle, setChartContainerStyle] = useState<React.CSSProperties>({
        position: 'relative',
        width: '100%',
        height: '100%',
    });

    const chartRange = getChartRange(scores);
    const datasets = scoreTypes.map((type) => getChartDataset(scores.items, type));

    const handleFillTodayReport = () => {
        if (!dailyReport) return;
        dispatch(
            addModalAction({
                modalKey: 'DAILY_REPORT_FORM_MODAL',
                modalProps: { report: dailyReport },
            })
        );
    };

    const handleFilterChange: ITransitionChartHeaderProps['onFiltersChange'] = (filters) => {
        const params = getParamsFromFilters(filters);
        dispatch(getTransitionScores(params));
    };

    const handleClear = () => {
        dispatch(getTransitionScores());
    };

    useEffect(() => {
        dispatch(getTransitionDailyReport(getFormattedDate(new Date())));
        return () => {
            dispatch(clearTransitionDailyReport());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(getTransitionScores({ filters: 'default' }));
        return () => {
            dispatch(clearTransitionScores());
        };
    }, [dispatch]);

    useEffect(() => {
        if (status !== 'success') return;
        const handleResize = () => {
            if (!chartBoxRef.current) return;
            setChartContainerStyle((prev) => {
                if (!chartBoxRef.current) return prev;
                return {
                    ...prev,
                    width: chartBoxRef.current.clientWidth,
                    height: chartBoxRef.current.clientHeight,
                };
            });
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [status]);

    if (!status || status === 'idle' || (status === 'loading' && !scores.items.length)) {
        return (
            <div className={`${container} ${className}`}>
                <Loader className={loader} />
            </div>
        );
    }

    if (status === 'fail') {
        return (
            <div className={`${container} ${className}`}>
                <p className={errorText}>{copy.error}</p>
            </div>
        );
    }

    return (
        <div className={`${container} ${className} ${status === 'loading' ? loading : ''}`}>
            <TransitionChartHeader
                filters={scores.filters}
                title={copy.title}
                subtitle={copy.subtitle}
                buttonText={copy.button}
                onAdd={handleFillTodayReport}
                onFiltersChange={handleFilterChange}
                onClearFilters={handleClear}
                isAddDisabled={dailyReportStatus === 'loading'}
            />
            <div className={chartBox} ref={chartBoxRef}>
                <div className="chart-container" style={chartContainerStyle}>
                    <Line
                        plugins={[
                            {
                                id: 'customScale',
                                beforeDraw(
                                    chart: Chart<'line'>,
                                    args: {
                                        cancelable: true;
                                    },
                                    options: AnyObject
                                ): boolean | void {
                                    const {
                                        ctx,
                                        scales: { y },
                                    } = chart;
                                    ctx.save();
                                    y.ticks.forEach((tick, index) => {
                                        const yModifier =
                                            index === 0
                                                ? -24
                                                : index === y.ticks.length - 1
                                                ? 0
                                                : -12;
                                        const label = new Image();
                                        label.src = scoreLabels[index];
                                        ctx.drawImage(
                                            label,
                                            y.left - 35,
                                            y.getPixelForValue(index + 1) + yModifier,
                                            25,
                                            25
                                        );
                                    });
                                },
                            },
                            {
                                id: 'chartAreaBorder',
                                beforeDraw(chart) {
                                    const {
                                        ctx,
                                        chartArea: { left, top, width, height },
                                    } = chart;
                                    ctx.save();

                                    ctx.strokeStyle = 'rgba(239, 239, 239, 1)';
                                    ctx.beginPath();
                                    ctx.moveTo(left + 10, top);
                                    ctx.arcTo(left + width, top, left + width, top + height, 10);
                                    ctx.arcTo(left + width, top + height, left, top + height, 10);
                                    ctx.arcTo(left, top + height, left, top, 10);
                                    ctx.arcTo(left, top, left + width, top, 10);

                                    ctx.stroke();
                                    ctx.clip();
                                    ctx.restore();
                                },
                            },
                            {
                                id: 'backgroundStripes',
                                beforeDraw(chart) {
                                    const {
                                        ctx,
                                        chartArea: { left, top, width, height },
                                    } = chart;
                                    ctx.save();

                                    const colors = [
                                        'rgba(239, 239, 239, 0.29)',
                                        'rgba(255,255,255,1)',
                                    ];
                                    const stripeWidth = width / 4;

                                    for (let i = 0; i < 4; i++) {
                                        ctx.fillStyle = colors[i % 2];
                                        ctx.fillRect(
                                            left + i * stripeWidth,
                                            top,
                                            stripeWidth,
                                            height
                                        );
                                    }

                                    ctx.restore();
                                },
                            },
                        ]}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            layout: {
                                padding: { left: 30, top: 25 },
                            },
                            scales: {
                                x: {
                                    type: 'time',
                                    min: chartRange[0],
                                    max: chartRange[1],
                                    ticks: {
                                        padding: 20,
                                    },
                                    grid: {
                                        display: false,
                                    },
                                    border: {
                                        display: false,
                                    },
                                },
                                y: {
                                    min: 1,
                                    max: 5,
                                    ticks: {
                                        maxTicksLimit: 5,
                                        display: false,
                                    },
                                    grid: {
                                        drawTicks: false,
                                        lineWidth: function (context) {
                                            return context.index === 0 ||
                                                context.index ===
                                                    context.chart.scales['y'].ticks.length - 1
                                                ? 0
                                                : 1;
                                        },
                                    },
                                    border: {
                                        dash: [4, 5],
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                legend: {
                                    position: 'bottom',
                                    align: 'start',
                                    labels: {
                                        usePointStyle: true,
                                        padding: 20,
                                    },
                                },
                                tooltip: {
                                    mode: 'nearest',
                                    callbacks: {
                                        label: function () {
                                            return '';
                                        },
                                        title: function (context) {
                                            const endIndex =
                                                context[0].label.lastIndexOf(',') || -1;
                                            return context[0].label.substring(0, endIndex);
                                        },
                                    },
                                },
                                filler: {
                                    propagate: false,
                                },
                            },
                            elements: {
                                line: {
                                    tension: 0.4,
                                },
                            },
                        }}
                        data={{ datasets: datasets }}
                    />
                </div>
            </div>
        </div>
    );
};

function getChartRange(scores: ITransitionScoresState) {
    if (scores.filters) {
        const appliedYear = Object.values(scores.filters.year.values).find((value) => value.applied)
            ?.value;
        const appliedMonth = Object.values(scores.filters.month.values).find(
            (value) => value.applied
        )?.value;
        if (!appliedMonth && appliedYear) {
            return [`${appliedYear}-01-01`, `${appliedYear}-12-31`];
        }
        if (appliedMonth && appliedYear) {
            return [
                `${appliedYear}-${appliedMonth}-01`,
                `${appliedYear}-${appliedMonth}-${moment(`${appliedYear}-${appliedMonth}-01`)
                    .endOf('month')
                    .format('DD')}`,
            ];
        }
    }
    if (scores.items.length) {
        const minDate = new Date(
            Math.min(...scores.items.map((item) => new Date(item.date).getTime()))
        );
        const maxDate = new Date(
            Math.max(...scores.items.map((item) => new Date(item.date).getTime()))
        );
        return [
            `${minDate.getFullYear()}-${minDate.getMonth() + 1}-${minDate.getDate()}`,
            `${maxDate.getFullYear()}-${maxDate.getMonth() + 1}-${maxDate.getDate()}`,
        ];
    }
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    return [
        `${year}-${month}-1`,
        `${year}-${month}-${moment(`${year}-${month}-01`).endOf('month').format('DD')}`,
    ];
}

function getChartDataset(
    scores: IDailyReportScoreChartItem[],
    type: TScoreType
): ChartDataset<'line', { x: number; y: number }[]> {
    const data = scores
        .filter((score) => score.scores[type] !== null)
        .map((score) => {
            return {
                x: new Date(score.date).getTime(),
                y: score.scores[type] as number,
            };
        });
    return {
        label: copy[type],
        data,
        borderColor: `rgba(${colors[type]}, 1.0)`,
        pointBackgroundColor: `rgba(${colors[type]}, 1.0)`,
        pointBorderWidth: 3,
        borderWidth: 2,
        backgroundColor: (context: any) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (chartArea) {
                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                gradient.addColorStop(0, `rgba(${colors[type]}, 0.3)`);
                gradient.addColorStop(0.15, `rgba(88, 88, 88, 0.15)`);
                gradient.addColorStop(0.3, `rgba(88, 88, 88, 0)`);
                return gradient;
            }
            return '';
        },
        fill: true,
    };
}

const colors = {
    sleep: '88,88,88',
    nutrition: '10,201,69',
    activity: '247,152,24',
    mood: '45,169,224',
    stress: '128,0,255',
};

const copy = {
    title: 'Raporty',
    subtitle: 'Uzupełnij raporty aby śledzić swój nastrój.',
    error: 'Nie udało się pobrać danych raportów. Spróbuj ponownie później...',
    button: 'Uzupełnij dzisiejszy raport',
    clear: 'Pełny zakres',
    sleep: 'Sen',
    nutrition: 'Odżywianie',
    activity: 'Ruch',
    mood: 'Samopoczucie',
    stress: 'Stres',
};

export default TransitionScores;
