import React, { useEffect, useState } from 'react';

import {
    container,
    today,
    toggle,
    header,
    dateText,
    scoreBox,
    scores,
    scoreText,
    bottom,
    toggleBox,
    toggleContent,
    toggleButton,
    toggleIcon,
    open,
    box,
    boxTitle,
    emotions,
    emotion,
    emotionLabel,
    questionIndex,
    editBox,
    stepBox,
    stepLabel,
    button,
    done,
    empty,
    partial,
    stepCircle,
    separator,
    stepBar,
    preview,
    deleteButton,
} from './daily-report-card.module.scss';
import ChevronDown from '../../assets/images/svg/chevron-down.svg';
import CheckIcon from '../../assets/images/svg/ok.svg';
import TrashIcon from '../../assets/images/svg/delete.svg';
import { IDailyReport, TReportStatus } from '../../models/transition.model';
import { IOption } from '../../models/option.model';
import { getIsDateToday } from '../../utills/get-is-date-today';
import { useTransitionEmotionOptions } from '../../hooks/use-transition-emotion-options';

import MoreButton from './more-button';
import TransitionScore, { TTransitionScoreMode } from '../atoms/transition-score';
import AnimateHeight from 'react-animate-height';
import Button from '../atoms/button';
import { getIsDateInFuture } from '../../utills/get-is-date-in-future';

export interface IDailyReportCardProps {
    className?: string;
    dailyReport: IDailyReport;
    mode?: TTransitionScoreMode;
    onFillReport?(report: IDailyReport): void;
    onDeleteReport?(report: IDailyReport): void;
}

const DailyReportCard: React.FC<IDailyReportCardProps> = ({
    className = '',
    dailyReport,
    onFillReport,
    onDeleteReport,
    mode = 'normal',
}) => {
    const emotionOptions = useTransitionEmotionOptions();
    const [isOpen, setIsOpen] = useState(false);
    const isToday = getIsDateToday(new Date(dailyReport.date).getTime(), false);
    const isInFuture = getIsDateInFuture(dailyReport.date);
    const canBeToggled = getCanBeToggled(dailyReport);
    const steps = [
        { status: dailyReport.stepScores },
        { status: dailyReport.stepEmotions },
        { status: dailyReport.stepQuestions },
    ];

    const modeClass = modeClasses[mode];

    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleDelete = () => {
        if (typeof onDeleteReport === 'function') {
            onDeleteReport(dailyReport);
        }
    };

    const handleFill = () => {
        if (typeof onFillReport === 'function') {
            onFillReport(dailyReport);
        }
    };

    useEffect(() => {
        if (!canBeToggled) {
            setIsOpen(false);
        }
    }, [canBeToggled]);

    return (
        <div
            className={`${container} ${className} ${isToday ? today : ''} ${isOpen ? open : ''} ${
                canBeToggled ? toggle : ''
            } ${modeClass}`}
        >
            <div className={header}>
                <h4 className={dateText}>{dailyReport.displayName}</h4>
                {mode === 'normal' && dailyReport.status !== 'empty' && (
                    <MoreButton>
                        <button className={deleteButton} onClick={handleDelete}>
                            <TrashIcon /> {copy.delete}
                        </button>
                    </MoreButton>
                )}
            </div>
            <div className={scores}>
                <div className={scoreBox}>
                    <TransitionScore
                        mode={mode}
                        {...(mode === 'normal' ? { onClick: handleFill } : {})}
                        score={dailyReport.scores.sleep}
                    />
                    <p className={scoreText}>{copy.sleep}</p>
                </div>
                <div className={scoreBox}>
                    <TransitionScore
                        mode={mode}
                        {...(mode === 'normal' ? { onClick: handleFill } : {})}
                        score={dailyReport.scores.nutrition}
                    />
                    <p className={scoreText}>{copy.nutrition}</p>
                </div>
                <div className={scoreBox}>
                    <TransitionScore
                        mode={mode}
                        {...(mode === 'normal' ? { onClick: handleFill } : {})}
                        score={dailyReport.scores.activity}
                    />
                    <p className={scoreText}>{copy.activity}</p>
                </div>
                <div className={scoreBox}>
                    <TransitionScore
                        mode={mode}
                        {...(mode === 'normal' ? { onClick: handleFill } : {})}
                        score={dailyReport.scores.mood}
                    />
                    <p className={scoreText}>{copy.mood}</p>
                </div>
                <div className={scoreBox}>
                    <TransitionScore
                        mode={mode}
                        {...(mode === 'normal' ? { onClick: handleFill } : {})}
                        score={dailyReport.scores.stress}
                    />
                    <p className={scoreText}>{copy.stress}</p>
                </div>
            </div>
            <AnimateHeight className={toggleBox} height={isOpen ? 'auto' : 1} duration={500}>
                <div className={toggleContent}>
                    <div className={box}>
                        <p className={boxTitle}>{copy.emotions}</p>
                        <div className={emotions}>
                            <div className={emotion}>
                                <p className={emotionLabel}>{copy.positive}:</p>
                                <p>
                                    {getEmotionsString(
                                        emotionOptions.positive,
                                        dailyReport.emotions.positive
                                    )}
                                </p>
                            </div>
                            <div className={emotion}>
                                <p className={emotionLabel}>{copy.neutral}:</p>
                                <p>
                                    {getEmotionsString(
                                        emotionOptions.neutral,
                                        dailyReport.emotions.neutral
                                    )}
                                </p>
                            </div>
                            <div className={emotion}>
                                <p className={emotionLabel}>{copy.negative}:</p>
                                <p>
                                    {getEmotionsString(
                                        emotionOptions.negative,
                                        dailyReport.emotions.negative
                                    )}
                                </p>
                            </div>
                            <div className={emotion}>
                                <p className={emotionLabel}>{copy.other}:</p>
                                <p>{dailyReport.emotions.other || '-'}</p>
                            </div>
                        </div>
                    </div>
                    {dailyReport.questions.map((question, index) => {
                        return (
                            <div key={`survey-question-${index + 1}`} className={box}>
                                <p className={boxTitle}>
                                    <span className={questionIndex}>{index + 1}.</span>
                                    <span>{question.content}</span>
                                </p>
                                <p>{question.answer || '-'}</p>
                            </div>
                        );
                    })}
                </div>
            </AnimateHeight>
            <div className={bottom}>
                <button className={toggleButton} onClick={handleToggle}>
                    <span>{isOpen ? copy.hide : copy.show}</span>
                    <span>
                        <ChevronDown className={toggleIcon} />
                    </span>
                </button>
                <div className={editBox}>
                    <div className={stepBox}>
                        <p className={stepLabel}>{copy.step}</p>
                        <div className={stepBar}>
                            {steps.map((step, index) => {
                                const statusClass = statusClasses[step.status];
                                return (
                                    <React.Fragment key={`step-${index}`}>
                                        <button
                                            className={`${stepCircle} ${statusClass}`}
                                            onClick={handleFill}
                                        >
                                            {step.status === 'done' ? <CheckIcon /> : index + 1}
                                        </button>
                                        {index + 1 < steps.length && (
                                            <span className={`${separator} ${statusClass}`} />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                    {mode === 'normal' && (
                        <Button
                            className={button}
                            size="small"
                            color="yellow"
                            onClick={handleFill}
                            disabled={isInFuture || !dailyReport.isEditable}
                            isDisabledClickable={true}
                        >
                            {steps.every((step) => step.status === 'done') ? copy.edit : copy.fill}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

function getEmotionsString(emotionOptions: IOption[], reportEmotions: number[]) {
    return (
        reportEmotions
            .map((emotion) => emotionOptions.find((option) => option.value === emotion))
            .filter((option) => !!option)
            .map((option) => option?.label)
            .join(', ') || '-'
    );
}

function getCanBeToggled(report: IDailyReport) {
    const stepStatuses: TReportStatus[] = ['partial', 'done'];
    return (
        stepStatuses.includes(report.stepQuestions) || stepStatuses.includes(report.stepEmotions)
    );
}

const modeClasses: Record<TTransitionScoreMode, string> = {
    normal: '',
    preview: preview,
};

const statusClasses: Record<TReportStatus, string> = {
    empty: empty,
    partial: partial,
    done: done,
};

const copy = {
    sleep: 'Sen',
    nutrition: 'Odżywianie',
    activity: 'Ruch',
    mood: 'Samopoczucie',
    stress: 'Stres',
    show: 'Pokaż odpowiedzi',
    hide: 'Ukryj odpowiedzi',
    emotions: 'Emocje, które dziś u Ciebie dominowały',
    positive: 'pozytywne',
    neutral: 'neutralne',
    negative: 'negatywne',
    other: 'inne',
    edit: 'Edytuj',
    fill: 'Uzupełnij',
    step: 'Krok',
    delete: 'Usuń raport dzienny',
};

export default DailyReportCard;
