import React from 'react';

import {
    container,
    header,
    titleText,
    actions,
    button,
    buttonInner,
    filtersBox,
    filterListBox,
    filter,
    filterList,
} from './transition-chart-header.module.scss';
import PlusIcon from '../../assets/images/svg/plus.svg';
import { GenericListFilters } from '../../models/generic-list.model';
import { getParamsFromFilters } from '../../utills/filter-utils';

import Button from '../atoms/button';
import Filters from '../organisms/filters/filters';

export interface ITransitionChartHeaderProps {
    className?: string;
    filters: GenericListFilters | null;
    buttonText?: string;
    title?: string;
    subtitle?: string;
    isAddDisabled?: boolean;
    onAdd?(): void;
    onFiltersChange?(filters: GenericListFilters): void;
    onClearFilters?(): void;
}

const TransitionChartHeader: React.FC<ITransitionChartHeaderProps> = ({
    className = '',
    filters,
    title,
    subtitle,
    buttonText,
    isAddDisabled,
    onAdd,
    onFiltersChange,
    onClearFilters,
}) => {
    const hasParams = Object.values(getParamsFromFilters(filters)).some((value) => value);

    return (
        <div className={`${container} ${className}`}>
            {(title || subtitle) && (
                <div className={header}>
                    {title && <h3 className={titleText}>{title}</h3>}
                    {subtitle && <p>{subtitle}</p>}
                </div>
            )}
            <div className={actions}>
                {typeof onAdd === 'function' && (
                    <Button
                        className={button}
                        size="small"
                        color="yellow"
                        onClick={onAdd}
                        disabled={isAddDisabled}
                    >
                        <span className={buttonInner}>
                            <PlusIcon />
                            {buttonText || copy.add}
                        </span>
                    </Button>
                )}
                {filters && (
                    <div className={filtersBox}>
                        <Button
                            className={button}
                            size="small"
                            color={hasParams ? 'blank' : 'black'}
                            onClick={onClearFilters}
                        >
                            {copy.clear}
                        </Button>
                        <Filters
                            className={filterListBox}
                            filterClassName={filter}
                            filterListClassName={filterList}
                            filters={filters}
                            showTitle={false}
                            onApplyFilters={onFiltersChange}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const copy = {
    add: 'Dodaj',
    clear: 'Pełny zakres',
};

export default TransitionChartHeader;
