import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useDispatch } from 'react-redux';

import {
    container,
    open,
    header,
    dateText,
    days,
    content,
    daysList,
    dailyReportButton,
    sendBox,
    sendInner,
    sendButton,
    sendButtonInner,
    timeToSend,
    toggleBox,
    toggleContent,
    toggleButton,
    bottom,
    toggleIcon,
    deleteButton,
    sent,
    dayButton,
} from './weekly-report-card.module.scss';
import PaperPlaneIcon from '../../assets/images/svg/paper-plane.svg';
import ChevronDown from '../../assets/images/svg/chevron-down.svg';
import TrashIcon from '../../assets/images/svg/delete.svg';
import { IWeeklyReport } from '../../models/transition.model';
import { getWeek } from '../../utills/get-week';
import { getAbsolutePath } from '../../routes';
import { addModalAction } from '../../redux/actions/actions-modals';
import { deleteTransitionWeeklyReport } from '../../redux/transition/transition.actions';

import TransitionWeeklyAnswers from './transition-weekly-answers';
import MoreButton from './more-button';
import TransitionDayButton, { ITransitionDayButtonProps } from '../atoms/transition-day-button';
import Button from '../atoms/button';
import AnimateHeight from 'react-animate-height';
import WeeklyReportQuestionAnswers from './weekly-report-question-answers';

export interface IWeeklyReportCardProps {
    className?: string;
    report: IWeeklyReport;
}

const WeeklyReportCard: React.FC<IWeeklyReportCardProps> = ({ className = '', report }) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const isCurrentWeek =
        new Date().setHours(0, 0, 0, 0) < new Date(report.to).setHours(0, 0, 0, 0);

    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleDelete = () => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: copy.confirmationTitle,
                    content: copy.confirmationContent,
                    onConfirm: () => {
                        dispatch(deleteTransitionWeeklyReport(report.weekId));
                    },
                },
            })
        );
    };

    const handleSend = () => {
        if (report.status === 'sent') return;
        if (report.status === 'empty') {
            dispatch(
                addModalAction({
                    type: 'info',
                    size: 'small',
                    title: 'Nie możesz wysłać tego raportu',
                    buttonText: 'Rozumiem',
                    noEmotics: true,
                    content:
                        'Aby można wysłać raport odpowiedz na jedno z pytan raportu tygodniowego lub uzupełnij któryś z raportów dziennych.',
                })
            );
            return;
        }
        if (isCurrentWeek) {
            dispatch(
                addModalAction({
                    type: 'info',
                    size: 'small',
                    title: 'Nie możesz wysłać tego raportu',
                    buttonText: 'Rozumiem',
                    noEmotics: true,
                    content: 'Ten tydzień jeszcze się nie kończy.',
                })
            );
            return;
        }
        dispatch(
            addModalAction({
                modalKey: 'SEND_WEEKLY_REPORT_MODAL',
                modalProps: { weekId: report.weekId },
            })
        );
    };

    const handleFillReport = () => {
        dispatch(
            addModalAction({
                modalKey: 'WEEKLY_REPORT_FORM_MODAL',
                modalProps: { report },
            })
        );
    };

    const handleDaySelect: ITransitionDayButtonProps['onDaySelect'] = (dailyReport) => {
        dispatch(
            addModalAction({
                modalKey: 'DAILY_REPORT_FORM_MODAL',
                modalProps: { report: dailyReport },
            })
        );
    };

    return (
        <div
            className={`${container} ${className} ${isOpen ? open : ''} ${
                report.status === 'sent' ? sent : ''
            }`}
        >
            <div className={header}>
                <h4 className={dateText}>{report.displayName}</h4>
                {report.status !== 'sent' && report.status !== 'empty' && (
                    <MoreButton>
                        <button className={deleteButton} onClick={handleDelete}>
                            <TrashIcon /> {copy.delete}
                        </button>
                    </MoreButton>
                )}
            </div>
            <div className={content}>
                <div className={days}>
                    <div className={daysList}>
                        {report.dailyReports.map((dailyReport) => {
                            return (
                                <TransitionDayButton
                                    className={dayButton}
                                    key={`day-${dailyReport.date}`}
                                    report={dailyReport}
                                    showNumber={false}
                                    onDaySelect={handleDaySelect}
                                />
                            );
                        })}
                    </div>
                    <Link
                        to={getAbsolutePath('APP_MY_TRANSITION_DAILY')}
                        state={{ week: getWeek(new Date(report.dailyReports[0].date)) }}
                    >
                        <Button size="small" color="blank" className={dailyReportButton}>
                            {copy.dailyReport}
                        </Button>
                    </Link>
                </div>
                <div className={sendBox}>
                    <div className={sendInner}>
                        <Button
                            className={sendButton}
                            size="small"
                            color="black"
                            onClick={handleSend}
                            disabled={
                                report.status === 'empty' ||
                                report.status === 'sent' ||
                                isCurrentWeek
                            }
                            isDisabledClickable={true}
                        >
                            <span className={sendButtonInner}>
                                {report.status === 'sent' ? (
                                    copy.sent
                                ) : (
                                    <>
                                        <PaperPlaneIcon />
                                        {copy.send}
                                    </>
                                )}
                            </span>
                        </Button>
                        {report.timeToSend && report.status !== 'sent' && (
                            <p className={timeToSend}>
                                {copy.timeToSend(report.timeToSend.duration)}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <AnimateHeight className={toggleBox} height={isOpen ? 'auto' : 1} duration={500}>
                <WeeklyReportQuestionAnswers
                    questions={report.questions}
                    className={toggleContent}
                />
            </AnimateHeight>
            <div className={bottom}>
                <button className={toggleButton} onClick={handleToggle}>
                    <span>{isOpen ? copy.hide : copy.show}</span>
                    <span>
                        <ChevronDown className={toggleIcon} />
                    </span>
                </button>
                <TransitionWeeklyAnswers report={report} onFillReport={handleFillReport} />
            </div>
        </div>
    );
};

const copy = {
    answers: 'Odpowiedzi',
    dailyReport: 'Raport dzienny',
    send: 'Wyślij raport tygodniowy',
    edit: 'Edytuj raport tygodniowy',
    fill: 'Wypełnij raport tygodniowy',
    show: 'Pokaż odpowiedzi',
    hide: 'Ukryj odpowiedzi',
    delete: 'Usuń raport tygodniowy',
    confirmationTitle: 'Usunąć raport tygodniowy?',
    confirmationContent: 'Dane raportu tygodniowego zostaną nieodwracalnie usunięte.',
    sent: 'Raport wyslany',
    timeToSend: (time: string) => (
        <>
            Masz <strong>{time}</strong> na wysłanie raportu
        </>
    ),
};

export default WeeklyReportCard;
