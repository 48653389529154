import React, { useEffect, useState } from 'react';

import { getUserTokenData } from '../../utills/get-user-token-data';

interface IProtectedFileProps {
    className?: string;
    url: string;
    children?: React.ReactNode;
}

const ProtectedFile: React.FC<IProtectedFileProps> = ({ className = '', url, children }) => {
    const [objectUrl, setObjectUrl] = useState('');
    const tokenData = getUserTokenData();
    const token = tokenData?.token;

    useEffect(() => {
        const getProtectedFile = async () => {
            const headers = new Headers();
            headers.set('Authorization', `Bearer ${token}`);
            const response = await fetch(url, { headers });
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            setObjectUrl(objectUrl);
        };
        getProtectedFile();
    }, [url, token]);

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(objectUrl);
        };
    }, [objectUrl]);

    return (
        <a className={className} href={objectUrl} target="_blank" rel="noreferrer noopener">
            {children}
        </a>
    );
};

export default ProtectedFile;
