import { types as apiTypes } from '../api/api.actions';

export const INFO_BARS = '[INFO_BARS]';

export const types = {
    // flow/commands actions
    GET_INFO_BARS: `${INFO_BARS} get`,
    CLEAR_INFO_BARS: `${INFO_BARS} clear`,

    // api actions
    SEND_INFO_BARS_REQUEST: `${INFO_BARS} ${apiTypes.API_REQUEST}`,
    SEND_INFO_BARS_SUCCESS: `${INFO_BARS} ${apiTypes.API_SUCCESS}`,
    SEND_INFO_BARS_FAIL: `${INFO_BARS} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_INFO_BARS: `${INFO_BARS} set`,
    RESTORE_INFO_BARS_INITIAL_STATE: `${INFO_BARS} restore`,
};

// reducer actions
export const setInfoBars = (payload) => ({
    type: types.SET_INFO_BARS,
    payload: payload,
});

export const restoreInfoBarsInitialState = () => ({
    type: types.RESTORE_INFO_BARS_INITIAL_STATE,
});

// middleware actions
export const getInfoBars = () => ({
    type: types.GET_INFO_BARS,
});

export const clearInfoBars = () => ({
    type: types.CLEAR_INFO_BARS,
});
