import React from 'react';

import { container, button, iconBox, img, preview } from './transition-score.module.scss';
import PlusIcon from '../../assets/images/svg/plus-no-circle.svg';
import CloseIcon from '../../assets/images/svg/icon-close.svg';
import score01 from '../../assets/images/transition-score-1.png';
import score02 from '../../assets/images/transition-score-2.png';
import score03 from '../../assets/images/transition-score-3.png';
import score04 from '../../assets/images/transition-score-4.png';
import score05 from '../../assets/images/transition-score-5.png';

export type TTransitionScoreMode = 'normal' | 'preview';

interface ITransitionScoreProps {
    className?: string;
    score: number | null; // min 0, max 5;
    onClick?(): void;
    mode?: TTransitionScoreMode;
}

const TransitionScore: React.FC<ITransitionScoreProps> = ({
    className = '',
    onClick,
    score,
    mode = 'normal',
}) => {
    const imgSrc = getImgSrc(score);
    const modeClass = modeClasses[mode];

    if (typeof onClick === 'function') {
        return (
            <button
                className={`${container} ${button} ${className} ${modeClass}`}
                onClick={onClick}
            >
                {imgSrc && <img className={img} src={imgSrc} alt="" />}
                {!imgSrc && (
                    <div className={iconBox}>
                        {mode === 'normal' && <PlusIcon />}
                        {mode === 'preview' && <CloseIcon />}
                    </div>
                )}
            </button>
        );
    }

    return (
        <div className={`${container} ${className} ${modeClass}`}>
            {imgSrc && <img className={img} src={imgSrc} alt="" />}
            {!imgSrc && (
                <div className={iconBox}>
                    {mode === 'normal' && <PlusIcon />}
                    {mode === 'preview' && <CloseIcon />}
                </div>
            )}
        </div>
    );
};

const modeClasses: Record<TTransitionScoreMode, string> = {
    normal: '',
    preview: preview,
};

function getImgSrc(score: number | null) {
    if (score === 1) return score01;
    if (score === 2) return score02;
    if (score === 3) return score03;
    if (score === 4) return score04;
    if (score === 5) return score05;
    return null;
}

export default TransitionScore;
