import { types as apiTypes } from '../api/api.actions';

export const MESSAGE_COUNT = '[MESSAGE_COUNT]';

export const types = {
    GET_MESSAGE_COUNT: `${MESSAGE_COUNT} get`,
    CLEAR_MESSAGE_COUNT: `${MESSAGE_COUNT} clear`,

    FETCH_MESSAGE_COUNT_REQUEST: `${MESSAGE_COUNT} ${apiTypes.API_REQUEST}`,
    FETCH_MESSAGE_COUNT_SUCCESS: `${MESSAGE_COUNT} ${apiTypes.API_SUCCESS}`,
    FETCH_MESSAGE_COUNT_FAIL: `${MESSAGE_COUNT} ${apiTypes.API_FAIL}`,

    SET_MESSAGE_COUNT: `${MESSAGE_COUNT} set`,
    RESTORE_MESSAGE_COUNT_INITIAL_STATE: `${MESSAGE_COUNT} restore`,
};

export const getMessageCountAction = () => ({
    type: types.GET_MESSAGE_COUNT,
});

export const clearMessageCountAction = () => ({
    type: types.CLEAR_MESSAGE_COUNT,
});

export const setMessageCountAction = (data) => ({
    type: types.SET_MESSAGE_COUNT,
    payload: data,
});

export const restoreMessageCountInitialItemsAction = () => ({
    type: types.RESTORE_MESSAGE_COUNT_INITIAL_STATE,
});
