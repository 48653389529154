import React from 'react';
import { connect } from 'react-redux';

import {
    wrapper,
    closeIcon,
    elementsWrapper,
    folderWrapper,
    elements,
    reverseColors,
    downloadButton,
    pillText,
} from './attachment-pill.module.scss';
import FolderSvg from '../../../assets/images/svg/folder.svg';
import IconClose from '../../../assets/images/svg/icon-close.svg';
import { removeAttachmentPillByIndex } from '../../../redux/messenger/additional-attachments-to-send/actions';

import FileToBigSizePill from '../../atoms/file-to-big-size-pill/file-to-big-size-pill';
import ProtectedFile from '../../hoc/protected-file';

const AttachmentPill = ({
    data,
    reverseColor = false,
    canDownload,
    isAttachmentToMessage,
    removePill,
}) => {
    const maxFileSize = 32 * 1024 * 1000;
    const { name, file, className, index } = data;
    const { uri } = file;
    const fileSize = isAttachmentToMessage ? data.size : file.size;

    return isAttachmentToMessage && maxFileSize <= fileSize ? (
        <FileToBigSizePill data={data} />
    ) : (
        <div className={`${wrapper} ${reverseColor && reverseColors} ${className}`}>
            <span className={elementsWrapper}>
                <ProtectedFile url={uri} className={elements}>
                    <span className={folderWrapper}>
                        <FolderSvg />
                    </span>
                    <span className={pillText}>{name}</span>
                    {canDownload && <span className={downloadButton}>Podgląd</span>}
                </ProtectedFile>
            </span>
            {isAttachmentToMessage && (
                <button onClick={() => removePill(index)} className={closeIcon}>
                    <IconClose />
                </button>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    removePill: (index) => dispatch(removeAttachmentPillByIndex(index)),
});

export default connect(null, mapDispatchToProps)(AttachmentPill);
