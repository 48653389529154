import React from 'react';
import { useRef } from 'react';

import {
    button,
    fullwidth as fullwidthClass,
    small,
    outline as outlineClass,
    circle as circleClass,
    inline as inlineClass,
    noMargin as noMarginClass,
    icon as iconClass,
    black,
    yellow,
    green,
    lime,
    blank,
    whiteYellow,
    white,
    grey,
    blankWhite,
    transparentBlack,
    red,
    extraSmall,
    clickableDisabled,
    blankYellow,
} from './button.module.scss';

export type TButtonColor =
    | 'black'
    | 'yellow'
    | 'green'
    | 'lime'
    | 'blank'
    | 'whiteYellow'
    | 'white'
    | 'grey'
    | 'blankWhite'
    | 'transparentBlack'
    | 'red'
    | 'blankYellow';

export type TButtonSize = 'normal' | 'small' | 'extraSmall';

export interface IButtonProps extends React.ComponentProps<'button'> {
    className?: string;
    onClick?(): void;
    color?: TButtonColor;
    fullwidth?: boolean;
    icon?: React.SVGFactory;
    size?: TButtonSize;
    noMargin?: boolean;
    outline?: boolean;
    circle?: boolean;
    inline?: boolean;
    isDisabledClickable?: boolean;
}

const Button: React.FC<IButtonProps> = ({
    className = '',
    color = 'black',
    onClick,
    fullwidth = false,
    icon: Icon,
    size = 'normal',
    noMargin = false,
    outline = false,
    circle = false,
    inline = false,
    children,
    isDisabledClickable,
    disabled,
    ...rest
}) => {
    const ref = useRef<HTMLButtonElement>(null);

    const colorClass = colorClasses[color];
    const sizeClass = sizeClasses[size];

    const handleClick = () => {
        if (ref.current) {
            ref.current.blur();
        }
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <button
            className={`
                ${button} 
                ${colorClass}
                ${fullwidth ? fullwidthClass : ''}
                ${sizeClass}
                ${outline ? outlineClass : ''}
                ${circle ? circleClass : ''}
                ${inline ? inlineClass : ''}
                ${noMargin ? noMarginClass : ''}
                ${disabled && isDisabledClickable ? clickableDisabled : ''}
                ${className}
            `}
            ref={ref}
            onClick={handleClick}
            disabled={disabled && !isDisabledClickable}
            {...rest}
        >
            {children}
            {Icon && <Icon className={iconClass} />}
        </button>
    );
};

const sizeClasses: Record<TButtonSize, string> = {
    small,
    extraSmall,
    normal: '',
};

const colorClasses: Record<TButtonColor, string> = {
    black,
    yellow,
    green,
    lime,
    blank,
    whiteYellow,
    white,
    grey,
    blankWhite,
    transparentBlack,
    red,
    blankYellow,
};

export default Button;
