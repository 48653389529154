import { types } from './notifications.actions';

const {
    SET_NOTIFICATIONS,
    SET_NOTIFICATIONS_COUNT,
    SET_NOTIFICATIONS_INTERVAL_ID,
    SET_NEW_NOTIFICATIONS_AVAILABLE,
    SET_NOTIFICATIONS_NOT_READ_COUNT,
    RESTORE_NOTIFICATIONS_INITIAL_ITEMS,
    RESTORE_NOTIFICATIONS_INITIAL_STATE,
} = types;

const initialState = {
    notSeenCount: 0,
    notReadCount: {
        count: 0,
        latestMessage: null,
    },
    intervalId: null,
    newAvailable: false,
    items: [],
    pagination: {
        perPage: 5,
        pageCount: 0,
        totalCount: 0,
        currentPage: 0,
    },
};

export default function notificationsReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                items: [...state.items, ...payload.items],
                pagination: payload.pagination,
            };
        case SET_NOTIFICATIONS_INTERVAL_ID:
            return {
                ...state,
                intervalId: payload,
            };
        case SET_NOTIFICATIONS_COUNT:
            return {
                ...state,
                notSeenCount: payload,
            };
        case SET_NOTIFICATIONS_NOT_READ_COUNT:
            return {
                ...state,
                notReadCount: payload,
            };
        case SET_NEW_NOTIFICATIONS_AVAILABLE:
            return {
                ...state,
                newAvailable: true,
            };
        case RESTORE_NOTIFICATIONS_INITIAL_ITEMS:
            return {
                ...state,
                newAvailable: false,
                items: [],
                pagination: initialState.pagination,
            };
        case RESTORE_NOTIFICATIONS_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
}
