import { types as apiTypes } from '../api/api.actions';

export const TRANSITION = '[TRANSITION]';
export const TRANSITION_DAILY_REPORTS = '[TRANSITION_DAILY_REPORTS]';
export const TRANSITION_DAILY_REPORT = '[TRANSITION_DAILY_REPORT]';
export const TRANSITION_WEEKLY_REPORTS = '[TRANSITION_WEEKLY_REPORTS]';
export const TRANSITION_WEEKLY_REPORT = '[TRANSITION_WEEKLY_REPORT]';
export const TRANSITION_REPORTS_HISTORY = '[TRANSITION_REPORTS_HISTORY]';
export const TRANSITION_SCORES = '[TRANSITION_SCORES]';
export const TRANSITION_MEASUREMENTS = '[TRANSITION_MEASUREMENTS]';
export const TRANSITION_MEASUREMENT_COUNT = '[TRANSITION_MEASUREMENT_COUNT]';
export const TRANSITION_MEASUREMENT_DEFAULT = '[TRANSITION_MEASUREMENT_DEFAULT]';
export const TRANSITION_MEASUREMENT = '[TRANSITION_MEASUREMENT]';
export const TRANSITION_GALLERY = '[TRANSITION_GALLERY]';

export const TRANSITION_REPORTS_HISTORY_PER_PAGE = 10;

export const types = {
    // flow/commands actions
    GET_TRANSITION_DAILY_REPORTS: `${TRANSITION_DAILY_REPORTS} get`,
    CLEAR_TRANSITION_DAILY_REPORTS: `${TRANSITION_DAILY_REPORTS} clear`,

    GET_TRANSITION_DAILY_REPORT: `${TRANSITION_DAILY_REPORT} get`,
    CREATE_TRANSITION_DAILY_REPORT: `${TRANSITION_DAILY_REPORT} create`,
    UPDATE_TRANSITION_DAILY_REPORT: `${TRANSITION_DAILY_REPORT} update`,
    DELETE_TRANSITION_DAILY_REPORT: `${TRANSITION_DAILY_REPORT} delete`,
    CLEAR_TRANSITION_DAILY_REPORT: `${TRANSITION_DAILY_REPORT} clear`,

    GET_TRANSITION_WEEKLY_REPORTS: `${TRANSITION_WEEKLY_REPORTS} get`,
    SUBMIT_TRANSITION_WEEKLY_REPORTS: `${TRANSITION_WEEKLY_REPORTS} submit`,
    CLEAR_TRANSITION_WEEKLY_REPORTS: `${TRANSITION_WEEKLY_REPORTS} clear`,

    GET_TRANSITION_WEEKLY_REPORT: `${TRANSITION_WEEKLY_REPORT} get`,
    CREATE_TRANSITION_WEEKLY_REPORT: `${TRANSITION_WEEKLY_REPORT} create`,
    UPDATE_TRANSITION_WEEKLY_REPORT: `${TRANSITION_WEEKLY_REPORT} update`,
    DELETE_TRANSITION_WEEKLY_REPORT: `${TRANSITION_WEEKLY_REPORT} delete`,
    CLEAR_TRANSITION_WEEKLY_REPORT: `${TRANSITION_WEEKLY_REPORT} clear`,

    GET_TRANSITION_REPORTS_HISTORY: `${TRANSITION_REPORTS_HISTORY} get`,
    CLEAR_TRANSITION_REPORTS_HISTORY: `${TRANSITION_REPORTS_HISTORY} clear`,

    GET_TRANSITION_SCORES: `${TRANSITION_SCORES} get`,
    CLEAR_TRANSITION_SCORES: `${TRANSITION_SCORES} clear`,

    GET_TRANSITION_MEASUREMENTS: `${TRANSITION_MEASUREMENTS} get`,
    CLEAR_TRANSITION_MEASUREMENTS: `${TRANSITION_MEASUREMENTS} clear`,

    GET_TRANSITION_MEASUREMENT_DEFAULT: `${TRANSITION_MEASUREMENT_DEFAULT} get`,
    CLEAR_TRANSITION_MEASUREMENT_DEFAULT: `${TRANSITION_MEASUREMENT_DEFAULT} clear`,

    CREATE_TRANSITION_MEASUREMENT: `${TRANSITION_MEASUREMENT} create`,
    UPDATE_TRANSITION_MEASUREMENT: `${TRANSITION_MEASUREMENT} update`,

    GET_TRANSITION_GALLERY: `${TRANSITION_GALLERY} get`,
    CLEAR_TRANSITION_GALLERY: `${TRANSITION_GALLERY} clear`,

    GET_TRANSITION_MEASUREMENT_COUNT: `${TRANSITION_MEASUREMENT_COUNT} get`,
    CLEAR_TRANSITION_MEASUREMENT_COUNT: `${TRANSITION_MEASUREMENT_COUNT} clear`,

    CLEAR_TRANSITION: `${TRANSITION} clear`,

    // api actions
    SEND_TRANSITION_DAILY_REPORTS_REQUEST: `${TRANSITION_DAILY_REPORTS} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_DAILY_REPORTS_SUCCESS: `${TRANSITION_DAILY_REPORTS} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_DAILY_REPORTS_FAIL: `${TRANSITION_DAILY_REPORTS} ${apiTypes.API_FAIL}`,

    SEND_TRANSITION_DAILY_REPORT_REQUEST: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_DAILY_REPORT_SUCCESS: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_DAILY_REPORT_FAIL: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_FAIL}`,

    POST_TRANSITION_DAILY_REPORT_REQUEST: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_REQUEST} post`,
    POST_TRANSITION_DAILY_REPORT_SUCCESS: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_SUCCESS} post`,
    POST_TRANSITION_DAILY_REPORT_FAIL: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_FAIL} post`,

    PATCH_TRANSITION_DAILY_REPORT_REQUEST: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_REQUEST} patch`,
    PATCH_TRANSITION_DAILY_REPORT_SUCCESS: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_SUCCESS} patch`,
    PATCH_TRANSITION_DAILY_REPORT_FAIL: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_FAIL} patch`,

    DELETE_TRANSITION_DAILY_REPORT_REQUEST: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_REQUEST} delete`,
    DELETE_TRANSITION_DAILY_REPORT_SUCCESS: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_SUCCESS} delete`,
    DELETE_TRANSITION_DAILY_REPORT_FAIL: `${TRANSITION_DAILY_REPORT} ${apiTypes.API_FAIL} delete`,

    SEND_TRANSITION_WEEKLY_REPORTS_REQUEST: `${TRANSITION_WEEKLY_REPORTS} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_WEEKLY_REPORTS_SUCCESS: `${TRANSITION_WEEKLY_REPORTS} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_WEEKLY_REPORTS_FAIL: `${TRANSITION_WEEKLY_REPORTS} ${apiTypes.API_FAIL}`,

    SEND_TRANSITION_WEEKLY_REPORT_REQUEST: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_WEEKLY_REPORT_SUCCESS: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_WEEKLY_REPORT_FAIL: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_FAIL}`,

    POST_TRANSITION_WEEKLY_REPORT_REQUEST: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_REQUEST} post`,
    POST_TRANSITION_WEEKLY_REPORT_SUCCESS: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_SUCCESS} post`,
    POST_TRANSITION_WEEKLY_REPORT_FAIL: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_FAIL} post`,

    PATCH_TRANSITION_WEEKLY_REPORT_REQUEST: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_REQUEST} patch`,
    PATCH_TRANSITION_WEEKLY_REPORT_SUCCESS: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_SUCCESS} patch`,
    PATCH_TRANSITION_WEEKLY_REPORT_FAIL: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_FAIL} patch`,

    DELETE_TRANSITION_WEEKLY_REPORT_REQUEST: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_REQUEST} delete`,
    DELETE_TRANSITION_WEEKLY_REPORT_SUCCESS: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_SUCCESS} delete`,
    DELETE_TRANSITION_WEEKLY_REPORT_FAIL: `${TRANSITION_WEEKLY_REPORT} ${apiTypes.API_FAIL} delete`,

    SUBMIT_TRANSITION_WEEKLY_REPORTS_REQUEST: `${TRANSITION_DAILY_REPORTS} ${apiTypes.API_REQUEST} submit`,
    SUBMIT_TRANSITION_WEEKLY_REPORTS_SUCCESS: `${TRANSITION_DAILY_REPORTS} ${apiTypes.API_SUCCESS} submit`,
    SUBMIT_TRANSITION_WEEKLY_REPORTS_FAIL: `${TRANSITION_DAILY_REPORTS} ${apiTypes.API_FAIL} submit`,

    SEND_TRANSITION_REPORTS_HISTORY_REQUEST: `${TRANSITION_REPORTS_HISTORY} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_REPORTS_HISTORY_SUCCESS: `${TRANSITION_REPORTS_HISTORY} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_REPORTS_HISTORY_FAIL: `${TRANSITION_REPORTS_HISTORY} ${apiTypes.API_FAIL}`,

    SEND_TRANSITION_SCORES_REQUEST: `${TRANSITION_SCORES} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_SCORES_SUCCESS: `${TRANSITION_SCORES} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_SCORES_FAIL: `${TRANSITION_SCORES} ${apiTypes.API_FAIL}`,

    SEND_TRANSITION_MEASUREMENTS_REQUEST: `${TRANSITION_MEASUREMENTS} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_MEASUREMENTS_SUCCESS: `${TRANSITION_MEASUREMENTS} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_MEASUREMENTS_FAIL: `${TRANSITION_MEASUREMENTS} ${apiTypes.API_FAIL}`,

    SEND_TRANSITION_MEASUREMENT_DEFAULT_REQUEST: `${TRANSITION_MEASUREMENT_DEFAULT} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_MEASUREMENT_DEFAULT_SUCCESS: `${TRANSITION_MEASUREMENT_DEFAULT} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_MEASUREMENT_DEFAULT_FAIL: `${TRANSITION_MEASUREMENT_DEFAULT} ${apiTypes.API_FAIL}`,

    POST_TRANSITION_MEASUREMENT_REQUEST: `${TRANSITION_MEASUREMENT} ${apiTypes.API_REQUEST} post`,
    POST_TRANSITION_MEASUREMENT_SUCCESS: `${TRANSITION_MEASUREMENT} ${apiTypes.API_SUCCESS} post`,
    POST_TRANSITION_MEASUREMENT_FAIL: `${TRANSITION_MEASUREMENT} ${apiTypes.API_FAIL} post`,

    PATCH_TRANSITION_MEASUREMENT_REQUEST: `${TRANSITION_MEASUREMENT} ${apiTypes.API_REQUEST} patch`,
    PATCH_TRANSITION_MEASUREMENT_SUCCESS: `${TRANSITION_MEASUREMENT} ${apiTypes.API_SUCCESS} patch`,
    PATCH_TRANSITION_MEASUREMENT_FAIL: `${TRANSITION_MEASUREMENT} ${apiTypes.API_FAIL} patch`,

    SEND_TRANSITION_GALLERY_REQUEST: `${TRANSITION_GALLERY} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_GALLERY_SUCCESS: `${TRANSITION_GALLERY} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_GALLERY_FAIL: `${TRANSITION_GALLERY} ${apiTypes.API_FAIL}`,

    SEND_TRANSITION_MEASUREMENT_COUNT_REQUEST: `${TRANSITION_MEASUREMENT_COUNT} ${apiTypes.API_REQUEST}`,
    SEND_TRANSITION_MEASUREMENT_COUNT_SUCCESS: `${TRANSITION_MEASUREMENT_COUNT} ${apiTypes.API_SUCCESS}`,
    SEND_TRANSITION_MEASUREMENT_COUNT_FAIL: `${TRANSITION_MEASUREMENT_COUNT} ${apiTypes.API_FAIL}`,

    // writing actions (reducer)
    SET_TRANSITION_DAILY_REPORTS: `${TRANSITION_DAILY_REPORTS} set`,
    RESTORE_TRANSITION_DAILY_REPORTS_INITIAL_STATE: `${TRANSITION_DAILY_REPORTS} restore`,

    SET_TRANSITION_DAILY_REPORT: `${TRANSITION_DAILY_REPORT} set`,
    RESTORE_TRANSITION_DAILY_REPORT_INITIAL_STATE: `${TRANSITION_DAILY_REPORT} restore`,

    SET_TRANSITION_WEEKLY_REPORTS: `${TRANSITION_WEEKLY_REPORTS} set`,
    RESTORE_TRANSITION_WEEKLY_REPORTS_INITIAL_STATE: `${TRANSITION_WEEKLY_REPORTS} restore`,

    SET_TRANSITION_WEEKLY_REPORT: `${TRANSITION_WEEKLY_REPORT} set`,
    RESTORE_TRANSITION_WEEKLY_REPORT_INITIAL_STATE: `${TRANSITION_WEEKLY_REPORT} restore`,

    SET_TRANSITION_REPORTS_HISTORY: `${TRANSITION_REPORTS_HISTORY} set`,
    RESTORE_TRANSITION_REPORTS_HISTORY_INITIAL_STATE: `${TRANSITION_REPORTS_HISTORY} restore`,

    SET_TRANSITION_SCORES: `${TRANSITION_SCORES} set`,
    RESTORE_TRANSITION_SCORES_INITIAL_STATE: `${TRANSITION_SCORES} restore`,

    SET_TRANSITION_MEASUREMENTS: `${TRANSITION_MEASUREMENTS} set`,
    RESTORE_TRANSITION_MEASUREMENTS_INITIAL_STATE: `${TRANSITION_MEASUREMENTS} restore`,

    SET_TRANSITION_MEASUREMENT_DEFAULT: `${TRANSITION_MEASUREMENT_DEFAULT} set`,
    RESTORE_TRANSITION_MEASUREMENT_DEFAULT_INITIAL_STATE: `${TRANSITION_MEASUREMENT_DEFAULT} restore`,

    SET_TRANSITION_GALLERY: `${TRANSITION_GALLERY} set`,
    RESTORE_TRANSITION_GALLERY_INITIAL_STATE: `${TRANSITION_GALLERY} restore`,

    SET_TRANSITION_MEASUREMENT_COUNT: `${TRANSITION_MEASUREMENT_COUNT} set`,
    RESTORE_TRANSITION_MEASUREMENT_COUNT_INITIAL_STATE: `${TRANSITION_MEASUREMENT_COUNT} restore`,

    RESTORE_TRANSITION_INITIAL_STATE: `${TRANSITION} restore`,
};

// reducer actions
export const setTransitionDailyReports = (payload) => ({
    type: types.SET_TRANSITION_DAILY_REPORTS,
    payload: payload,
});

export const setTransitionDailyReport = (payload) => ({
    type: types.SET_TRANSITION_DAILY_REPORT,
    payload: payload,
});

export const setTransitionWeeklyReports = (payload) => ({
    type: types.SET_TRANSITION_WEEKLY_REPORTS,
    payload: payload,
});

export const setTransitionWeeklyReport = (payload) => ({
    type: types.SET_TRANSITION_WEEKLY_REPORT,
    payload: payload,
});

export const setTransitionReportsHistory = (payload) => ({
    type: types.SET_TRANSITION_REPORTS_HISTORY,
    payload: payload,
});

export const setTransitionScores = (payload) => ({
    type: types.SET_TRANSITION_SCORES,
    payload: payload,
});

export const setTransitionMeasurements = (payload) => ({
    type: types.SET_TRANSITION_MEASUREMENTS,
    payload: payload,
});

export const setTransitionMeasurementDefault = (payload) => ({
    type: types.SET_TRANSITION_MEASUREMENT_DEFAULT,
    payload: payload,
});

export const setTransitionGallery = (payload) => ({
    type: types.SET_TRANSITION_GALLERY,
    payload: payload,
});

export const setTransitionMeasurementCount = (payload) => ({
    type: types.SET_TRANSITION_MEASUREMENT_COUNT,
    payload: payload,
});

export const restoreTransitionInitialState = () => ({
    type: types.RESTORE_TRANSITION_INITIAL_STATE,
});

export const restoreTransitionDailyReportsInitialState = () => ({
    type: types.RESTORE_TRANSITION_DAILY_REPORTS_INITIAL_STATE,
});

export const restoreTransitionDailyReportInitialState = () => ({
    type: types.RESTORE_TRANSITION_DAILY_REPORT_INITIAL_STATE,
});

export const restoreTransitionWeeklyReportsInitialState = () => ({
    type: types.RESTORE_TRANSITION_WEEKLY_REPORTS_INITIAL_STATE,
});

export const restoreTransitionReportsHistoryInitialState = () => ({
    type: types.RESTORE_TRANSITION_REPORTS_HISTORY_INITIAL_STATE,
});

export const restoreTransitionWeeklyReportInitialState = () => ({
    type: types.RESTORE_TRANSITION_WEEKLY_REPORT_INITIAL_STATE,
});

export const restoreTransitionScoresInitialState = () => ({
    type: types.RESTORE_TRANSITION_SCORES_INITIAL_STATE,
});

export const restoreTransitionMeasurementsInitialState = () => ({
    type: types.RESTORE_TRANSITION_MEASUREMENTS_INITIAL_STATE,
});

export const restoreTransitionMeasurementDefaultInitialState = () => ({
    type: types.RESTORE_TRANSITION_MEASUREMENT_DEFAULT_INITIAL_STATE,
});

export const restoreTransitionGalleryInitialState = () => ({
    type: types.RESTORE_TRANSITION_GALLERY_INITIAL_STATE,
});

export const restoreTransitionMeasurementCountInitialState = () => ({
    type: types.RESTORE_TRANSITION_MEASUREMENT_COUNT_INITIAL_STATE,
});

// middleware actions
export const getTransitionDailyReports = ({ from, to }) => ({
    type: types.GET_TRANSITION_DAILY_REPORTS,
    payload: { from, to },
});

export const getTransitionDailyReport = (date) => ({
    type: types.GET_TRANSITION_DAILY_REPORT,
    payload: date,
});

export const clearTransition = () => ({
    type: types.CLEAR_TRANSITION,
});

export const clearTransitionDailyReports = () => ({
    type: types.CLEAR_TRANSITION_DAILY_REPORTS,
});

export const clearTransitionDailyReport = () => ({
    type: types.CLEAR_TRANSITION_DAILY_REPORT,
});

export const clearTransitionWeeklyReports = () => ({
    type: types.CLEAR_TRANSITION_WEEKLY_REPORTS,
});

export const clearTransitionWeeklyReport = () => ({
    type: types.CLEAR_TRANSITION_WEEKLY_REPORT,
});

export const clearTransitionReportsHistory = () => ({
    type: types.CLEAR_TRANSITION_REPORTS_HISTORY,
});

export const clearTransitionScores = () => ({
    type: types.CLEAR_TRANSITION_SCORES,
});

export const clearTransitionMeasurements = () => ({
    type: types.CLEAR_TRANSITION_MEASUREMENTS,
});

export const clearTransitionMeasurementDefault = () => ({
    type: types.CLEAR_TRANSITION_MEASUREMENT_DEFAULT,
});

export const clearTransitionGallery = () => ({
    type: types.CLEAR_TRANSITION_GALLERY,
});

export const clearTransitionMeasurementCount = () => ({
    type: types.CLEAR_TRANSITION_MEASUREMENT_COUNT,
});

export const createTransitionDailyReport = ({ values, date, formik, modalId }) => ({
    type: types.CREATE_TRANSITION_DAILY_REPORT,
    payload: { values, date },
    meta: { formikBag: formik, modalId },
});

export const updateTransitionDailyReport = ({ values, date, formik, modalId }) => ({
    type: types.UPDATE_TRANSITION_DAILY_REPORT,
    payload: { values, date },
    meta: { formikBag: formik, modalId },
});

export const deleteTransitionDailyReport = (date) => ({
    type: types.DELETE_TRANSITION_DAILY_REPORT,
    payload: date,
});

export const getTransitionWeeklyReports = () => ({
    type: types.GET_TRANSITION_WEEKLY_REPORTS,
});

export const submitTransitionWeeklyReports = ({ values, formik, modalId }) => ({
    type: types.SUBMIT_TRANSITION_WEEKLY_REPORTS,
    payload: { values },
    meta: { formikBag: formik, modalId },
});

export const getTransitionWeeklyReport = (payload) => ({
    type: types.GET_TRANSITION_WEEKLY_REPORT,
    payload: payload,
});

export const getTransitionReportsHistory = ({ params, page } = {}) => ({
    type: types.GET_TRANSITION_REPORTS_HISTORY,
    payload: { params, page },
});

export const createTransitionWeeklyReport = ({ values, formik, modalId }) => ({
    type: types.CREATE_TRANSITION_WEEKLY_REPORT,
    payload: { values },
    meta: { formikBag: formik, modalId },
});

export const updateTransitionWeeklyReport = ({ values, weekId, formik, modalId }) => ({
    type: types.UPDATE_TRANSITION_WEEKLY_REPORT,
    payload: { values, weekId },
    meta: { formikBag: formik, modalId },
});

export const deleteTransitionWeeklyReport = (payload) => ({
    type: types.DELETE_TRANSITION_WEEKLY_REPORT,
    payload: payload,
});

export const getTransitionScores = (params = {}) => ({
    type: types.GET_TRANSITION_SCORES,
    payload: params,
});

export const getTransitionMeasurements = (params = {}) => ({
    type: types.GET_TRANSITION_MEASUREMENTS,
    payload: params,
});

export const getTransitionMeasurementDefault = () => ({
    type: types.GET_TRANSITION_MEASUREMENT_DEFAULT,
});

export const createTransitionMeasurement = ({ values, formik, modalId }) => ({
    type: types.CREATE_TRANSITION_MEASUREMENT,
    payload: { values },
    meta: { formikBag: formik, modalId },
});

export const updateTransitionMeasurement = ({ values, measurementId, formik, modalId }) => ({
    type: types.UPDATE_TRANSITION_MEASUREMENT,
    payload: { values, measurementId },
    meta: { formikBag: formik, modalId },
});

export const getTransitionGallery = (params) => ({
    type: types.GET_TRANSITION_GALLERY,
    payload: params,
});

export const getTransitionMeasurementCount = () => ({
    type: types.GET_TRANSITION_MEASUREMENT_COUNT,
});
