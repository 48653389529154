import React from 'react';
import { navigate } from '@reach/router';

import {
    content,
    imageWrapper,
    wrapper,
    image,
    tvIconWrapper,
    tvIcon,
    iconInfoWrapper,
    lockIconWrapper,
    listItem,
    iconLock,
    timeIcon,
    bottomHolder,
    buttonHolder,
    placeHolder,
    placeHolderContent,
    timeIconWrapper,
    ratioClass,
} from './courses-list-item.module.scss';
import TvIcon from '../../../../../assets/images/svg/tv.svg';
import TimeIcon from '../../../../../assets/images/svg/time.svg';
import OkIcon from '../../../../../assets/images/svg/ok.svg';
import IconLock from '../../../../../assets/images/svg/icon-lock.svg';
import { humanizedSeconds } from '../../../../../communication/utils';
import { getAbsolutePath, PUBLIC_ROUTES } from '../../../../../routes';

import Button from '../../../../atoms/button';
import RatioImage from '../../../../atoms/ratio-image';

const CoursesListItem = ({ item }) => {
    const { points, moviesCount, moviesTimeCount, coverListUri, name, isBought, id } = item;

    const navigateToCourse = () => {
        navigate(`${getAbsolutePath('PUBLIC_APP_COURSE', PUBLIC_ROUTES)}/${id}`);
    };

    return (
        <article className={wrapper}>
            {!isBought && (
                <div className={lockIconWrapper}>
                    <IconLock className={iconLock} />
                </div>
            )}
            <button onClick={navigateToCourse} className={imageWrapper} tabIndex={-1}>
                <RatioImage
                    src={coverListUri}
                    className={image}
                    alt="Miniatura kursu"
                    ratioClass={ratioClass}
                />
                <div className={placeHolder}>
                    <div className={placeHolderContent}>{name}</div>
                </div>
            </button>
            <div className={bottomHolder}>
                <div className={iconInfoWrapper}>
                    <div className={tvIconWrapper}>
                        <TvIcon className={tvIcon} />
                        Filmy: {moviesCount}
                    </div>
                    <div className={timeIconWrapper}>
                        <TimeIcon className={timeIcon} />
                        {humanizedSeconds(moviesTimeCount)}
                    </div>
                </div>
                {points && (
                    <ul>
                        {points.map((point, index) => (
                            <li key={`point--${index}`} className={listItem}>
                                <OkIcon />
                                <span className={content}>{point.content}</span>
                            </li>
                        ))}
                    </ul>
                )}
                <div className={buttonHolder}>
                    <Button color={'yellow'} fullwidth={true} onClick={navigateToCourse}>
                        PRZEJDŹ
                    </Button>
                </div>
            </div>
        </article>
    );
};

export default CoursesListItem;
