import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    header,
    listBox,
    list,
    listItem,
    newAvailableMessage,
    newAvailableButton,
} from './notifications.module.scss';
import { selectNotifications } from '../../../redux/notifications/notifications.selectors';
import {
    clearNotificationsItemsAction,
    getNotificationsAction,
    NOTIFICATIONS,
} from '../../../redux/notifications/notifications.actions';
import { selectLoaderByEntity } from '../../../redux/ui/ui.selectors';
import { config } from '../../../config';

import InfinityList from '../../molecules/list/infinity-list';
import Notification from '../../molecules/notification';
import Button from '../../atoms/button';

const { apiStatusMap } = config;

const Notifications = () => {
    const dispatch = useDispatch();
    const { items, pagination, newAvailable } = useSelector(selectNotifications);
    const status = useSelector((state) => selectLoaderByEntity(state, NOTIFICATIONS));

    const getNextPage = () => {
        dispatch(getNotificationsAction(pagination.currentPage + 1, pagination.perPage));
    };

    const handleNewAvailable = () => {
        dispatch(clearNotificationsItemsAction());
    };

    useEffect(() => {
        return () => {
            dispatch(clearNotificationsItemsAction());
        };
    }, [dispatch]);

    return (
        <div className={container}>
            <h3 className={header}>
                Tutaj znajdziesz listę wszystkich powiadomień na&nbsp;temat nowych przepisów,
                treningów, awarii oraz wielu innych rzeczy związanych z&nbsp;Tobą
                i&nbsp;ze&nbsp;Strefą Przemian.
            </h3>
            {newAvailable && (
                <>
                    <p className={newAvailableMessage}>
                        Dostępne nowe powiadomienia, naciśnij przycisk żeby je zobaczyć.
                    </p>
                    <Button
                        className={newAvailableButton}
                        color="yellow"
                        onClick={handleNewAvailable}
                        size="small"
                    >
                        Zobacz nowe powiadomienia
                    </Button>
                </>
            )}
            <InfinityList
                className={listBox}
                listClassName={list}
                callbackFunction={getNextPage}
                loading={apiStatusMap.loading === status}
                items={transformItems(items)}
                ItemComponent={Notification}
            />
        </div>
    );
};

const transformItems = (items) =>
    items.map((item) => ({ item, className: listItem, TitleTag: 'h4' }));

export default Notifications;
