import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import {
    name,
    closed,
    accountButton,
    authLinks,
    authMobileLinks,
    authMobileLogo,
    authMobileContainer,
    avatarBox,
    chevron,
    desktopContainer,
    desktopNavButton,
    desktopNavItem,
    desktopNavLink,
    desktopNavList,
    dropdownContainer,
    level1,
    linkActive,
    linkPartiallyActive,
    mobileContainer,
    mobileLogoutBox,
    mobileLogoutButton,
    mobileNav,
    mobileNavButton,
    mobileNavItem,
    mobileNavLink,
    mobileNavList,
    mobilePanelBottom,
    mobilePanelContainer,
    mobileSwitchProfileButton,
    mobileSwitchProfileRow,
    msgCounter,
    separator,
    authMobile,
} from './account-widget.module.scss';
import Logo from '../../assets/images/svg/logo.svg';
import EnvelopeIcon from '../../assets/images/svg/envelope.svg';
import ChevronIcon from '../../assets/images/svg/chevron-down.svg';
import { getRouteNodeAbsoluteNav } from '../../routes';
import { removeCurrentProfileId } from '../../redux/profile/profile.actions';
import { handleAvatarString } from '../../redux/utills/nameUtill';
import {
    getCurrentProfile,
    getCurrentProfileId,
    getProfileAvatarUriByProfileId,
    isMoreThanOneProfile,
} from '../../redux/profile/profile.selectors';
import { logoutUser } from '../../redux/user/user.actions';
import { config } from '../../config';
import useHasMounted from '../../hooks/useHasMounted';

import NavItem from '../atoms/nav-item';
import MobileNavPanel from '../organisms/mobile-nav-panel';
import Button from '../atoms/button';
import NestedNav from './nested-nav';
import Dropdown from './dropdown';
import Separator from '../atoms/separator';
import Avatar from '../atoms/avatar';
import AvatarChange from './avatar-change';
import CounterDot from '../atoms/counter-dot';

const { counters } = config;

const AccountWidget = () => {
    const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    const accountNavItems = getRouteNodeAbsoluteNav('APP_MY_ACCOUNT');
    const dispatch = useDispatch();

    const { userData, profileId, avatarUrl, moreThanOneProfile, currentProfile } = useSelector(
        (state) => ({
            userData: state.user.userData,
            profileId: getCurrentProfileId(state),
            currentProfile: getCurrentProfile(state),
            avatarUrl: getProfileAvatarUriByProfileId(state, getCurrentProfileId(state)),
            moreThanOneProfile: isMoreThanOneProfile(state),
        })
    );

    /* It's a hack - Probably React's hydration take longer time than getting user data, so when React tries to hydrate
     * this component its html tree is different from the one from server. With this hook we check if component
     * is really mounted, and then we change its html structure.
     */
    const hasMounted = useHasMounted();

    useEffect(() => {
        const htmlRef = document.getElementsByTagName('html');
        if (isMobileNavOpen) {
            htmlRef[0].classList.add('mobile-nav-open');
        } else {
            htmlRef[0].classList.remove('mobile-nav-open');
        }
    }, [isMobileNavOpen]);

    return (
        <>
            {userData && hasMounted ? (
                <>
                    <div className={desktopContainer}>
                        <button className={accountButton} onClick={() => setIsDesktopNavOpen(true)}>
                            <div className={avatarBox}>
                                <Avatar avatarUri={avatarUrl} size="small" hasShadow={false} />
                                <CounterDot type={counters.notifications} isAbsolute={true} />
                            </div>
                            <p className={name}>
                                {profileId &&
                                    handleAvatarString(
                                        currentProfile.firstName,
                                        currentProfile.lastName
                                    )}
                            </p>
                            <div className={chevron}>
                                <ChevronIcon />
                            </div>
                        </button>

                        <Dropdown
                            open={isDesktopNavOpen}
                            closeFunc={() => setIsDesktopNavOpen(false)}
                        >
                            <div className={dropdownContainer}>
                                <NestedNav
                                    navItems={accountNavItems}
                                    buttonContent={<ChevronIcon />}
                                    onItemClick={() => setIsDesktopNavOpen(false)}
                                    classes={desktopNavClasses}
                                    collapse={!isDesktopNavOpen}
                                />
                                <Separator className={separator} />
                                {moreThanOneProfile ? (
                                    <button
                                        className={desktopNavLink}
                                        onClick={async () => {
                                            dispatch(removeCurrentProfileId());
                                            await navigate('/app');
                                        }}
                                    >
                                        Przełącz użytkownika
                                    </button>
                                ) : null}
                                <button
                                    className={desktopNavLink}
                                    onClick={() => dispatch(logoutUser())}
                                >
                                    Wyloguj się
                                </button>
                            </div>
                        </Dropdown>
                    </div>

                    <div className={desktopContainer}>
                        <button
                            className={accountButton}
                            onClick={() => navigate('/app/moje-konto/wiadomosci')}
                        >
                            <div className={avatarBox}>
                                <EnvelopeIcon />
                                <CounterDot
                                    className={msgCounter}
                                    type={counters.messages}
                                    isAbsolute={true}
                                />
                            </div>
                        </button>
                    </div>
                </>
            ) : (
                <div className={desktopContainer}>
                    <div className={authLinks}>
                        <NavItem to="/login/">Zaloguj się</NavItem>
                        <NavItem to="/register/">Załóż konto</NavItem>
                    </div>
                </div>
            )}

            <div className={mobileContainer}>
                <button className={accountButton} onClick={() => setIsMobileNavOpen(true)}>
                    <div className={avatarBox}>
                        <Avatar hasShadow={false} size="small" avatarUri={avatarUrl} />
                        <CounterDot type={counters.notifications} isAbsolute={true} />
                    </div>
                </button>

                {userData && hasMounted && (
                    <button
                        className={accountButton}
                        onClick={() => navigate('/app/moje-konto/wiadomosci')}
                    >
                        <div className={avatarBox}>
                            <EnvelopeIcon />
                            <CounterDot
                                className={msgCounter}
                                type={counters.messages}
                                isAbsolute={true}
                            />
                        </div>
                    </button>
                )}

                <MobileNavPanel
                    open={isMobileNavOpen}
                    closeFunc={() => setIsMobileNavOpen(false)}
                    dark={true}
                >
                    {userData && hasMounted ? (
                        <div className={mobilePanelContainer}>
                            <NestedNav
                                navItems={accountNavItems}
                                buttonContent={<ChevronIcon />}
                                onItemClick={() => setIsMobileNavOpen(false)}
                                classes={mobileNavClasses}
                                collapse={!isMobileNavOpen}
                            />
                            <div className={mobilePanelBottom}>
                                <div className={mobileSwitchProfileRow}>
                                    <AvatarChange name="top-bar-avatar-upload">
                                        <Avatar
                                            size="small"
                                            hasBorder={true}
                                            hasShadow={false}
                                            avatarUri={avatarUrl}
                                        />
                                    </AvatarChange>
                                    {moreThanOneProfile ? (
                                        <button
                                            className={mobileSwitchProfileButton}
                                            onClick={() => {
                                                dispatch(removeCurrentProfileId());
                                                navigate('/app');
                                            }}
                                        >
                                            Przełącz użytkownika
                                        </button>
                                    ) : null}
                                </div>
                                <div className={mobileLogoutBox}>
                                    <button
                                        className={mobileLogoutButton}
                                        onClick={() => dispatch(logoutUser())}
                                    >
                                        Wyloguj się
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={authMobile}>
                            <div className={authMobileContainer}>
                                <div className={authMobileLogo}>
                                    <Logo />
                                </div>
                                <div className={authMobileLinks}>
                                    <Link to="/login/" onClick={() => setIsMobileNavOpen(false)}>
                                        <Button fullwidth={true} color="white">
                                            Zaloguj się
                                        </Button>
                                    </Link>
                                    <Link to="/register/" onClick={() => setIsMobileNavOpen(false)}>
                                        <Button fullwidth={true} color="blankWhite">
                                            Zarejestruj się
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </MobileNavPanel>
            </div>
        </>
    );
};

const desktopNavClasses = {
    navList: desktopNavList,
    navListClosed: closed,
    navItem: desktopNavItem,
    navLink: desktopNavLink,
    button: desktopNavButton,
    level_1: level1,
};

const mobileNavClasses = {
    nav: mobileNav,
    navList: mobileNavList,
    navListClosed: closed,
    navItem: mobileNavItem,
    navLink: mobileNavLink,
    navLinkActive: linkActive,
    navLinkPartiallyActive: linkPartiallyActive,
    button: mobileNavButton,
    level_1: level1,
};

export default AccountWidget;
