import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import BellIcon from '../assets/images/svg/bell.svg';
import EnvelopeIcon from '../assets/images/svg/envelope.svg';
import { selectNotificationsNotReadCount } from '../redux/notifications/notifications.selectors';
import { getFormattedDate } from '../utills/date-utils';
import { selectMessageCount } from '../redux/message-count/message-count.selectors';
import { getAbsolutePath } from '../routes';
import { selectUserData } from '../redux/user/user.selectors';

const NOTIFICATION_SMOKE_KEY = 'notificationSmokeDate';
const MESSAGE_SMOKE_KEY = 'messageSmokeDate';

interface INotificationSmokeState {
    count: number;
    latestMessage: null | { title: string };
}

interface IMessageSmokeState {
    count: number;
    latestMessage: null | { content: string };
}

export const useSmokes = () => {
    const notificationCount: INotificationSmokeState = useSelector(selectNotificationsNotReadCount);
    const messageCount: IMessageSmokeState = useSelector(selectMessageCount);
    const isLogged = useSelector(selectUserData) !== null;

    const [showNotificationSmoke, setShowNotificationSmoke] = useState(false);
    const [showMessageSmoke, setShowMessageSmoke] = useState(false);

    const messageRedirectUrl = useMemo(() => getAbsolutePath('APP_MY_ACCOUNT_MESSENGER'), []);
    const notificationRedirectUrl = useMemo(
        () => getAbsolutePath('APP_MY_ACCOUNT_NOTIFICATIONS'),
        []
    );

    const handleClose = (type: 'message' | 'notification') => {
        const today = getFormattedDate(new Date().getTime());
        if (type === 'notification') {
            setShowNotificationSmoke(false);
            localStorage.setItem(NOTIFICATION_SMOKE_KEY, today);
        }
        if (type === 'message') {
            setShowMessageSmoke(false);
            localStorage.setItem(MESSAGE_SMOKE_KEY, today);
        }
    };

    useEffect(() => {
        if (!isLogged) {
            setShowNotificationSmoke(false);
            setShowMessageSmoke(false);
        } else {
            const today = getFormattedDate(new Date().getTime());
            const notificationSmokeLastShown = localStorage.getItem(NOTIFICATION_SMOKE_KEY);
            const messageSmokeLastShown = localStorage.getItem(MESSAGE_SMOKE_KEY);
            if (notificationCount.count > 0) {
                if (!notificationSmokeLastShown) {
                    setShowNotificationSmoke(true);
                } else {
                    setShowNotificationSmoke(today !== notificationSmokeLastShown);
                }
            }
            if (messageCount.count > 0) {
                if (!messageSmokeLastShown) {
                    setShowMessageSmoke(true);
                } else {
                    setShowMessageSmoke(today !== messageSmokeLastShown);
                }
            }
        }
    }, [isLogged, messageCount.count, notificationCount.count]);

    return {
        message: {
            props: {
                title: copy.messageTitle,
                Icon: EnvelopeIcon,
                redirectUrl: messageRedirectUrl,
                unreadCount: messageCount.count,
                content: messageCount.latestMessage?.content,
                onClose: () => handleClose('message'),
            },
            show: showMessageSmoke,
        },
        notification: {
            props: {
                title: copy.notificationTitle,
                Icon: BellIcon,
                redirectUrl: notificationRedirectUrl,
                unreadCount: notificationCount.count,
                content: notificationCount.latestMessage?.title,
                onClose: () => handleClose('notification'),
            },
            show: showNotificationSmoke,
        },
    };
};

const copy = {
    messageTitle: 'Sprawdź nową wiadomość',
    notificationTitle: 'Sprawdź nowe powiadomienie',
};
