// extracted by mini-css-extract-plugin
export var button = "transition-gallery-card-module--button--PJAlP";
export var container = "transition-gallery-card-module--container--RYj+8";
export var count = "transition-gallery-card-module--count--XgonU";
export var dataBox = "transition-gallery-card-module--data-box--72xAN";
export var dataRow = "transition-gallery-card-module--data-row--LTnPt";
export var dataValue = "transition-gallery-card-module--data-value--cXV7X";
export var date = "transition-gallery-card-module--date--VWm1F";
export var iconBox = "transition-gallery-card-module--icon-box--ypB2v";
export var image = "transition-gallery-card-module--image--KzB7I";
export var imageBox = "transition-gallery-card-module--image-box--vgXBO";
export var noImage = "transition-gallery-card-module--no-image--YkgmM";