import React, { useEffect, useState } from 'react';

import { getUserTokenData } from '../../utills/get-user-token-data';

interface IProtectedImageProps {
    className?: string;
    src: string;
    alt?: string;
}

const ProtectedImage: React.FC<IProtectedImageProps> = ({ className = '', src, alt = '' }) => {
    const [objectUrl, setObjectUrl] = useState('');
    const tokenData = getUserTokenData();
    const token = tokenData?.token;

    const handleLoad = () => {
        URL.revokeObjectURL(objectUrl);
    };

    useEffect(() => {
        const getProtectedImage = async () => {
            const headers = new Headers();
            headers.set('Authorization', `Bearer ${token}`);
            const response = await fetch(src, { headers });
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            setObjectUrl(objectUrl);
        };
        getProtectedImage();
    }, [src, token]);

    return <img className={className} src={objectUrl} onLoad={handleLoad} alt={alt} />;
};

export default ProtectedImage;
