// extracted by mini-css-extract-plugin
export var banner = "welcome-module--banner--43z3j";
export var challenge = "welcome-module--challenge--+aE4K";
export var change = "welcome-module--change--BbSao";
export var develop = "welcome-module--develop--uMrm6";
export var diet = "welcome-module--diet--eSZLa";
export var forum = "welcome-module--forum--gET5m";
export var list = "welcome-module--list--Xrjxf";
export var news = "welcome-module--news--OpOW+";
export var notify = "welcome-module--notify--137cW";
export var prize = "welcome-module--prize--loeit";
export var title = "welcome-module--title--WZ2oh";