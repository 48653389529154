import React from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';

import userHaveRightsTo from '../../rbac/utils';
import { getAbsolutePath } from '../../routes';
import { getCurrentProfile, getCurrentProfileId } from '../../redux/profile/profile.selectors';

const PrivateRoute = (props) => {
    const { location, path, redirect, children, Component, rbacAction, ...rest } = props;

    const excludedOnboardingPathsMap = excludedOnboardingPaths.map((path) => getAbsolutePath(path));

    const { userData, currentProfileId, rbac, currentProfile } = useSelector((state) => ({
        userData: state.user.userData,
        currentProfileId: getCurrentProfileId(state),
        currentProfile: getCurrentProfile(state),
        rbac: state.rbac,
    }));

    let component = null,
        navTo = null;

    switch (true) {
        case userData === null && location.pathname !== '/login':
            navTo = { path: '/login' };
            break;

        case currentProfileId === null &&
            location.pathname !== getAbsolutePath('APP_CHOOSE_PROFILE'):
            navTo = getAbsolutePath('APP_CHOOSE_PROFILE');
            navTo = { path: getAbsolutePath('APP_CHOOSE_PROFILE') };
            break;

        case currentProfile &&
            !currentProfile.watchedOnboardingVideoInSession &&
            !excludedOnboardingPathsMap.includes(location.pathname):
            navTo = { path: getAbsolutePath('APP_ONBOARDING_MOVIE') };
            break;

        case currentProfileId !== null &&
            location.pathname === getAbsolutePath('APP_CHOOSE_PROFILE'):
            navTo = { path: getAbsolutePath('APP') };
            break;

        case rbacAction && !userHaveRightsTo(rbacAction, rbac):
            navTo = {
                path: getAbsolutePath('APP_FORBIDDEN'),
                config: { replace: true },
            };
            break;

        case location.pathname.slice(-1) === '/':
            navTo = {
                path: location.pathname.slice(0, -1),
                config: { replace: true },
            };
            break;

        case redirect && redirect.from === location.pathname:
            navTo = { path: redirect.to, config: { replace: true } };

            break;

        case Component !== null:
            component = (
                <Component path={path} location={location} {...rest}>
                    {children}
                </Component>
            );
            break;
        default:
            component = <>{children}</>;
    }

    if (navTo?.path) {
        navigate(navTo.path, navTo.config);
    }

    return component;
};

export default PrivateRoute;

const excludedOnboardingPaths = ['APP_ONBOARDING_MOVIE'];
