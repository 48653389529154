import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import {
    title,
    list,
    banner,
    diet,
    develop,
    forum,
    challenge,
    notify,
    prize,
    change,
    news,
} from './welcome.module.scss';
import { getCurrentProfile } from '../../../redux/profile/profile.selectors';
import {
    clearWelcomeSlidesAction,
    getWelcomeSlidesAction,
} from '../../../redux/welcome-slides/welcome-slides.actions';

import Title from '../../atoms/title';
import LinkBanner from '../../molecules/link-banner';

const Welcome = () => {
    const {
        transitionImg,
        dietImg,
        developmentImg,
        forumImg,
        challengesImg,
        notificationsImg,
        prizesImg,
        newsImg,
    } = useStaticQuery(query);
    const dispatch = useDispatch();
    const currentProfile = useSelector(getCurrentProfile);

    const links = [
        {
            id: 1,
            to: '/app/moje-konto/dieta',
            description:
                'Tu znajdziesz pyszne przepisy, listę zakupową oraz planer posiłków, a w nim jadłospisy.',
            image: getImage(dietImg),
            title: 'Dieta',
            className: `${banner} ${diet}`,
        },
        {
            id: 2,
            to: '/app/moje-konto/strefa-rozwoju',
            description:
                'Tu są wszystkie ćwiczenia, treningi personalne, wyzwania, programy treningowe oraz kursy.',
            image: getImage(developmentImg),
            title: 'Strefa rozwoju',
            className: `${banner} ${develop}`,
        },
        {
            id: 3,
            to: '/app/moje-konto/grupa',
            description:
                'Tu jest forum naszej społeczności, w którym znajdziesz mnóstwo wartości, motywacji i wsparcia.',
            image: getImage(forumImg),
            title: 'Grupa',
            className: `${banner} ${forum}`,
        },
        {
            id: 4,
            to: '/app/moje-konto/strefa-rozwoju/wyzwania',
            description:
                'Tu odkryjesz poprzednie, aktualne i nadchodzące wyzwania, które systematycznie organizujemy dla naszej społeczności.',
            image: getImage(challengesImg),
            title: 'Wyzwania',
            className: `${banner} ${challenge}`,
        },
        {
            id: 5,
            to: '/app/moje-konto/powiadomienia',
            description: 'Bądź na bieżąco z najnowszymi informacjami i aktualizacjami.',
            image: getImage(notificationsImg),
            title: 'Powiadomienia',
            className: `${banner} ${notify}`,
        },
        {
            id: 6,
            to: '/app/moje-konto/twoje-ketony',
            description:
                'Doceniamy Twoją aktywność i dodatkowo wynagradzamy za zaangażowanie. Opłaca się być w Strefie Przemian.',
            image: getImage(prizesImg),
            title: 'Nagrody',
            className: `${banner} ${prize}`,
        },
        {
            id: 7,
            to: '/app/moje-konto/przemiana',
            description:
                'Śledź swoje postępy! Zapisuj swoje pomiary, by monitorować efekty na bieżąco.',
            image: getImage(transitionImg),
            title: 'Moja przemiana i raporty',
            className: `${banner} ${change}`,
        },
        {
            id: 8,
            // here we go again 🦞😅
            to: '/app/moje-konto/grupa/post/15',
            description: 'Odkrywaj ważne ogłoszenia i zapowiedzi dla naszej społeczności',
            image: getImage(newsImg),
            title: 'Aktualności',
            className: `${banner} ${news}`,
        },
    ];

    useEffect(() => {
        dispatch(getWelcomeSlidesAction());
        return () => {
            dispatch(clearWelcomeSlidesAction());
        };
    }, [dispatch]);

    return (
        <>
            <Title Tag="h3" className={title}>
                Witaj, {currentProfile && currentProfile.firstName}!
            </Title>
            <div className={list}>
                {links.map((link) => {
                    return <LinkBanner key={`item-link-${link.id}`} {...link} />;
                })}
            </div>
        </>
    );
};

const query = graphql`
    {
        transitionImg: file(relativePath: { eq: "banner-przemiana.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        newsImg: file(relativePath: { eq: "banner-news.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        dietImg: file(relativePath: { eq: "banner-dieta.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        developmentImg: file(relativePath: { eq: "banner-strefa-rozwoju.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        forumImg: file(relativePath: { eq: "banner-grupa.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        challengesImg: file(relativePath: { eq: "banner-wyzwania.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        notificationsImg: file(relativePath: { eq: "banner-powiadomienia.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        prizesImg: file(relativePath: { eq: "banner-nagrody.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        exerciseAtlasImg: file(relativePath: { eq: "banner-atlas-cwiczen.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        trainingAtlasImg: file(relativePath: { eq: "banner-atlas-treningow.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        personalTrainingImg: file(relativePath: { eq: "banner-trening-personalny.png" }) {
            childImageSharp {
                gatsbyImageData(width: 550, quality: 99, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
    }
`;

export default Welcome;
