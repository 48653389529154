const API_URL = process.env.API_URL;
const RECAPTCHA = process.env.RECAPTCHA;
const YOUTUBE_KEY = process.env.YOUTUBE_KEY;
const PAYU_SDK_SRC = process.env.PAYU_SDK_SRC;
const PAYU_POS_ID = process.env.PAYU_POS_ID;
const GOOGLE_SITE_VERIFICATION = process.env.GOOGLE_SITE_VERIFICATION;

exports.config = {
    endpoints: {
        baseApiUrl: `${API_URL}`,
        accountData: {
            base: `${API_URL}/profiles`,
            edit: (id) => `${API_URL}/profiles/${id}`,
        },
        audit: {
            movie: `${API_URL}/save-movie-log`,
        },
        allCourses: (perPage) =>
            `${API_URL}/courses?expand=points,categories,categories.movies${
                perPage ? `&per-page=${perPage}` : ''
            }`,
        myCourses: (perPage) =>
            `${API_URL}/courses/my?expand=points,categories,categories.movies${
                perPage ? `&per-page=${perPage}` : ''
            }`,
        chosenCourse: (id) =>
            `${API_URL}/courses/${id}?expand=points,categories,categories.movies,categories.media`,
        tags: {
            allTags: (pageSize) => `${API_URL}/tags?per-page=${pageSize}`,
        },
        blog: {
            allPosts: (pageSize) =>
                `${API_URL}/posts?expand=tags,author,profiles,relatedPosts,relatedProducts,media,meta&per-page=${pageSize}`,
            posts: (page, pageSize, tags, search) =>
                `${API_URL}/posts?page=${page}&per-page=${pageSize}&sort=-publishedAt&expand=tags,author,profiles,media,meta${
                    tags ? `&filter[tags]=${tags}` : ''
                }${search ? `&filter[title][like]=${search}` : ''}`,
            categories: `${API_URL}/post-categories?per-page=512`,
        },
        faq: (page, pageSize) => `${API_URL}/faqs?page=${page}&per-page=${pageSize}`,
        contact: `${API_URL}/contacts`,
        courses: {
            course: {
                my: (page, perPage) =>
                    `/courses/my?expand=points,categories,categories.movies&page=${page}&per-page=${perPage}`,
                notMine: (page, perPage) =>
                    `/courses/not-mine?expand=points,categories,categories.movies&page=${page}&per-page=${perPage}`,
            },
            trainingProgram: {
                my: (page, perPage) =>
                    `/training-programs/my?expand=points,categories,categories.movies&page=${page}&per-page=${perPage}`,
                notMine: (page, perPage) =>
                    `/training-programs/not-mine?expand=points,categories,categories.movies&page=${page}&per-page=${perPage}`,
            },
            challenge: {
                my: (page, perPage) =>
                    `/challenges/my?expand=points,categories,categories.movies&page=${page}&per-page=${perPage}`,
                notMine: (page, perPage) =>
                    `/challenges/not-mine?expand=points,categories,categories.movies&page=${page}&per-page=${perPage}`,
            },
        },
        shop: {
            cart: (id = null) => (id === null ? `/carts` : `/carts/${id}`),
            categories: {
                tree: `${API_URL}/product-categories/tree`,
                all: `${API_URL}/product-categories`,
            },
            products: `/products?sort=isPromoted&per-page=50`,
            productsAll: (pageSize) =>
                `${API_URL}/products?per-page=${pageSize}&expand=variants,attributesDefinitions,producer,type,tax,kind,media,meta,relatedPosts,relatedProducts,variants.attributesValues,attributesDefinitions.values`,
            productDiscountsAll: `/products/promotions`,
            productDiscount: (id) => `/products/${id}/promotions`,
            order: `/orders`,
            orderValidate: `/orders/validate`,
            delivery: `/orders/deliveries`,
            orderPayUToken: (orderId) => `${API_URL}/orders/${orderId}/payu-token`,
            orderPayment: (paymentId) => `${API_URL}/order-payments/${paymentId}`,
            paymentStatus: (paymentId) => `${API_URL}/order-payments/${paymentId}/status`,
            orderStatus: (orderId) => `${API_URL}/orders/${orderId}/status`,
        },
        externalLinks: `${API_URL}/external-links`,
        sliders: `${API_URL}/sliders?expand=media`,
        socials: `${API_URL}/socials`,
        youtube: {
            subscriptions: (channelId) =>
                `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelId}&key=${YOUTUBE_KEY}`,
        },
        register: `${API_URL}/register`,
        login: `${API_URL}/login?expand=cart,cart.items,cart.items.product,cart.items.product.media`,
        logout: `${API_URL}/logout`,
        lostPassword: `${API_URL}/password-reset-request`,
        resetPassword: `${API_URL}/password-reset`,
        changePassword: `${API_URL}/password-change`,
        activateAccount: (token) => `${API_URL}/activate/${token}`,
        myAccount: `${API_URL}/my-account?expand=surveyProfiles,newsletterSubscribes,cart,cart.items,cart.items.product,cart.items.variant,cart.items.variant.attributesValues,cart.items.product.media,cart.items.product.promotions,cart.items.productPromotions,profiles.notificationSettings,profiles.favouriteRecipeIds,profiles.favouriteGroupThreadsIds`,
        myAccountDeactivate: `${API_URL}/my-account/deactivate`,
        account: `${API_URL}/accounts/`,
        meetUs: `${API_URL}/meet-us`,
        videoOpinions: `${API_URL}/opinions`,
        newsletterSignUp: `${API_URL}/newsletter-subscribe`,
        newsletterUnsubscribe: `${API_URL}/newsletter-unsubscribe`,
        personalTraining: {
            current: `/personal-trainings/current/profile`,
            single: `/personal-trainings`,
        },
        workout: {
            exercises: `/exercises`,
            trainings: `/trainings`,
            // @todo: clean after preparing enhancedList middleware
            exercise: (id) =>
                `${API_URL}/exercises/${id}?expand=bodyParts,difficulty,trainer,equipment,related,related.bodyParts,related.difficulty`,
            exerciseCollection: (page, pageSize, sort) =>
                `${API_URL}/exercises?page=${page}&per-page=${pageSize}&sort=${sort}&expand=bodyParts,equipment,difficulty`,
            trainingsCollection: (page, pageSize, sort) =>
                `${API_URL}/trainings?page=${page}&per-page=${pageSize}&&sort=${sort}&expand=bodyParts,equipment,difficulty`,
            training: (id) =>
                `${API_URL}/trainings/${id}?expand=bodyParts,difficulty,trainer,relatedTrainings,relatedTrainings.difficulty,relatedTrainings.bodyParts,equipment,intensity,group`,
        },
        addresses: {
            get: {
                all: `${API_URL}/addresses?per-page=50`,
                single: (id) => `${API_URL}/addresses/${id}`,
            },
            create: `${API_URL}/addresses`,
            edit: (id) => `${API_URL}/addresses/${id}`,
            delete: (id) => `${API_URL}/addresses/${id}`,
        },
        survey: {
            page: (page, perPage, profileId) =>
                `${API_URL}/survey-profiles/${profileId}?page=${page}&per-page=${perPage}&sort=-createdAt`,
            post: (profileId) => `${API_URL}/survey-profiles/${profileId}`,
            getAll: (profileId) => `${API_URL}/survey-profiles/${profileId}`,
            getSingle: (profileId, surveyToProfileId) =>
                `${API_URL}/survey-profiles/${profileId}/session/${surveyToProfileId}`,
            validate: (profileId) => `${API_URL}/survey-profiles/${profileId}/validate`,
        },
        metamorphosis: {
            all: `${API_URL}/metamorphosis`,
            page: (page, pageSize) =>
                `${API_URL}/metamorphosis?page=${page}&per-page=${pageSize}&sort=-createdAt`,
        },
        planner: {
            dietTypes: `/planner-meal-types`,
        },
        attachments: {
            basePath: (profileId) =>
                `${API_URL}/chats/profile/${profileId}/attachments?expand=attachments`,
        },
        messages: {
            fetch: ({ countToDownload, profileId }) =>
                `${API_URL}/chats/profile/${profileId}?expand=author,attachments,page=1&per-page=${countToDownload}`,
            findFetch: ({ phraseString, countToDownload, profileId }) =>
                `${API_URL}/chats/${profileId}/thread?expand=author,attachments&phrase=${phraseString}&page=1&per-page=${countToDownload}`,
            send: (profileId) => `${API_URL}/chats/profile/${profileId}`,
            sendMsgToTread: (profileId) => `${API_URL}/chats/${profileId}/thread`,
            fetchThread: ({ countToDownload, profileId }) =>
                `${API_URL}/chats/${profileId}/thread?expand=author,attachments,page=1&per-page=${countToDownload}`,
            notReadCount: (profileId) => `${API_URL}/chats/${profileId}/count?read=0`,
        },
        recipes: {
            all: {
                getPage: (page, pageSize, profileId, searchTerm) =>
                    `${API_URL}/recipes/profile/${profileId}/available?page=${page}&per-page=${pageSize}&sort=+name&filter[name][like]=${searchTerm}`,
            },
            publicEndpoint: `${API_URL}/recipes`,
            public: {
                getPage: (page, pageSize, searchTerm) =>
                    `${API_URL}/recipes?page=${page}&per-page=${pageSize}&sort=-createdAt&expand=mealTypes,preparationTime,difficultyLevel,favoriteByProfileIds&filter[name][like]=${searchTerm}`,
            },
            favorite: {
                getPage: (page, pageSize, profileId, searchTerm) =>
                    `${API_URL}/recipes/profile/${profileId}/favorite?page=${page}&per-page=${pageSize}&sort=-createdAt&expand=mealTypes,preparationTime,difficultyLevel,favoriteByProfileIds&filter[name][like]=${searchTerm}`,
            },
            your: {
                getPage: (page, pageSize, profileId, searchTerm) =>
                    `${API_URL}/recipes/profile/${profileId}?page=${page}&per-page=${pageSize}&sort=-createdAt&expand=mealTypes,preparationTime,difficultyLevel,favoriteByProfileIds&filter[name][like]=${searchTerm}`,
            },
            single: {
                get: (id) =>
                    `${API_URL}/recipes/${id}?expand=mealTypes,types,dietTypes,mealKinds,preparationTime,difficultyLevel,favoriteByProfileIds,preparationSteps,author,ingredients.replacements,rate,comments.author,relatedRecipes.mealTypes,relatedRecipes.preparationTime,relatedRecipes.difficultyLevel,relatedRecipes.favoriteByProfileIds`,
                create: `${API_URL}/recipes`,
                edit: (id) =>
                    `${API_URL}/recipes/${id}?expand=mealTypes,types,dietTypes,mealKinds,preparationTime,difficultyLevel,favoriteByProfileIds,preparationSteps,author,ingredients.replacements,rate,comments.author,relatedRecipes.mealTypes,relatedRecipes.preparationTime,relatedRecipes.difficultyLevel,relatedRecipes.favoriteByProfileIds`,
                delete: (id) => `${API_URL}/recipes/${id}`,
                editIsFavorite: (recipeId, profileId) =>
                    `${API_URL}/recipes/${recipeId}/profile/${profileId}/favorite`,
                rate: (id, profileId) => `${API_URL}/recipes/${id}/profile/${profileId}/rate`,
                pdf: (id) => `${API_URL}/recipes/${id}/pdf`,
                comment: (id, profileId) => `${API_URL}/comments/profile/${profileId}/recipe/${id}`,
                changeIngredientToProfile: (id, profileId) =>
                    `${API_URL}/recipes/${id}/profile/${profileId}/ingredient`,
            },
            ingredients: {
                get: `${API_URL}/recipes/ingredients?per-page=1000&expand=units`,
            },
            units: {
                get: `${API_URL}/recipes/units?per-page=1000`,
            },
        },
        mealPlanner: {
            planer: '/planners',
            templates: '/planner-templates',
            dietTypes: `/tags`,
            templateAuth: `/planner-templates/auth`,
        },
        subscriptions: {
            page: (page, perPage) => `${API_URL}/subscriptions?page=${page}&per-page=${perPage}`,
            get: `${API_URL}/subscriptions`,
            resign: (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}/resign`,
            upgrade: (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}/upgrade`,
            specialOffer: `${API_URL}/promotions/special-offer`,
            demandSpecialOffer: `${API_URL}/promotions/special-offer/create-application`,
        },
        shoppingLists: {
            all: (profileId, page, perPage) =>
                `${API_URL}/shoplists/profile/${profileId}?sort=-createdAt&page=${page}&per-page=${perPage}`,
            create: `${API_URL}/shoplists?expand=positions`,
            single: {
                get: (id, profileId) =>
                    `${API_URL}/shoplists/${id}/profile/${profileId}?expand=positions`,
                edit: (id) => `${API_URL}/shoplists/${id}?expand=positions`,
                delete: (id) => `${API_URL}/shoplists/${id}`,
                editFromRecipe: (id, profileId) =>
                    `${API_URL}/shoplists/${id}/profile/${profileId}?expand=positions`,
            },
            pdf: (id) => `${API_URL}/shoplists/${id}/pdf`,
        },
        filesToDownload: (page, perPage) =>
            `${API_URL}/files-to-downloads?page=${page}&per-page=${perPage}`,
        redirects: {
            all: (perPage = 1024) => `${API_URL}/redirects?per-page=${perPage}`,
        },
        notifications: {
            page: (page, perPage, profileId) =>
                `${API_URL}/notify/profile/${profileId}/messages?per-page=${perPage}&page=${page}&expand=message`,
            notReadCount: (profileId) => `${API_URL}/notify/profile/${profileId}/count?read=0`,
            notSeenCount: (profileId) => `${API_URL}/notify/profile/${profileId}/count?seen=0`,
            markAsRead: (profileId) => `${API_URL}/notify/profile/${profileId}/mark-as-read`,
            markAsSeen: (profileId) => `${API_URL}/notify/profile/${profileId}/mark-as-seen`,
        },
        gamification: {
            transactions: '/transactions?sort=-transactionId',
            tariff: '/transactions/tariff',
            transactionsValidate: '/transactions/validate',
        },
        partnersZone: {
            pool: '/partners-code-pools',
            buy: (id) => `/partners-code-pools/${id}/buy`,
            codes: '/partners-codes',
        },
        welcomeSlides: {
            get: {
                page: (page = 1, perPage = 50) =>
                    `/slider-accounts?expand=media&page=${page}&per-page=${perPage}&sort=+sequence`,
            },
        },
        forum: {
            comments: ({ page, perPage, threadId }) =>
                `/threads/${threadId}/comments?per-page=${perPage}&page=${page}`,
            commentCreate: (threadId) => `/threads/${threadId}/comments`,
            commentUpdate: ({ threadId, commentId }) =>
                `/threads/${threadId}/comments/${commentId}`,
            commentDelete: ({ threadId, commentId }) =>
                `/threads/${threadId}/comments/${commentId}`,
            commentAnswers: ({ page, perPage, threadId, commentId }) =>
                `/threads/${threadId}/comments/${commentId}/answers?per-page=${perPage}&page=${page}`,
            commentReport: ({ threadId, commentId }) =>
                `/threads/${threadId}/comments/${commentId}/report`,
            threadCategories: `/threads/categories`,
            threadCategoriesFavorite: (profileId) =>
                `/threads/categories/profile/${profileId}/favorite`,
            threads: ({ perPage, page, categoryId, search }) =>
                `/threads/categories/${categoryId}?page=${page}&per-page=${perPage}${
                    search ? `&szukaj=${search}` : ''
                }`,
            threadsFavorite: ({ categoryId, profileId, page, perPage }) =>
                `/threads/categories/${categoryId}/profile/${profileId}/favorites?page=${page}&per-page=${perPage}`,
            threadsPinned: (categoryId) => `/threads/categories/${categoryId}/pinned`,
            thread: (threadId) => `/threads/${threadId}`,
            threadToggleFavorite: ({ threadId, profileId }) =>
                `/threads/${threadId}/profile/${profileId}/favorite`,
        },
        transition: {
            dailyReports: (profileId, from, to) =>
                `/transformations/profile/${profileId}/days?from=${from}&to=${to}`,
            dailyReport: (profileId, date) => `/transformations/profile/${profileId}/day/${date}`,
            weeklyReports: (profileId) =>
                `/transformations/profile/${profileId}/weeks?expand=dailyReports,questions`,
            weeklyReportsSubmit: (profileId) => `/transformations/profile/${profileId}/weeks/sent`,
            weeklyReportCreate: (profileId) => `/transformations/profile/${profileId}/weeks`,
            weeklyReport: (profileId, weekId) =>
                `/transformations/profile/${profileId}/weeks/${weekId}`,
            reportsHistory: (profileId) => `/transformations/profile/${profileId}/weeks/history`,
            scores: (profileId) => `/transformations/profile/${profileId}/scores`,
            measurements: (profileId) => `/measurements/profile/${profileId}`,
            measurementsUpdate: (profileId, measurementId) =>
                `/measurements/${measurementId}/profile/${profileId}`,
            measurementDefault: (profileId) => `/measurements/profile/${profileId}/defaults`,
            measurementCount: (profileId) => `/measurements/profile/${profileId}/count`,
        },
        checkEmail: `${API_URL}/my-account/exist`,
        infoBars: `/info-bars`,
    },
    blogListingItemsCount: 15,
    modelStatusMap: {
        active: {
            label: 'Aktywny',
            status: 1,
            key: 'active',
        },
        inactive: {
            label: 'Nieaktywny',
            status: 0,
            key: 'inactive',
        },
    },
    audit: {
        module: {
            course: 'course',
            exercise: 'exercise',
            onBoarding: 'onBoarding',
            personalTraining: 'personalTraining',
            training: 'training',
        },
        group: {
            additional: 'additional',
            category: 'category',
            exercise: 'exercise',
            intro: 'additional',
            movie: 'movie',
        },
        action: {
            watch: 'watch',
        },
    },
    fileTypesMap: {
        images: [
            'image/apng',
            'image/bmp',
            'image/gif',
            'image/jpeg',
            'image/pjpeg',
            'image/png',
            'image/svg+xml',
            'image/tiff',
            'image/webp',
            'image/x-icon',
        ],
    },
    formsStatusMap: {
        idle: 'idle',
        loading: 'loading',
        success: 'success',
        fail: 'fail',
    },
    apiStatusMap: {
        idle: 'idle',
        loading: 'loading',
        success: 'success',
        fail: 'fail',
    },
    userStatusMap: {
        loggedIn: 'loggedIn',
        loggedOut: 'loggedOut',
        pending: 'pending',
    },
    shop: {
        order: {
            statusMap: {
                0: {
                    key: 'new',
                    label: 'Nowe',
                },
                100: {
                    key: 'complete',
                    label: 'Zrealizowane',
                },
                250: {
                    key: 'canceled',
                    label: 'Anulowane',
                },
                255: {
                    key: 'delated',
                    label: 'Usunięte',
                },
            },
            paymentStatusMap: {
                0: {
                    key: 'new',
                    label: 'Nowa',
                },
                5: {
                    key: 'processing',
                    label: 'Zlecona',
                },
                10: {
                    key: 'pending',
                    label: 'W trakcie',
                },
                100: {
                    key: 'paid',
                    label: 'Opłacona',
                },
                200: {
                    key: 'failed',
                    label: 'Nieudana',
                },
                201: {
                    key: 'partial_paid',
                    label: 'Niedopłata',
                },
                202: {
                    key: 'overpaid',
                    label: 'Nadpłata',
                },
                250: {
                    key: 'refunded',
                    label: 'Zwrócono',
                },
                255: {
                    key: 'canceled',
                    label: 'Anulowana',
                },
            },
        },
    },
    messages: {
        form: {
            required: 'To pole jest wymagane',
            email: 'Niepoprawny format email',
            requiredConsent: 'Zgoda jest wymagana',
            textCopied: 'Tekst został skopiowany do schowka',
        },
        password: {
            min: 'Hasło musi składać się przynajmniej z 8 znaków',
            repeat: 'Hasło nie są takie same',
        },
        networkError: 'Wystąpił problem z siecią, sprawdź połączenie lub spróbuj później',
        error: 'Wystąpił błąd, prosimy spróbować później',
        noResults: 'Brak wyników',
        profile: {
            updateSuccessTitle: 'Udało się!',
            updateSuccessContent: 'Profil został zmieniony.',
            updateFailTitle: 'Coś poszło nie tak...',
            updateFailContent: 'Nie udało się zaktualizować profilu, spróbuj później.',
            updateOnboardingVideoTitle: 'Zapamiętaliśmy Twój wybór.',
            updateOnboardingVideoContent:
                'Od teraz, po zalogowaniu od razu trafisz do “Strefy użytkownika”',
        },
        forbidden: {
            premium: `Usługa, którą wybrałeś jest dostępna tylko dla subskrybentów Strefy Przemian. Zainwestuj w\u00A0siebie i\u00A0dołącz do\u00A0Strefy Przemian już dziś!`,
            standard: `Usługa, którą wybrałeś jest dostępna tylko dla subskrybentów Strefy Przemian. Zainwestuj w\u00A0siebie i\u00A0dołącz do\u00A0Strefy Przemian już dziś!`,
            trainingAtlas: `Usługa, którą wybrałeś jest dostępna tylko dla subskrybentów Strefy Przemian i\u00A0subskrybentów Atlasu Treningów. Zainwestuj w\u00A0siebie i\u00A0dołącz do\u00A0Strefy Przemian już dziś!`,
        },
        gamification: {
            modals: {
                content: {
                    joinAndUnlock: 'Dołącz do Strefy i odblokuj wszystkie korzyści!',
                    joinAndGetPoints: 'Dołącz do Strefy i otrzymuj punkty również za tę czynność!',
                },
            },
        },
    },
    captcha: {
        clientKey: RECAPTCHA,
    },
    settings: {
        externalLinks: {
            facebook: 'facebook',
            instagram: 'instagram',
            twitter: 'twitter',
        },
    },
    mediaRelationsMap: {
        listCover: 1,
        mainImage: 1,
        secondaryImage: 2,
        mobileImage: 3,
        listing: 4,
        galleryItem: 5,
        ogImage: 6,
        alternativeMainImage: 101,
        alternativeGalleryItem: 105,
    },
    recipeTypesMap: {
        public: 'public',
        your: 'your',
        favorite: 'favorite',
    },
    mealPlanner: {
        coordinates: {
            cols: {
                type: {
                    key: 'type',
                    label: '',
                    dimension: 'calc(var(--box-padding-vertical, 150px) * 10)',
                },
            },
            rows: {
                dayLabel: {
                    key: 'dayLabel',
                    label: '',
                    dimension: 'auto',
                },
            },
        },
        mealTypesMap: {
            breakfast: {
                mealTypeId: 1,
                key: 'breakfast',
                label: 'Śniadanie',
                isDefault: true,
            },
            secondBreakfast: {
                mealTypeId: 2,
                key: 'secondBreakfast',
                label: 'Drugie śniadanie',
                isDefault: false,
            },
            lunch: {
                mealTypeId: 3,
                key: 'lunch',
                label: 'Obiad',
                isDefault: true,
            },
            snack: {
                mealTypeId: 4,
                key: 'snack',
                label: 'Przekąska',
                isDefault: false,
            },
            snap: {
                mealTypeId: 5,
                key: 'snap',
                label: 'Podwieczorek',
                isDefault: false,
            },
            dinner: {
                mealTypeId: 6,
                key: 'dinner',
                label: 'Obiadokolacja',
                isDefault: false,
            },
            supper: {
                mealTypeId: 7,
                key: 'supper',
                label: 'Kolacja',
                isDefault: true,
            },
        },
    },
    payU: {
        sdk: PAYU_SDK_SRC,
        posId: PAYU_POS_ID,
    },
    mealTypesMap: {
        breakfast: {
            mealTypeId: 1,
            key: 'breakfast',
            label: 'Śniadanie',
            dimension: 'auto',
        },
        secondBreakfast: {
            mealTypeId: 2,
            key: 'secondBreakfast',
            label: 'Drugie śniadanie',
            dimension: '0',
        },
        lunch: {
            mealTypeId: 3,
            key: 'lunch',
            label: 'Obiad',
            dimension: 'auto',
        },
        snack: {
            mealTypeId: 4,
            key: 'snack',
            label: 'Przekąska',
            dimension: '0',
        },
        snap: {
            mealTypeId: 5,
            key: 'snap',
            label: 'Podwieczorek',
            dimension: '0',
        },
        dinner: {
            mealTypeId: 6,
            key: 'dinner',
            label: 'Obiadokolacja',
            dimension: '0',
        },
        supper: {
            mealTypeId: 7,
            key: 'supper',
            label: 'Kolacja',
            dimension: 'auto',
        },
    },
    paymentModelsMap: {
        singlePayment: {
            id: 1,
        },
        installments: {
            id: 2,
        },
        renewableInstallments: {
            id: 3,
        },
    },
    consentsMap: {
        source: {
            contactForm: 'contactForm',
            newsletterBanner: 'newsletterBanner',
            newsletterAccount: 'newsletterAccount',
            registerForm: 'registerForm',
            order: 'order',
        },
        key: {
            regulations: 'regulations',
            regulationsOrder: 'regulationsOrder',
            rodo: 'rodo',
            rodoOrder: 'rodoOrder',
            newsletter: 'newsletter',
            newsletterShort: 'newsletterShort',
            rodoHealthData: 'rodoHealthData',
            recurringPaymentSubscription: 'recurringPaymentSubscription',
            recurringPaymentTrainingAtlas: 'recurringPaymentTrainingAtlas',
            warrantyOne: 'warrantyOne',
            warrantyTwo: 'warrantyTwo',
            withdraw: 'withdraw',
        },
    },
    profileTypesMap: {
        single: 1,
        couple: 2,
    },
    subscriptionMap: {
        attributes: {
            installmentCount: {
                key: 'installmentCount',
                id: 1,
                values: {
                    12: {
                        value: 1,
                    },
                },
            },
            accountLevel: {
                key: 'accountLevel',
                id: 2,
                levels: {
                    standard: {
                        value: 5,
                    },
                    premium: {
                        value: 6,
                    },
                },
            },
            accountType: {
                key: 'accountType',
                id: 3,
                values: {
                    single: {
                        value: 7,
                        key: 'single',
                    },
                    pair: {
                        value: 8,
                        key: 'pair',
                    },
                },
            },
        },
    },
    googleSiteVerification: GOOGLE_SITE_VERIFICATION,
    personalTraining: {
        typesMap: {
            exercises: {
                key: 'exercises',
                id: 0,
                name: 'Trening',
                emptyInfo: `<p>Nie masz na dziś żadnego treningu.</p>
                    <p>Na górze playera znajdziesz sekcję</p>
                    <p><strong>"Dodatkowe"</strong></p>
                    <p>tam czekają na Ciebie kolejne filmy.</p>`,
            },
            additionals: {
                key: 'additionals',
                id: 1,
                name: 'Dodatkowe',
                emptyInfo: 'Nie masz na dziś żadnych materiałów dodatkoych',
            },
        },
        defaultDescription: `poniżej 
                        znajduje się Twój spersonalizowany plan działania w Strefie 
                        Przemian! Znajdziesz przygotowany dla Ciebie plan 
                        treningowy. Koniecznie sprawdź też dział dodatki, są 
                        tam bardzo ważne informacje dotyczące optymalizacji 
                        treningów oraz pracy nad mentalnością. Podchodzimy do 
                        Twojej metamorfozy holistycznie, dlatego możemy naprawdę 
                        wiele osiągnąć! My dajemy z siebie 100%, pamiętaj, że 
                        im więcej Ty z siebie dasz, tym lepsze rezultaty będziesz 
                        mieć!`,
    },
    htmlCodes: {
        401: {
            seo: '401: Brak autoryzacji',
            message: 'Wystąpił błąd podczas autoryzacji',
        },
        403: {
            seo: '403: Odmowa dostępu',
            message: 'Brak uprawnień do wyświetlenia tej strony',
        },
        404: {
            seo: '404: Nie znaleziono strony',
            message: 'Nie znaleziono takiej strony',
        },
        500: {
            seo: '500: Błąd serwera',
            message: 'Wystąpił błąd podczas przetwarzania tego żądania',
        },
    },
    videos: {
        intro: {
            src: 'https://vimeo.com/530272285',
        },
    },
    alerts: {
        types: {
            success: 'success',
            warning: 'warning',
            error: 'error',
        },
    },
    addressTypes: {
        delivery: {
            id: 0,
            key: 'delivery',
        },
        invoice: {
            id: 1,
            key: 'invoice',
        },
    },
    counters: {
        notifications: 'notifications',
        cart: 'cart',
        messages: 'messages',
    },
};
