import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage, Form, Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
    container,
    contentColumn,
    header,
    dot,
    name,
    content,
    text,
    bottom,
    date,
    answerButton,
    answer,
    dropdownButton,
    admin,
    avatar,
    form,
    fields,
    inputBox,
    input,
    fileInput,
    fileInputLabel,
    nameBox,
    yours,
    formActions,
    formButton,
    images,
    imageBox,
    image,
    imageButton,
    deleteImageButton,
    deleted,
} from './comment.module.scss';
import { config } from '../../config';
import EditIcon from '../../assets/images/svg/edit.svg';
import DeleteIcon from '../../assets/images/svg/delete.svg';
import ReportIcon from '../../assets/images/svg/flag.svg';
import PaperclipIcon from '../../assets/images/svg/paperclip.svg';
import CloseIcon from '../../assets/images/svg/icon-close.svg';
import { IComment, ICommentFormValues } from '../../models/comment.model';
import { IProfile } from '../../models/profile.model';
import { IFileUploadResult } from '../../models/file.model';
import { getCurrentProfile } from '../../redux/profile/profile.selectors';
import { getTextWithLinks } from '../../utills/get-text-with-links';
import { addModalAction } from '../../redux/actions/actions-modals';

import Avatar from '../atoms/avatar';
import Input from '../atoms/form-poc/input';
import Button from '../atoms/button';
import FileInputArea from '../atoms/form-poc/file-input-area';
import GalleryModal from '../organisms/custom-modals/gallery-modal';
import Markdown from '../hoc/markdown';
import TextError from '../atoms/form-poc/text-error';
import MoreButton from './more-button';

type TCommentMode = 'edit' | 'view' | 'create';

export interface ICommentProps {
    className?: string;
    comment: IComment | null;
    mode?: TCommentMode;
    isAnswer?: boolean;
    onCancel?(comment: ICommentProps['comment']): void;
    onAnswer?(comment: ICommentProps['comment']): void;
    onSubmit?(args: {
        values: ICommentFormValues;
        helpers: FormikHelpers<ICommentFormValues>;
        comment: ICommentProps['comment'];
    }): void;
    onConfirmDelete?(comment: ICommentProps['comment']): void;
    onConfirmReport?(comment: ICommentProps['comment']): void;
}

const { fileTypesMap } = config;
const MAX_IMAGE_SIZE = 20480000; // 20MB;
const MAX_IMAGE_COUNT = 6;
const MAX_CONTENT_LENGTH = 1000;

const Comment: React.FC<ICommentProps> = ({
    className = '',
    comment,
    mode = 'view',
    isAnswer,
    onCancel,
    onAnswer,
    onSubmit,
    onConfirmDelete,
    onConfirmReport,
}) => {
    const formikRef = useRef<FormikProps<ICommentFormValues>>(null);
    const [localMode, setLocalMode] = useState<TCommentMode>(mode);
    const [remainingImages, setRemainingImages] = useState(
        (comment && MAX_IMAGE_COUNT - comment.media.length) ?? MAX_IMAGE_COUNT
    );

    const dispatch = useDispatch();
    const profile = useSelector(getCurrentProfile);
    const isAnswerLocal = isAnswer || !!(comment && comment.parentCommentId);
    const isOwner =
        localMode !== 'create' && !!(comment && comment.author.profileId === profile.profileId);
    const isAdmin = !!(comment && comment.author.role === 'admin');
    const isDeleted = !!(
        comment &&
        (comment.status === 'deleted-by-user' || comment.status === 'deleted-by-admin')
    );
    const fullName = getFullName({ profile, comment });
    const avatarUrl = getAvatarUrl({ profile, comment });

    const handleAnswer = () => {
        if (typeof onAnswer === 'function') {
            onAnswer(comment);
        }
    };

    const handleEdit = () => setLocalMode('edit');

    const handleDelete = () => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: copy.confirmDeleteTitle,
                    content: copy.confirmDeleteText,
                    onConfirm: () => {
                        if (typeof onConfirmDelete === 'function') {
                            onConfirmDelete(comment);
                        }
                    },
                },
            })
        );
    };

    const handleReport = () => {
        dispatch(
            addModalAction({
                modalKey: 'CONFIRMATION_MODAL',
                modalProps: {
                    title: copy.confirmReportTitle,
                    content: copy.confirmReportText,
                    onConfirm: () => {
                        if (typeof onConfirmReport === 'function') {
                            onConfirmReport(comment);
                        }
                    },
                },
            })
        );
    };

    const handleCancel = useCallback(() => {
        if (comment) {
            setLocalMode('view');
        }
        if (typeof onCancel === 'function') {
            onCancel(comment);
        }
    }, [comment, onCancel]);

    const handleFileInputClick = (formik: FormikProps<ICommentFormValues>) => {
        return () => {
            formik.setFieldError('attachments', '');
            formik.setFieldTouched('attachments');
        };
    };

    const handleFileInputChange = (
        result: IFileUploadResult,
        formik: FormikProps<ICommentFormValues>
    ) => {
        const newValue = [...formik.values.attachments, ...result.uploadedFiles];
        formik.setFieldValue('attachments', newValue);
        const newRemainingImages =
            MAX_IMAGE_COUNT - newValue.length < 0 ? 0 : MAX_IMAGE_COUNT - newValue.length;
        setRemainingImages(newRemainingImages);
        if (result.uploadErrors.length > 0) {
            setTimeout(() => {
                formik.setFieldError('attachments', result.uploadErrors.join('. '));
            }, 0);
        }
    };

    const handleDeleteFile = (formik: FormikProps<ICommentFormValues>, indexToDelete: number) => {
        return () => {
            const newValue = formik.values.attachments.filter(
                (_, index) => index !== indexToDelete
            );
            const newRemainingImages =
                MAX_IMAGE_COUNT - newValue.length < 0 ? 0 : MAX_IMAGE_COUNT - newValue.length;
            setRemainingImages(newRemainingImages);
            formik.setFieldValue('attachments', newValue);
        };
    };

    const handleShowGalleryModal = (activeIndex: number) => {
        return () => {
            if (!comment) return;
            dispatch(
                addModalAction({
                    renderComponent: (modalId: number) => (
                        <GalleryModal
                            modalId={modalId}
                            images={comment.media}
                            activeIndex={activeIndex}
                        />
                    ),
                })
            );
        };
    };

    const handleSubmit: FormikConfig<ICommentFormValues>['onSubmit'] = (values, helpers) => {
        if (typeof onSubmit === 'function') {
            onSubmit({ values, helpers, comment });
        }
    };

    useEffect(() => {
        const formik = formikRef.current;
        if (!formik) return;
        if (formik.status === 'success') {
            handleCancel();
        }
    }, [handleCancel]);

    return (
        <div
            className={`${container} ${className} ${isAnswerLocal ? answer : ''} ${
                isAdmin ? admin : ''
            } ${isDeleted ? deleted : ''}`}
        >
            <Avatar
                className={avatar}
                avatarUri={avatarUrl}
                hasShadow={false}
                isDark={true}
                size={isAnswerLocal ? 'medium' : 'large'}
            />
            <div className={contentColumn}>
                <div className={header}>
                    <div className={nameBox}>
                        <p className={name}>{fullName}</p>
                        {isOwner && <span className={yours}>{copy.yours}</span>}
                    </div>
                    {localMode === 'view' && comment && !isDeleted && (
                        <MoreButton>
                            {isOwner ? (
                                <>
                                    <button className={dropdownButton} onClick={handleEdit}>
                                        <EditIcon /> {copy.edit}
                                    </button>
                                    <button className={dropdownButton} onClick={handleDelete}>
                                        <DeleteIcon /> {copy.delete}
                                    </button>
                                </>
                            ) : (
                                <button className={dropdownButton} onClick={handleReport}>
                                    <ReportIcon /> {copy.report}
                                </button>
                            )}
                        </MoreButton>
                    )}
                </div>
                {localMode === 'view' && comment && (
                    <div className={content}>
                        <Markdown className={text}>
                            {getCommentContent({ comment, isAdmin })}
                        </Markdown>
                        {!isDeleted && comment.media.length > 0 && (
                            <div className={images}>
                                {comment.media.map((mediaImage, index) => {
                                    return (
                                        <button
                                            className={imageButton}
                                            key={`image-${mediaImage.fileId || mediaImage.id}`}
                                            onClick={handleShowGalleryModal(index)}
                                        >
                                            <img
                                                className={image}
                                                src={mediaImage.url}
                                                alt={mediaImage.alt || ''}
                                            />
                                        </button>
                                    );
                                })}
                            </div>
                        )}
                        <div className={bottom}>
                            <p className={date}>
                                {comment.displayCreatedAtDate}
                                <span className={dot}>•</span>
                                {comment.displayCreatedAtTime}
                            </p>
                            {!isDeleted && (
                                <button onClick={handleAnswer} className={answerButton}>
                                    {copy.answer}
                                </button>
                            )}
                        </div>
                    </div>
                )}
                {(localMode === 'edit' || localMode === 'create') && (
                    <Formik
                        innerRef={formikRef}
                        initialValues={getValues({ mode: localMode, comment })}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formik) => (
                            <Form className={form}>
                                <div className={fields}>
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    <Input
                                        containerClass={inputBox}
                                        inputClassname={input}
                                        as="textarea"
                                        name="content"
                                        placeholder={copy.placeholder}
                                        rows={3}
                                        showError={false}
                                    />
                                    {formik.values.attachments.length > 0 && (
                                        <div className={images}>
                                            {formik.values.attachments.map((file, index) => {
                                                const src = file.content || file.url;
                                                return (
                                                    <div
                                                        key={`image-box-${index}`}
                                                        className={imageBox}
                                                    >
                                                        <img
                                                            className={image}
                                                            src={src}
                                                            alt={file.name}
                                                        />
                                                        <button
                                                            type="button"
                                                            className={deleteImageButton}
                                                            onClick={handleDeleteFile(
                                                                formik,
                                                                index
                                                            )}
                                                        >
                                                            <CloseIcon />
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    <FileInputArea
                                        className={fileInput}
                                        name="attachments"
                                        multiple={true}
                                        fileTypes={fileTypesMap.images}
                                        form={formik}
                                        onChange={handleFileInputChange}
                                        onClick={handleFileInputClick(formik)}
                                        maxFileSize={MAX_IMAGE_SIZE}
                                        maxFileCount={remainingImages}
                                    >
                                        <span className={fileInputLabel}>
                                            {copy.attach}
                                            <PaperclipIcon />
                                        </span>
                                    </FileInputArea>
                                </div>
                                <ErrorMessage name="content" component={TextError} />
                                <ErrorMessage name="attachments" component={TextError} />
                                {formik.errors.global && (
                                    <TextError>{formik.errors.global}</TextError>
                                )}
                                {formik.errors.parentCommentId && (
                                    <TextError>{formik.errors.parentCommentId}</TextError>
                                )}
                                <div className={formActions}>
                                    <Button
                                        className={formButton}
                                        type="submit"
                                        color="yellow"
                                        size="small"
                                    >
                                        {copy.submit}
                                    </Button>
                                    <Button
                                        className={formButton}
                                        type="button"
                                        onClick={handleCancel}
                                        color="blank"
                                        size="small"
                                    >
                                        {copy.cancel}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    );
};

interface IGetValuesConfig {
    mode: TCommentMode;
    comment?: IComment | null;
}

function getValues({ mode, comment }: IGetValuesConfig): ICommentFormValues {
    if (mode === 'edit' && comment) {
        return {
            content: comment.content,
            attachments: comment.media.map((mediaItem) => {
                return {
                    fileId: mediaItem.id || mediaItem.fileId,
                    content: null,
                    url: mediaItem.url || '',
                    name: mediaItem.alt || '',
                    mimeType: mediaItem.type,
                };
            }),
        };
    }
    return {
        content: '',
        attachments: [],
    };
}

interface IGetCommentContentConfig {
    comment: IComment;
    isAdmin: boolean;
}

function getCommentContent({ comment, isAdmin }: IGetCommentContentConfig) {
    if (comment.status === 'visible' && isAdmin) return getTextWithLinks(comment.content);
    if (comment.status === 'deleted-by-user') return copy.deletedByUser;
    if (comment.status === 'deleted-by-admin') return copy.deletedByAdmin;
    return comment.content;
}

interface IGetFullNameConfig {
    profile: IProfile;
    comment?: IComment | null;
}

function getFullName({ profile, comment }: IGetFullNameConfig) {
    if (comment) return `${comment.author.firstName} ${comment.author.lastName}`;
    return `${profile.firstName} ${profile.lastName}`;
}

interface IGetAvatarUrlConfig {
    profile: IProfile;
    comment?: IComment | null;
}

function getAvatarUrl({ profile, comment }: IGetAvatarUrlConfig) {
    if (comment) return comment.author.avatarUrl;
    return profile.avatarUri;
}

const copy = {
    answer: 'Odpowiedz',
    attach: 'Dodaj zdjęcie',
    cancel: 'Anuluj',
    confirmDeleteTitle: 'Usunąć komentarz?',
    confirmDeleteText: 'Komentarz zostanie oznaczony jako usunięty.',
    confirmReportTitle: 'Zgłosić komentarz?',
    confirmReportText: 'Wskazany komentarz zostanie złoszony jako nieodpowiedni do administratora.',
    delete: 'Usuń',
    deletedByAdmin: 'Komentarz usunięty przez administratora.',
    deletedByUser: 'Komentarz usunięty przez użytkownika.',
    edit: 'Edytuj',
    error: {
        required: 'Uzupełnij treść komentarza',
        max: (max: number) => `Komentarz nie może być dłuższy niż ${max} znaków.`,
    },
    placeholder: 'Dodaj odpowiedź',
    report: 'Zgłoś',
    submit: 'Opublikuj',
    yours: 'Twój komentarz',
};

const validationSchema = Yup.object({
    content: Yup.string()
        .max(1000, copy.error.max(MAX_CONTENT_LENGTH))
        .required(copy.error.required),
});

export default Comment;
