// extracted by mini-css-extract-plugin
export var active = "dashboard-navigation-module--active--rAHUz";
export var bar = "dashboard-navigation-module--bar--amumZ";
export var barButton = "dashboard-navigation-module--bar-button--ZcDbU";
export var barButtonBox = "dashboard-navigation-module--bar-button-box--Bndcx";
export var barButtonChevron = "dashboard-navigation-module--bar-button-chevron--UJHND";
export var barButtonDots = "dashboard-navigation-module--bar-button-dots--gx+zF";
export var barDropdown = "dashboard-navigation-module--bar-dropdown--oNeKc";
export var barItem = "dashboard-navigation-module--bar-item--RKAfa";
export var barOpen = "dashboard-navigation-module--bar-open--BvNPC";
export var barVisibleBox = "dashboard-navigation-module--bar-visible-box--pIZnw";
export var container = "dashboard-navigation-module--container--Kx9sT";
export var link = "dashboard-navigation-module--link--OnCg3";
export var linkContent = "dashboard-navigation-module--link-content--iyX22";