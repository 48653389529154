import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';

import {
    container,
    button,
    buttonInner,
    icon,
    dropdown,
    selectedDay,
    monday,
    sunday,
    today,
    day,
} from './week-picker.module.scss';
import CalendarIcon from '../../assets/images/svg/callendar.svg';
import { getIsDateToday } from '../../utills/get-is-date-today';
import { getWeek } from '../../utills/get-week';
import { getIsDateInDateRange } from '../../utills/get-is-date-in-date-range';

import Dropdown from '../molecules/dropdown';
import Button from './button';

export interface IWeekPickerProps {
    className?: string;
    week?: [Date, Date];
    minDate?: Date;
    maxDate?: Date;
    onWeekSelect?(week: [Date, Date]): void;
}

const WeekPicker: React.FC<IWeekPickerProps> = ({
    className = '',
    onWeekSelect,
    week,
    minDate,
    maxDate,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedWeek, setSelectedWeek] = useState(week || getWeek(new Date()));

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const handleDateChange = (date: Date) => {
        const week = getWeek(date);
        setSelectedWeek(week);
        handleClose();

        if (onWeekSelect) {
            onWeekSelect(week);
        }
    };

    useEffect(() => {
        if (!week) return;
        if (week[0].getTime() === selectedWeek[0].getTime()) return;
        setSelectedWeek(week);
    }, [selectedWeek, week]);

    return (
        <div className={`${container} ${className}`}>
            <Button className={button} size="small" color="blank" onClick={handleOpen}>
                <span className={buttonInner}>
                    {copy.select} <CalendarIcon className={icon} />
                </span>
            </Button>
            <Dropdown open={isOpen} isTriangle={false} closeFunc={handleClose} className={dropdown}>
                <Calendar
                    value={selectedWeek[0]}
                    onClickDay={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    view="month"
                    tileClassName={(tile) => {
                        const isInSelectedWeek = getIsDateInDateRange(tile.date, selectedWeek);
                        const isMonday = tile.date.getDay() === 1;
                        const isSunday = tile.date.getDay() === 0;
                        const isToday = getIsDateToday(tile.date.getTime(), false);
                        return `${day} ${isInSelectedWeek ? selectedDay : ''} ${
                            isMonday ? monday : ''
                        } ${isSunday ? sunday : ''} ${isToday ? today : ''}`;
                    }}
                />
            </Dropdown>
        </div>
    );
};

const copy = {
    select: 'Wybierz tydzień',
};

export default WeekPicker;
