import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    modal,
    separator,
    list,
    buttonOwnMeal,
    filtersWrapper,
    filterBox,
} from './recipe-search-modal.module.scss';
import { config } from '../../../config';
import { removeModalAction } from '../../../redux/actions/actions-modals';
import { getCurrentProfileId } from '../../../redux/profile/profile.selectors';
import { ALL, RECIPES } from '../../../redux/recipes/recipes.reducer';
import useGenericList from '../../../hooks/use-generic-list';
import { clearList } from '../../../redux/generic-list/generic-list.actions';

import RecipeSearchList from '../../molecules/recipe/recipe-search-list';
import SeparatorWithText from '../../atoms/separator-with-text';
import Button from '../../atoms/button';
import InfiniteContainer from '../../hoc/infinite-continer';
import CustomModal from '../../../templates/custom-modal';
import Filters from '../filters/filters';
import InputSearchList from '../../molecules/input-search-list';

const { apiStatusMap, endpoints } = config;

const pageSize = 20;

const RecipeSearchModal = ({ modalId, onAddOwnMeal, onRecipeSelect, isTemplate }) => {
    const currentProfileId = useSelector(getCurrentProfileId);
    const dispatch = useDispatch();
    const {
        items,
        apiStatus,
        pagination,
        filters,
        search,
        sort,
        getNextPage,
        applyFilters,
        applySort,
        handleSearchChange,
    } = useGenericList({
        entity: RECIPES,
        subEntity: ALL,
        perPage: pageSize,
        url: `${endpoints.recipes.publicEndpoint}/profile/${currentProfileId}/available`,
    });

    const handleAddOwnMeal = () => {
        dispatch(removeModalAction(modalId));
        onAddOwnMeal();
    };

    useEffect(() => {
        getNextPage();
        return () => {
            dispatch(clearList(RECIPES, ALL));
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CustomModal modalId={modalId} className={modal} title="Dodaj posiłek">
            <div className={filtersWrapper}>
                <InputSearchList search={search} onChange={handleSearchChange} />
                <Filters
                    className={filterBox}
                    filters={filters}
                    onApplyFilters={applyFilters}
                    sort={sort}
                    onApplySort={applySort}
                />
            </div>

            <InfiniteContainer
                className={list}
                onScroll={getNextPage}
                loading={apiStatus === apiStatusMap.loading}
                disabled={pagination.currentPage >= pagination.pageCount}
            >
                <RecipeSearchList recipes={items} onRecipeSelect={onRecipeSelect} />
            </InfiniteContainer>

            {!isTemplate && (
                <>
                    <SeparatorWithText className={separator}>Lub</SeparatorWithText>
                    <Button
                        className={buttonOwnMeal}
                        color="yellow"
                        size="small"
                        onClick={handleAddOwnMeal}
                    >
                        Dodaj własny posiłek
                    </Button>
                </>
            )}
        </CustomModal>
    );
};

RecipeSearchModal.propTypes = {
    modalId: PropTypes.number.isRequired,
    onAddOwnMeal: PropTypes.func,
    onRecipeSelect: PropTypes.func,
    isTemplate: PropTypes.bool,
};

RecipeSearchModal.defaultProps = {
    isTemplate: false,
    onAddOwnMeal: () => {},
    onRecipeSelect: () => {},
};

export default RecipeSearchModal;
