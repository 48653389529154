import React from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';

import { loader } from './app.module.scss';
import useHasMounted from '../hooks/useHasMounted';

import { renderRoutes, PUBLIC_ROUTES } from '../routes';
import Loader from '../components/atoms/loader';
import PublicRoute from '../components/hoc/public-route';

const routesMap = renderRoutes(PUBLIC_ROUTES, PublicRoute);

const PublicApp = () => {
    const hasMounted = useHasMounted();
    const isUserVerificationFinished = useSelector(
        (state) => state.user.isUserVerificationFinished
    );

    if (!hasMounted || !isUserVerificationFinished) {
        return <Loader className={loader} />;
    } else {
        return <Router>{routesMap}</Router>;
    }
};

export default PublicApp;
