import React from 'react';
import Slider from 'react-slick';

import {
    container,
    slider,
    button,
    prev,
    next,
    sliderGrid,
    title,
} from './transition-team-slider.module.scss';
import ArrowIcon from '../../assets/images/svg/chevron-down.svg';
import { useTransitionTeamMembers } from '../../hooks/use-transition-team-members';
import useSlider from '../../hooks/use-slider';

import TransitionTeamMemberCard from '../molecules/transition-team-member-card';

interface TransitionTeamSliderProps {
    className?: string;
}

const TransitionTeamSlider: React.FC<TransitionTeamSliderProps> = ({ className = '' }) => {
    const teamMembers = useTransitionTeamMembers();
    const { sliderRef, sliderSettings, getPrev, getNext } = useSlider();

    if (!teamMembers.length) return null;

    return (
        <div className={`${container} ${className}`}>
            <h2 className={title}>{copy.title}</h2>
            <div className={sliderGrid}>
                <Slider
                    className={slider}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ref={sliderRef}
                    {...sliderSettings}
                    slidesToShow={3}
                    infinite={false}
                    arrows={false}
                    responsive={[
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                            },
                        },
                    ]}
                >
                    {teamMembers.map((member) => {
                        return (
                            <div key={`team-member-${member.profileId}`}>
                                <TransitionTeamMemberCard teamMember={member} />
                            </div>
                        );
                    })}
                </Slider>
                <button className={`${button} ${prev}`} onClick={getPrev}>
                    <ArrowIcon />
                </button>
                <button className={`${button} ${next}`} onClick={getNext}>
                    <ArrowIcon />
                </button>
            </div>
        </div>
    );
};

const copy = {
    title: 'Zespół odpowiedzialny za Twoje efekty',
};

export default TransitionTeamSlider;
