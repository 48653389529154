import React from 'react';
import PropTypes from 'prop-types';

import { wrapper, list, filterBox } from './enhanced-list.module.scss';
import { config } from '../../../config';
import useGenericList from '../../../hooks/use-generic-list';

import Filters from '../filters/filters';
import InfinityList from '../../molecules/list/infinity-list';
import InputSearchList from '../../molecules/input-search-list';

const { apiStatusMap } = config;

const EnhancedList = ({
    entity,
    subEntity,
    itemComponent,
    className,
    targetLocation,
    perPage,
    url,
    itemProps,
    ...rest
}) => {
    const {
        apiStatus,
        filters,
        sort,
        search,
        items,
        applyFilters,
        applySort,
        getNextPage,
        handleSearchChange,
    } = useGenericList({ entity, subEntity, url, perPage });

    return (
        <div className={`${wrapper} ${className}`} {...rest}>
            <InputSearchList search={search} onChange={handleSearchChange} />
            <Filters
                className={filterBox}
                filters={filters}
                sort={sort}
                onApplySort={applySort}
                onApplyFilters={applyFilters}
                loading={apiStatus === apiStatusMap.loading}
            />

            <InfinityList
                loading={apiStatus === apiStatusMap.loading}
                callbackFunction={getNextPage}
                className={list}
                items={itemProps ? enrichItems(items, itemProps) : items}
                ItemComponent={itemComponent}
                targetPath={targetLocation}
            />
        </div>
    );
};

export default EnhancedList;

EnhancedList.propTypes = {
    entity: PropTypes.string.isRequired,
    subEntity: PropTypes.string.isRequired,
    targetLocation: PropTypes.string.isRequired,
    itemComponent: PropTypes.elementType.isRequired,
    className: PropTypes.string,
    url: PropTypes.string,
    perPage: PropTypes.number,
    itemProps: PropTypes.object,
};

EnhancedList.defaultProps = {
    className: '',
    perPage: 12,
    url: '',
    itemProps: null,
};

const enrichItems = (items, itemProps) => {
    return items.map((item) => ({ ...item, ...itemProps }));
};
