import React from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

import { markdown, buttonLink, button } from './markdown.module.scss';

import InjectableSlider from '../organisms/injectable-slider';
import Button from '../atoms/button';

interface IMarkdownProps extends ReactMarkdownOptions {
    children: string;
    className?: string;
}

const Markdown: React.FC<IMarkdownProps> = ({ children, className = '', ...rest }) => {
    return (
        <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
                h1: 'h2',
                a: ({ href, children }) => {
                    return (
                        <a href={href} target="_blank" rel="noreferrer noopener">
                            {children}
                        </a>
                    );
                },
                p: ({ children, node }) => {
                    const text = children?.[0];
                    if (typeof text !== 'string') return <p>{children}</p>;

                    const sliderRegex = /\{\{slider=(\d+)\}\}/;
                    const sliderMatch = text.match(sliderRegex);
                    if (sliderMatch) {
                        const sliderId = Number(sliderMatch[1]);
                        if (isNaN(sliderId)) return null;
                        return <InjectableSlider sliderId={sliderId} />;
                    }

                    const buttonRegex = /\{\{button=([^|]+)\|([^}]+)\}\}/;
                    const buttonMatch = text.match(buttonRegex);
                    if (buttonMatch) {
                        const buttonText = buttonMatch[1];
                        const buttonHref = buttonMatch[2];
                        if (!buttonText || !buttonHref) return null;
                        return (
                            <Link to={buttonHref} className={buttonLink}>
                                <Button className={button} color="yellow">
                                    {buttonText}
                                </Button>
                            </Link>
                        );
                    }

                    return <p>{children}</p>;
                },
            }}
            className={`${className} ${markdown}`}
            {...rest}
        >
            {children}
        </ReactMarkdown>
    );
};

export default Markdown;
