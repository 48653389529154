import { selectLoaderByEntity } from '../ui/ui.selectors';
import { INFO_BARS } from './info-bars.actions';

export function selectInfoBars(state) {
    return state.infoBars;
}

export function selectInfoBarsStatus(state) {
    return selectLoaderByEntity(state, INFO_BARS);
}
