import React from 'react';

const GamificationBuySubscriptionModal = React.lazy(() =>
    import('./gamification/gamification-buy-subscription-modal')
);
const GamificationAlreadySentModal = React.lazy(() =>
    import('./gamification/gamification-already-sent-modal')
);
const SubscriptionUpgradeModal = React.lazy(() => import('./subscription-upgrade-modal'));
const PayUCardFormModal = React.lazy(() => import('./payu-card-from-modal'));
const PartnersZoneModal = React.lazy(() => import('./partners-zone/partners-zone-modal'));
const UpsellingProductsModal = React.lazy(() => import('./upselling-products-modal'));
const ConfirmationModal = React.lazy(() => import('./confirmation-modal'));
const DeleteReasonModal = React.lazy(() => import('./delete-reason-modal'));
const PlannerReportModal = React.lazy(() => import('./planner-report-modal'));
const DailyReportFormModal = React.lazy(() => import('./daily-report-form-modal'));
const WeeklyReportFormModal = React.lazy(() => import('./weekly-report-form-modal'));
const SendWeeklyReportModal = React.lazy(() => import('./send-weekly-report-modal'));
const MeasurementFormModal = React.lazy(() => import('./measurement-form-modal'));
const TransitionGalleryModal = React.lazy(() => import('./transition-gallery-modal'));
const TransitionTeamMemberModal = React.lazy(() => import('./transition-team-member-modal'));

type TModalKey =
    | 'GAMIFICATION_BUY_SUBSCRIPTION_MODAL'
    | 'GAMIFICATION_ALREADY_SENT_MODAL'
    | 'PARTNERS_ZONE_MODAL'
    | 'SUBSCRIPTION_UPGRADE_MODAL'
    | 'PAYU_CARD_FORM_MODAL'
    | 'UPSELLING_PRODUCTS_MODAL'
    | 'CONFIRMATION_MODAL'
    | 'DELETE_REASON_MODAL'
    | 'PLANNER_REPORT_MODAL'
    | 'DAILY_REPORT_FORM_MODAL'
    | 'WEEKLY_REPORT_FORM_MODAL'
    | 'SEND_WEEKLY_REPORT_MODAL'
    | 'MEASUREMENT_FORM_MODAL'
    | 'TRANSITION_GALLERY_MODAL'
    | 'TRANSITION_TEAM_MEMBER_MODAL';

const MODALS: Record<TModalKey, React.ElementType> = {
    GAMIFICATION_BUY_SUBSCRIPTION_MODAL: GamificationBuySubscriptionModal,
    GAMIFICATION_ALREADY_SENT_MODAL: GamificationAlreadySentModal,
    PARTNERS_ZONE_MODAL: PartnersZoneModal,
    SUBSCRIPTION_UPGRADE_MODAL: SubscriptionUpgradeModal,
    PAYU_CARD_FORM_MODAL: PayUCardFormModal,
    UPSELLING_PRODUCTS_MODAL: UpsellingProductsModal,
    CONFIRMATION_MODAL: ConfirmationModal,
    DELETE_REASON_MODAL: DeleteReasonModal,
    PLANNER_REPORT_MODAL: PlannerReportModal,
    DAILY_REPORT_FORM_MODAL: DailyReportFormModal,
    WEEKLY_REPORT_FORM_MODAL: WeeklyReportFormModal,
    SEND_WEEKLY_REPORT_MODAL: SendWeeklyReportModal,
    MEASUREMENT_FORM_MODAL: MeasurementFormModal,
    TRANSITION_GALLERY_MODAL: TransitionGalleryModal,
    TRANSITION_TEAM_MEMBER_MODAL: TransitionTeamMemberModal,
};

export default MODALS;
