import React from 'react';
import { Props } from 'react-select';

import { FilterCommonProps } from '../../../models/generic-list-filters.model';
import { IGenericListModifierSelect } from '../../../models/generic-list.model';

import Select from '../form-poc/select';

export interface IFilterSelectProps extends Partial<Omit<FilterCommonProps, 'group'>> {
    group: { key: string } & IGenericListModifierSelect;
}

export default function FilterSelect({ group, filterClick, clear, className }: IFilterSelectProps) {
    const emptyValue = transformEmptyValue(group.emptyValue || {});
    return (
        <Select
            options={[emptyValue, ...transformOptionsValues(group.values)]}
            defaultValue={emptyValue}
            value={getCurrentValue(group.values) || emptyValue}
            onChange={handleChange(filterClick, clear)}
            name={group.key}
            placeholder={group.label}
            className={className}
            isSearchable={false}
        />
    );
}

// add value to empty option (api returns object without value field)
const transformEmptyValue = ({ label }: IGenericListModifierSelect['emptyValue']) => ({
    label,
    value: 'empty',
});

// transform "enabled" field to "isDisabled"
const transformOptionsValues = (values: IGenericListModifierSelect['values']) =>
    Object.values(values).map((value) => ({ ...value, isDisabled: !value.enabled }));

// get current value by checking applied field
const getCurrentValue = (values: IGenericListModifierSelect['values']) => {
    const current = Object.values(values).find((value) => value.applied);

    if (!current) {
        return;
    }

    const { label, value } = current;

    return {
        label,
        value,
    };
};

// it creates "dummy" event because util function for filter preparing needs input event as one of function parameter...
const createDummyEvent = (name: string, value: string) => ({
    target: {
        name,
        value,
        type: 'select',
    },
});

// transform select onChange to generic list applying filters api
const handleChange: (
    onClick: IFilterSelectProps['filterClick'],
    clearFilters: IFilterSelectProps['clear']
) => Props<any>['onChange'] = (onClick, clearFilters) => (options, action) => {
    const event = createDummyEvent(action?.name || '', options?.value || '');

    if (options.value === 'empty') {
        clearFilters(event);
        return;
    }

    onClick(event);
};
