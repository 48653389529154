import { TRANSITION_REPORTS_HISTORY_PER_PAGE, types } from './transition.actions';

const {
    SET_TRANSITION_DAILY_REPORTS,
    SET_TRANSITION_WEEKLY_REPORTS,
    SET_TRANSITION_REPORTS_HISTORY,
    SET_TRANSITION_WEEKLY_REPORT,
    SET_TRANSITION_SCORES,
    SET_TRANSITION_MEASUREMENTS,
    SET_TRANSITION_MEASUREMENT_DEFAULT,
    SET_TRANSITION_GALLERY,
    SET_TRANSITION_DAILY_REPORT,
    SET_TRANSITION_MEASUREMENT_COUNT,
    RESTORE_TRANSITION_DAILY_REPORTS_INITIAL_STATE,
    RESTORE_TRANSITION_INITIAL_STATE,
    RESTORE_TRANSITION_WEEKLY_REPORTS_INITIAL_STATE,
    RESTORE_TRANSITION_REPORTS_HISTORY_INITIAL_STATE,
    RESTORE_TRANSITION_WEEKLY_REPORT_INITIAL_STATE,
    RESTORE_TRANSITION_SCORES_INITIAL_STATE,
    RESTORE_TRANSITION_MEASUREMENTS_INITIAL_STATE,
    RESTORE_TRANSITION_MEASUREMENT_DEFAULT_INITIAL_STATE,
    RESTORE_TRANSITION_GALLERY_INITIAL_STATE,
    RESTORE_TRANSITION_DAILY_REPORT_INITIAL_STATE,
    RESTORE_TRANSITION_MEASUREMENT_COUNT_INITIAL_STATE,
} = types;

export const initialState = {
    dailyReport: null,
    dailyReports: {
        from: null,
        to: null,
        items: [],
    },
    weeklyReports: [],
    weeklyReport: null,
    reportsHistory: {
        items: [],
        pagination: {
            perPage: TRANSITION_REPORTS_HISTORY_PER_PAGE,
            pageCount: 0,
            totalCount: 0,
            currentPage: 0,
        },
        filters: null,
    },
    scores: {
        items: [],
        filters: null,
    },
    measurementCount: {
        count: null,
        countWithPhotos: null,
    },
    measurements: {
        items: [],
        filters: null,
    },
    gallery: {
        items: [],
        filters: null,
    },
    defaultMeasurement: null,
};

export default function transitionReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_TRANSITION_DAILY_REPORTS:
            return {
                ...state,
                dailyReports: {
                    from: payload[0].date,
                    to: payload[payload.length - 1].date,
                    items: payload,
                },
            };
        case SET_TRANSITION_DAILY_REPORT:
            return {
                ...state,
                dailyReport: payload[0] || null,
            };
        case SET_TRANSITION_WEEKLY_REPORTS:
            return {
                ...state,
                weeklyReports: payload,
            };
        case SET_TRANSITION_REPORTS_HISTORY:
            return {
                ...state,
                reportsHistory: payload,
            };
        case SET_TRANSITION_WEEKLY_REPORT:
            return {
                ...state,
                weeklyReport: payload,
            };
        case SET_TRANSITION_SCORES:
            return {
                ...state,
                scores: {
                    items: payload.items,
                    filters: payload.filters,
                },
            };
        case SET_TRANSITION_MEASUREMENTS:
            return {
                ...state,
                measurements: {
                    items: payload.items,
                    filters: payload.filters,
                },
            };
        case SET_TRANSITION_MEASUREMENT_DEFAULT:
            return {
                ...state,
                defaultMeasurement: payload,
            };
        case SET_TRANSITION_GALLERY:
            return {
                ...state,
                gallery: {
                    items: payload.items,
                    filters: payload.filters,
                },
            };
        case SET_TRANSITION_MEASUREMENT_COUNT:
            return {
                ...state,
                measurementCount: payload,
            };
        case RESTORE_TRANSITION_DAILY_REPORTS_INITIAL_STATE:
            return {
                ...state,
                dailyReports: initialState.dailyReports,
            };
        case RESTORE_TRANSITION_DAILY_REPORT_INITIAL_STATE:
            return {
                ...state,
                dailyReport: initialState.dailyReport,
            };
        case RESTORE_TRANSITION_WEEKLY_REPORTS_INITIAL_STATE:
            return {
                ...state,
                weeklyReports: initialState.weeklyReports,
            };
        case RESTORE_TRANSITION_WEEKLY_REPORT_INITIAL_STATE:
            return {
                ...state,
                weeklyReport: initialState.weeklyReport,
            };
        case RESTORE_TRANSITION_REPORTS_HISTORY_INITIAL_STATE:
            return {
                ...state,
                reportsHistory: initialState.reportsHistory,
            };
        case RESTORE_TRANSITION_SCORES_INITIAL_STATE:
            return {
                ...state,
                scores: initialState.scores,
            };
        case RESTORE_TRANSITION_MEASUREMENTS_INITIAL_STATE:
            return {
                ...state,
                measurements: initialState.measurements,
            };
        case RESTORE_TRANSITION_MEASUREMENT_DEFAULT_INITIAL_STATE:
            return {
                ...state,
                defaultMeasurement: initialState.defaultMeasurement,
            };
        case RESTORE_TRANSITION_GALLERY_INITIAL_STATE:
            return {
                ...state,
                gallery: initialState.gallery,
            };
        case RESTORE_TRANSITION_MEASUREMENT_COUNT_INITIAL_STATE:
            return {
                ...state,
                measurementCount: initialState.measurementCount,
            };
        case RESTORE_TRANSITION_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
}
