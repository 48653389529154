// extracted by mini-css-extract-plugin
export var actionBtns = "meal-planer-module--action-btns--ELWW+";
export var actions = "meal-planer-module--actions--PPv0B";
export var dateSwitcher = "meal-planer-module--date-switcher--Scr+3";
export var holder = "meal-planer-module--holder--569yD";
export var loader = "meal-planer-module--loader--Vt6zd";
export var planer = "meal-planer-module--planer--QXjb5";
export var rangeSelector = "meal-planer-module--range-selector--V8K0w";
export var scrolled = "meal-planer-module--scrolled---GMns";
export var title = "meal-planer-module--title--ExySn";
export var weekFromParams = "meal-planer-module--week-from-params--DXmY2";
export var wrapper = "meal-planer-module--wrapper--hKktE";