module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"none","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"includeInDevelopment":true,"routeChangeEventName":"lolo-analytics","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Strefa Przemian","short_name":"Strefa Przemian","start_url":"/","background_color":"#FCD56F","theme_color":"#FCD56F","display":"standalone","icon":"/var/www/strefa-przemian/releases/256/frontend/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"e421eefbc15c34bd341a39745177f418"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://strefa-przemian.stage.alterpage.pl","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
