import React from 'react';

import {
    container,
    answersLabel,
    answersList,
    answersItem,
    complete,
    editButton,
    answersNumber,
    answersBox,
    textButton,
    sent,
} from './transition-weekly-answers.module.scss';
import { IWeeklyReport } from '../../models/transition.model';
import CheckIcon from '../../assets/images/svg/ok.svg';
import Button from '../atoms/button';

export interface ITransitionWeeklyAnswersProps {
    className?: string;
    report: IWeeklyReport;
    isTextButton?: boolean;
    onFillReport?(report: IWeeklyReport): void;
}

const TransitionWeeklyAnswers: React.FC<ITransitionWeeklyAnswersProps> = ({
    className = '',
    report,
    isTextButton,
    onFillReport,
}) => {
    const handleFill = () => {
        if (typeof onFillReport === 'function' && report.status !== 'sent') {
            onFillReport(report);
        }
    };

    return (
        <div
            className={`${container} ${className} ${isTextButton ? textButton : ''} ${
                report.status === 'sent' ? sent : ''
            }`}
        >
            <div className={answersBox}>
                <p className={answersLabel}>{copy.answers}</p>
                <div className={answersList}>
                    {report.questions.map((question, index) => {
                        const isComplete = !!question.answer;
                        return (
                            <button
                                key={`question-${question.questionId}`}
                                className={`${answersItem} ${isComplete ? complete : ''}`}
                                onClick={handleFill}
                            >
                                {isComplete ? (
                                    <CheckIcon />
                                ) : (
                                    <p className={answersNumber}>{index + 1}</p>
                                )}
                            </button>
                        );
                    })}
                </div>
            </div>
            {report.status !== 'sent' &&
                (isTextButton ? (
                    <button className={editButton} type="button" onClick={handleFill}>
                        {report.status === 'done' ? copy.edit : copy.fill}
                    </button>
                ) : (
                    <Button
                        type="button"
                        className={editButton}
                        size="small"
                        color="yellow"
                        onClick={handleFill}
                    >
                        {report.status === 'done' ? copy.edit : copy.fill}
                    </Button>
                ))}
        </div>
    );
};

const copy = {
    answers: 'Odpowiedzi',
    edit: 'Edytuj',
    fill: 'Uzupełnij',
};

export default TransitionWeeklyAnswers;
