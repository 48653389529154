import { config } from '../../config';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';
import { statusIdle } from '../ui/ui.actions';
import { INFO_BARS, restoreInfoBarsInitialState, setInfoBars, types } from './info-bars.actions';

const {
    GET_INFO_BARS,
    SEND_INFO_BARS_REQUEST,
    SEND_INFO_BARS_SUCCESS,
    SEND_INFO_BARS_FAIL,
    CLEAR_INFO_BARS,
} = types;

const infoBarsMiddleware = (state) => (next) => (action) => {
    const { dispatch } = state;
    const { type, payload } = action;

    next(action);

    switch (type) {
        case GET_INFO_BARS:
            dispatch({
                types: [SEND_INFO_BARS_REQUEST, SEND_INFO_BARS_SUCCESS, SEND_INFO_BARS_FAIL],
                payload: {
                    request: {
                        url: config.endpoints.infoBars,
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: INFO_BARS,
                },
            });
            break;

        case SEND_INFO_BARS_SUCCESS:
            const seenInfoBarsSessionStorage = sessionStorage.getItem('seenInfoBars') || '';
            const seenInfoBarsLocalStorage = localStorage.getItem('seenInfoBars') || '';
            const seenInfoBarIds = `${seenInfoBarsSessionStorage},${seenInfoBarsLocalStorage}`
                .split(',')
                .filter((id) => !!id)
                .map((id) => Number(id));
            const newInfoBars = payload.data.filter(
                (bar) => !seenInfoBarIds.includes(bar.infoBarId)
            );
            dispatch(setInfoBars(newInfoBars));
            break;

        case CLEAR_INFO_BARS:
            dispatch(statusIdle(INFO_BARS));
            dispatch(restoreInfoBarsInitialState());
            break;

        default:
            break;
    }
};

export default infoBarsMiddleware;
