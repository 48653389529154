import {
    types,
    MESSAGE_COUNT,
    setMessageCountAction,
    restoreMessageCountInitialItemsAction,
    clearMessageCountAction,
} from './message-count.actions';
import { config } from '../../config';
import { getCurrentProfileId } from '../profile/profile.selectors';
import { statusIdle } from '../ui/ui.actions';
import { types as userTypes } from '../user/user.actions';
import { getAuthorizationBearerHeader } from '../../utills/get-authorization-bearer-header';

const { CLEAR_USER_DATA } = userTypes;

const messageCountMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload } = action;
    const currentProfileId = getCurrentProfileId(getState());
    next(action);

    switch (type) {
        case types.GET_MESSAGE_COUNT:
            dispatch({
                types: [
                    types.FETCH_MESSAGE_COUNT_REQUEST,
                    types.FETCH_MESSAGE_COUNT_SUCCESS,
                    types.FETCH_MESSAGE_COUNT_FAIL,
                ],
                payload: {
                    request: {
                        url: config.endpoints.messages.notReadCount(currentProfileId),
                        headers: { ...getAuthorizationBearerHeader() },
                    },
                },
                meta: {
                    entity: MESSAGE_COUNT,
                },
            });
            break;

        case types.FETCH_MESSAGE_COUNT_SUCCESS:
            dispatch(setMessageCountAction(payload.data));
            break;

        case types.CLEAR_MESSAGE_COUNT:
            dispatch(statusIdle(MESSAGE_COUNT));
            dispatch(restoreMessageCountInitialItemsAction());
            break;

        case CLEAR_USER_DATA:
            dispatch(clearMessageCountAction());
            break;

        default:
            break;
    }
};

export default messageCountMiddleware;
