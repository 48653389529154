import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import {
    wrapper,
    subscriptionSp,
    title,
    pricing,
    metamorphosis,
    gallery,
    opinions,
    description as descriptionClass,
    shortDescription as shortDescriptionClass,
    nextArticles,
    products,
    productsList,
    productOptions,
    cantBuyMessage,
    seeMoreButton,
    subscriptionButton,
    reviews,
    faq,
    faqButton,
    pricingContentBox,
} from './product.module.scss';
import { mainGrid } from '../style/grid.module.scss';
import FaqEmotics from '../assets/images/svg/faq-emotics.svg';
import EmoticsRelatedProd from '../assets/images/svg/emotics-related-products.svg';
import { config } from '../config';
import { selectCartIsLoading } from '../redux/shop/cart/cart.selectors';
import { eventMap, trackEvent } from '../utills/FbAnalytics';
import getNodes from '../utills/get-nodes';
import getDiscountInfo from '../utills/get-discount-info';
import useMediaWithSeo from '../hooks/use-media-with-seo';
import useProductDiscounts from '../hooks/use-product-discounts';
import useProductVariants from '../hooks/use-product-variants';
import useSubscriptionSp from '../hooks/use-subscription-sp';
import useCustomAddToCart from '../hooks/use-custom-add-to-cart';
import { addModalAction } from '../redux/actions/actions-modals';
import { getUrlParamValue } from '../utills/get-url-param-value';
import { getAddToCartRedirectUrl } from '../utills/get-add-to-cart-redirect-url';

import SEO from '../components/seo';
import Main from '../layouts/main';
import MetamorphosisSlider from '../components/organisms/metamorphosis-slider';
import VideoOpinions from '../components/organisms/video-opinions';
import CartButton from '../components/atoms/cart-button';
import LinkButton from '../components/atoms/link-button';
import Gallery from '../components/organisms/gallery';
import PostPreview from '../components/molecules/post-preview';
import RelatedList from '../components/organisms/related-list';
import List from '../components/molecules/list/list';
import ProductCard from '../components/molecules/product-card/product-card';
import Price from '../components/atoms/price';
import DiscountBenefitList from '../components/molecules/discount-benefit-list';
import DiscountConditionList from '../components/molecules/discount-condition-list';
import ProductAttributeList from '../components/organisms/product-attribute-list';
import PointsDiscountLabel from '../components/atoms/points-discount-label';
import Pricing from '../components/organisms/pricing';
import Button from '../components/atoms/button';
import Faq from '../components/organisms/faq';
import ProductDescription from '../components/organisms/product-description';

const { mediaRelationsMap } = config;
const galleryRelations = [mediaRelationsMap.mainImage, mediaRelationsMap.galleryItem];

const Product = ({ data }) => {
    const {
        allMetamorphosis,
        allPost,
        allProduct,
        product: {
            description,
            description1,
            description2,
            description3,
            description4,
            description5,
            description6,
            shortDescription,
            media,
            productId,
            name,
            price,
            attributesDefinitions,
            variants: allVariants,
            slug,
            kind,
            canBuy,
            meta,
            remoteShopUrl,
            pricingContent1,
            pricingContent2,
            pricingContent3,
            pricingContent4,
            pricingContent5,
            pricingContent6,
        },
        site: { siteMetadata },
    } = data;

    const [version, setVersion] = useState(0);
    const versionUrlParam = getUrlParamValue('version');
    const descriptions = [
        description,
        description1,
        description2,
        description3,
        description4,
        description5,
        description6,
    ];
    const pricingContents = [
        pricingContent1,
        pricingContent1,
        pricingContent2,
        pricingContent3,
        pricingContent4,
        pricingContent5,
        pricingContent6,
    ];
    const localDescription = descriptions[version];
    const localPricingContent = pricingContents[version];

    const dispatch = useDispatch();
    const pricingRef = useRef(null);
    const cartLoading = useSelector(selectCartIsLoading);
    const { url: mainImageUrl, ogImageUrl } = useMediaWithSeo(media);
    const { requestDiscount, ...discount } = useProductDiscounts({ productId });
    const { isSubscriptionSp, renderAttributeListItemProps, variants } = useSubscriptionSp({
        kind,
        variants: allVariants,
    });
    const {
        variant,
        attributesValues,
        allowedAttributesValues,
        handleAttributeChange,
    } = useProductVariants({ variants, attributesDefinitions });

    const { addToCart } = useCustomAddToCart(productId);

    const relatedPosts = getNodes(allPost) || [];
    const relatedProducts = getNodes(allProduct) || [];
    const metamorphosisSlides = getNodes(allMetamorphosis);

    const handleAddToCart = () => {
        if (!canBuy) {
            dispatch(
                addModalAction({
                    title: 'Produkt chwilowo niedostępny w sprzedaży',
                })
            );
            return;
        }
        const redirectUrl = getAddToCartRedirectUrl();
        addToCart({ productId, variantId: variant?.variantId }, { redirectUrl });
    };

    const handleScrollToPricing = () => {
        if (!pricingRef.current) return;
        pricingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    useEffect(() => {
        const analyticsParams = {
            content_ids: [`${productId}`],
            currency: 'PLN',
            content_type: 'product',
            value: price?.gross || 0,
        };
        trackEvent(eventMap.viewContent, analyticsParams);
    }, [productId, price]);

    useEffect(() => {
        requestDiscount();
    }, [requestDiscount]);

    useEffect(() => {
        const versionInt = isNaN(versionUrlParam) ? 0 : Number(versionUrlParam);
        setVersion(versionInt);
    }, [versionUrlParam]);

    return (
        <>
            <SEO
                title={`${name} | Sklep`}
                description={shortDescription}
                image={ogImageUrl}
                replaceMeta={meta}
                structuredData={{
                    '@type': 'Product',
                    name: name,
                    image: mainImageUrl,
                    description: shortDescription,
                    sku: productId,
                    offers: {
                        '@type': 'Offer',
                        price: price?.gross || 0,
                        priceCurrency: price?.currency || '',
                        url: `${siteMetadata.url}/sklep/${slug}/`,
                    },
                }}
            />
            <Main
                isPaddingEqualToHeaderOnPhone={true}
                className={`${wrapper} ${mainGrid} ${isSubscriptionSp ? subscriptionSp : ''}`}
            >
                {!isSubscriptionSp && (
                    <>
                        <div className={gallery}>
                            {!discount?.isLoading && !variant && (
                                <DiscountBenefitList benefits={discount?.benefitLabels} />
                            )}
                            <Gallery media={media} relations={galleryRelations} />
                            <PointsDiscountLabel labelPointsBenefit={discount.labelPointsBenefit} />
                        </div>
                        <section className={productOptions}>
                            <div className={title}>{name}</div>
                            {shortDescription && (
                                <div className={shortDescriptionClass}>{shortDescription}</div>
                            )}
                            {!isSubscriptionSp && (
                                <>
                                    <Price
                                        {...getPriceProps({ price, discount, variant })}
                                        isLoading={discount.isLoading}
                                    />
                                    {!discount.isLoading && (
                                        <DiscountConditionList
                                            conditions={discount.conditionLabels}
                                        />
                                    )}
                                    <ProductAttributeList
                                        attributes={attributesDefinitions}
                                        activeAttributes={attributesValues}
                                        allowedAttributesValues={allowedAttributesValues}
                                        show={variants?.length > 0}
                                        onAttributeChange={handleAttributeChange}
                                        itemProps={renderAttributeListItemProps}
                                    />
                                    {remoteShopUrl ? (
                                        <LinkButton
                                            className={seeMoreButton}
                                            to={remoteShopUrl}
                                            external={true}
                                            color="yellow"
                                        >
                                            Zobacz więcej
                                        </LinkButton>
                                    ) : (
                                        <CartButton
                                            transitions={false}
                                            onClick={handleAddToCart}
                                            disabled={cartLoading}
                                            disabledStyle={!canBuy}
                                            className={'addToCart'}
                                        />
                                    )}
                                </>
                            )}
                            {isSubscriptionSp && (
                                <>
                                    <Button
                                        color="yellow"
                                        className={subscriptionButton}
                                        onClick={handleScrollToPricing}
                                    >
                                        Poznaj ofertę
                                    </Button>
                                    <div className={reviews}>
                                        <img src="/images/app-store-review.png?v=20231229" alt="" />
                                        <img src="/images/facebook-review.png?v=20231229" alt="" />
                                    </div>
                                </>
                            )}
                            {!canBuy && (
                                <p className={cantBuyMessage}>
                                    Produkt chwilowo niedostępny w sprzedaży
                                </p>
                            )}
                        </section>
                    </>
                )}
                {isSubscriptionSp && (
                    <>
                        <section className={pricingContentBox}>
                            <ProductDescription description={localPricingContent} />
                        </section>
                        <section className={pricing} ref={pricingRef}>
                            <Pricing hideTitle={true} />
                        </section>
                        <section className={faq}>
                            <Faq />
                            <Button
                                color="yellow"
                                className={faqButton}
                                onClick={handleScrollToPricing}
                            >
                                Zacznij teraz ze zniżką!
                            </Button>
                        </section>
                    </>
                )}
                {localDescription && (
                    <section className={descriptionClass}>
                        <ProductDescription description={localDescription} />
                    </section>
                )}
                {metamorphosisSlides.length > 0 && (
                    <div className={metamorphosis}>
                        <MetamorphosisSlider slides={metamorphosisSlides} />
                    </div>
                )}
                <VideoOpinions
                    className={opinions}
                    title={'Sprawdź, co mówią o nas nasi podopieczni'}
                    bigTitle={true}
                />
                {relatedProducts.length > 0 && (
                    <RelatedList
                        className={products}
                        emotics={EmoticsRelatedProd}
                        title="Powiązane produkty"
                        subtitle="Tutaj znajdują się produkty, które mogą pasować do rzeczy, którą właśnie oglądasz. Stwórz swój zestaw!"
                    >
                        <List
                            listClassName={productsList}
                            itemProps={{ cartLoading }}
                            items={relatedProducts}
                            ItemComponent={ProductCard}
                            path="/sklep"
                        />
                    </RelatedList>
                )}
                {relatedPosts.length > 0 && (
                    <RelatedList
                        className={nextArticles}
                        emotics={FaqEmotics}
                        title="Powiązane artykuły"
                        subtitle="Tu znajdziesz artykuły w których opisujemy bądź używamy rzeczy, która Cię zainteresowała!"
                    >
                        {relatedPosts.map((post) => (
                            <PostPreview key={post.id} post={post} />
                        ))}
                    </RelatedList>
                )}
            </Main>
        </>
    );
};

function getVariantPrice(variant) {
    const { isDiscounted } = getDiscountInfo({
        price: variant.price,
        basePrice: variant.basePrice,
        promotions: [],
    });

    return {
        price: variant.price,
        basePrice: variant.basePrice,
        defaultPrice: variant.basePrice,
        isDiscounted,
    };
}

const getPriceProps = ({ price, discount, variant }) => {
    if (variant) {
        return getVariantPrice(variant);
    }

    return {
        price: discount.price,
        basePrice: discount.basePrice,
        defaultPrice: price,
        isDiscounted: discount.isDiscounted,
    };
};

export const query = graphql`
    query($id: String!, $relatedPostIds: [Int], $relatedProductIds: [Int]) {
        product(id: { eq: $id }) {
            ...productFields
        }
        allMetamorphosis(sort: { fields: createdAt, order: DESC }, limit: 5) {
            edges {
                node {
                    author
                    content
                    imageUrl
                }
            }
        }
        allPost(filter: { articleId: { in: $relatedPostIds } }) {
            edges {
                node {
                    ...relatedPostsFields
                }
            }
        }
        allProduct(filter: { productId: { in: $relatedProductIds } }) {
            edges {
                node {
                    ...productCardFields
                }
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default Product;
