import React from 'react';

import {
    container,
    navButton,
    prev,
    next,
    arrow,
    preview,
} from './transition-week-switcher.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow-right-short.svg';
import { IDailyReport } from '../../models/transition.model';

import TransitionDayButton, { TTransitionDayButtonMode } from '../atoms/transition-day-button';

interface ITransitionWeekSwitcherProps {
    className?: string;
    mode?: TTransitionDayButtonMode;
    dailyReports: IDailyReport[];
    onPrev?(): void;
    onNext?(): void;
    onDaySelect?(dailyReport: IDailyReport): void;
    isPrevDisabled?: boolean;
    isNextDisabled?: boolean;
}

const TransitionWeekSwitcher: React.FC<ITransitionWeekSwitcherProps> = ({
    className = '',
    dailyReports,
    onPrev,
    onNext,
    isPrevDisabled,
    isNextDisabled,
    onDaySelect,
    mode = 'normal',
}) => {
    const modeClass = modeClasses[mode];

    return (
        <div className={`${container} ${className} ${modeClass}`}>
            <button className={`${navButton} ${prev}`} onClick={onPrev} disabled={isPrevDisabled}>
                <ArrowIcon className={arrow} />
            </button>
            {dailyReports.map((report) => {
                return (
                    <TransitionDayButton
                        mode={mode}
                        key={`day-${report.date}`}
                        report={report}
                        onDaySelect={onDaySelect}
                    />
                );
            })}
            <button className={`${navButton} ${next}`} onClick={onNext} disabled={isNextDisabled}>
                <ArrowIcon className={arrow} />
            </button>
        </div>
    );
};

const modeClasses: Record<TTransitionDayButtonMode, string> = {
    preview: preview,
    normal: '',
};

export default TransitionWeekSwitcher;
