import { types } from './message-count.actions';

const { SET_MESSAGE_COUNT, RESTORE_MESSAGE_COUNT_INITIAL_STATE } = types;

const initialState = {
    count: 0,
    latestMessage: null,
};

export default function messageCountReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_MESSAGE_COUNT:
            return {
                ...payload,
            };
        case RESTORE_MESSAGE_COUNT_INITIAL_STATE:
            return initialState;
        default:
            return state;
    }
}
