import { types as apiTypes } from '../api/api.actions';

export const MEAL_PLANER = '[MealPlaner]';
export const MEAL_PLANER_ACTION = '[PlanerAction]';
export const MEAL_PLANER_POS = '[PlanerPosition]';
export const MEAL_PLANER_MEAL = '[PlanerMeal]';
export const MEAL_PLANER_POS_NOTE = '[PlanerPositionNote]';
export const MEAL_PLANER_SHOPPING_LIST = '[PlanerShoppingList]';
export const MEAL_PLANER_REPORT = '[PlanerReport]';

export const types = {
    // user triggered actions eg. button click
    ADD_ITEM: `${MEAL_PLANER} add item`, // show adding modal
    DEL_ITEM: `${MEAL_PLANER} del item`, // send delete request
    UPDATE_ITEM: `${MEAL_PLANER} update item`, // send update request eg. reorder items on drag&drop

    SEARCH_RECIPES: `${MEAL_PLANER} search recipes`, // send update request eg. reorder items on drag&drop
    ADD_MEAL: `${MEAL_PLANER} add meal`, // send update request eg. reorder items on drag&drop

    ADD_MEAL_TYPE: `${MEAL_PLANER} add_meal type`, // show modal with meal types
    DEL_MEAL_TYPE: `${MEAL_PLANER} del_meal type`, // send request to del of all positions with this type

    GENERATE_PLANER_PDF: `${MEAL_PLANER} generate pdf`, // sens request for pdf
    GENERATE_PLANER_SHOPPING_LIST: `${MEAL_PLANER} generate shopping list`, // show shopping list modal
    GENERATE_PLANNER_REPORT: `${MEAL_PLANER} generate report`, // show planner report modal

    CHOOSE_PLAN: `${MEAL_PLANER} choose plan`, // show plans modal
    SHOW_ITEM_PREVIEW: `${MEAL_PLANER} show item preview`, // show item preview modal

    GET_NEXT_WEEK: `${MEAL_PLANER} get next week`, // trigger next week fetch
    GET_PREV_WEEK: `${MEAL_PLANER} get prev week`, // trigger previous week fetch

    ADD_POSITION_NOTE: `${MEAL_PLANER_POS_NOTE} add note`,
    DEL_POSITION_NOTE: `${MEAL_PLANER_POS_NOTE} del note`,

    COPY_PLAN_TO_NEXT_WEEK: `${MEAL_PLANER} copy plan to next week`,

    // flow/commands actions
    GET_PLANER_DATA: `${MEAL_PLANER} get data`, // trigger get data with dates
    FETCH_PLANER_DATA: `${MEAL_PLANER} fetch data`, // trigger fetch
    SEND_PLANER_ACTION: `${MEAL_PLANER} send action`, // trigger action send
    SEND_CLEAR_POSITIONS: `${MEAL_PLANER} clear positions`, // trigger action send

    SEND_DEL_MEAL_TYPE: `${MEAL_PLANER} del meal type`,
    SEND_ADD_RECIPE: `${MEAL_PLANER} add recipe`,
    SEND_DEL_POSITION: `${MEAL_PLANER} del position`,

    FETCH_POSITION: `${MEAL_PLANER_POS} fetch position`,
    FETCH_MEAL: `${MEAL_PLANER_MEAL} fetch meal`,

    SEND_POSITION_NOTE_ACTION: `${MEAL_PLANER_POS_NOTE} note action`,
    SEND_SHOPPING_LIST_ACTION: `${MEAL_PLANER_SHOPPING_LIST} action`,
    SEND_REPORT_ACTION: `${MEAL_PLANER_REPORT} action`,

    // api actions
    SEND_PLANER_REQUEST: `${MEAL_PLANER} ${apiTypes.API_REQUEST}`,
    SEND_PLANER_SUCCESS: `${MEAL_PLANER} ${apiTypes.API_SUCCESS}`,
    SEND_PLANER_FAIL: `${MEAL_PLANER} ${apiTypes.API_FAIL}`,

    FETCH_PLANNER_PDF_REQUEST: `${MEAL_PLANER} ${apiTypes.API_REQUEST} pdf`,
    FETCH_PLANNER_PDF_SUCCESS: `${MEAL_PLANER} ${apiTypes.API_SUCCESS} pdf`,
    FETCH_PLANNER_PDF_FAIL: `${MEAL_PLANER} ${apiTypes.API_FAIL} pdf`,

    SEND_CLEAR_POSITIONS_REQUEST: `${MEAL_PLANER_ACTION} ${apiTypes.API_REQUEST} clear`,
    SEND_CLEAR_POSITIONS_SUCCESS: `${MEAL_PLANER_ACTION} ${apiTypes.API_SUCCESS} clear`,
    SEND_CLEAR_POSITIONS_FAIL: `${MEAL_PLANER_ACTION} ${apiTypes.API_FAIL} clear`,

    SEND_PLANER_ACTION_REQUEST: `${MEAL_PLANER_ACTION} ${apiTypes.API_REQUEST}`,
    SEND_PLANER_ACTION_SUCCESS: `${MEAL_PLANER_ACTION} ${apiTypes.API_SUCCESS}`,
    SEND_PLANER_ACTION_FAIL: `${MEAL_PLANER_ACTION} ${apiTypes.API_FAIL}`,

    SEND_PLANER_POSITION_REQUEST: `${MEAL_PLANER_POS} ${apiTypes.API_REQUEST}`,
    SEND_PLANER_POSITION_SUCCESS: `${MEAL_PLANER_POS} ${apiTypes.API_SUCCESS}`,
    SEND_PLANER_POSITION_FAIL: `${MEAL_PLANER_POS} ${apiTypes.API_FAIL}`,

    SEND_MEAL_REQUEST: `${MEAL_PLANER_MEAL} ${apiTypes.API_REQUEST}`,
    SEND_MEAL_SUCCESS: `${MEAL_PLANER_MEAL} ${apiTypes.API_SUCCESS}`,
    SEND_MEAL_FAIL: `${MEAL_PLANER_MEAL} ${apiTypes.API_FAIL}`,

    SEND_SHOPPING_LIST_REQUEST: `${MEAL_PLANER_SHOPPING_LIST} ${apiTypes.API_REQUEST}`,
    SEND_SHOPPING_LIST_SUCCESS: `${MEAL_PLANER_SHOPPING_LIST} ${apiTypes.API_SUCCESS}`,
    SEND_SHOPPING_LIST_FAIL: `${MEAL_PLANER_SHOPPING_LIST} ${apiTypes.API_FAIL}`,

    SEND_POSITION_NOTE_REQUEST: `${MEAL_PLANER_POS_NOTE} ${apiTypes.API_REQUEST}`,
    SEND_POSITION_NOTE_SUCCESS: `${MEAL_PLANER_POS_NOTE} ${apiTypes.API_SUCCESS}`,
    SEND_POSITION_NOTE_FAIL: `${MEAL_PLANER_POS_NOTE} ${apiTypes.API_FAIL}`,

    SEND_REPORT_REQUEST: `${MEAL_PLANER_REPORT} ${apiTypes.API_REQUEST}`,
    SEND_REPORT_SUCCESS: `${MEAL_PLANER_REPORT} ${apiTypes.API_SUCCESS}`,
    SEND_REPORT_FAIL: `${MEAL_PLANER_REPORT} ${apiTypes.API_FAIL}`,

    SEND_COPY_PLAN_TO_NEXT_WEEK_REQUEST: `${MEAL_PLANER} ${apiTypes.API_REQUEST} copy`,
    SEND_COPY_PLAN_TO_NEXT_WEEK_SUCCESS: `${MEAL_PLANER} ${apiTypes.API_SUCCESS} copy`,
    SEND_COPY_PLAN_TO_NEXT_WEEK_FAIL: `${MEAL_PLANER} ${apiTypes.API_FAIL} copy`,

    // writing actions (reducer)
    SET_PLANER_DATA: `${MEAL_PLANER} set_data`,
    SET_POSITIONS: `${MEAL_PLANER} set positions`,
    CLEAR_PLANER_DATA: `${MEAL_PLANER} clear`,
};

// reducer actions (writing to state)
export const setPlanerData = (payload) => ({
    type: types.SET_PLANER_DATA,
    payload: payload,
});

export const setPositions = (payload) => ({
    type: types.SET_POSITIONS,
    payload: payload,
});

// middleware actions
export const fetchPlanerData = (payload, meta) => ({
    type: types.FETCH_PLANER_DATA,
    payload: payload,
    meta: meta,
});

export const fetchPosition = (payload, meta) => ({
    type: types.FETCH_POSITION,
    payload: payload,
    meta: meta,
});

export const sendPlanerAction = (payload, meta) => ({
    type: types.SEND_PLANER_ACTION,
    payload: payload,
    meta: meta,
});

export const getPlanerData = (payload) => ({
    type: types.GET_PLANER_DATA,
    payload: payload,
});

export const showItemPreview = (payload) => ({
    type: types.SHOW_ITEM_PREVIEW,
    payload: payload,
});

export const addItem = (payload) => ({
    type: types.ADD_ITEM,
    payload: payload,
});

export const sendAddItem = (payload, meta) => ({
    type: types.SEND_ADD_RECIPE,
    payload: payload,
    meta: meta,
});

export const searchRecipes = (payload) => ({
    type: types.SEARCH_RECIPES,
    payload: payload,
});

export const getPos = (payload, meta) => ({
    type: types.FETCH_POSITION,
    payload: payload,
    meta: meta,
});

export const delItem = (payload) => ({
    type: types.DEL_ITEM,
    payload: payload,
});

export const sendDelPosition = (payload) => ({
    type: types.SEND_DEL_POSITION,
    payload: payload,
});

export const updateItem = (payload, meta) => ({
    type: types.UPDATE_ITEM,
    payload: payload,
    meta: meta,
});

export const addMeal = (payload) => ({
    type: types.ADD_MEAL,
    payload: payload,
});

export const addMealType = () => ({
    type: types.ADD_MEAL_TYPE,
});

export const delMealType = (payload) => ({
    type: types.DEL_MEAL_TYPE,
    payload: payload,
});
export const sendDelMealType = (payload) => ({
    type: types.SEND_DEL_MEAL_TYPE,
    payload: payload,
});

export const generatePdf = () => ({
    type: types.GENERATE_PLANER_PDF,
});

export const generateReport = ({ redirectUrl, redirectState }) => ({
    type: types.GENERATE_PLANNER_REPORT,
    meta: { redirectUrl, redirectState },
});

export const sendReport = (payload, meta) => ({
    type: types.SEND_REPORT_ACTION,
    payload: payload,
    meta: meta,
});

export const generateShoppingList = () => ({
    type: types.GENERATE_PLANER_SHOPPING_LIST,
});

export const choosePlan = () => ({
    type: types.CHOOSE_PLAN,
});

export const copyPlanToNextWeek = (payload) => ({
    type: types.COPY_PLAN_TO_NEXT_WEEK,
    payload: payload,
});

export const getNextWeek = () => ({
    type: types.GET_NEXT_WEEK,
});

export const getPrevWeek = () => ({
    type: types.GET_PREV_WEEK,
});

export const addNote = (payload) => ({
    type: types.ADD_POSITION_NOTE,
    payload: payload,
});

export const delNote = (payload) => ({
    type: types.DEL_POSITION_NOTE,
    payload: payload,
});

export const sendNoteAction = (payload, meta) => ({
    type: types.SEND_POSITION_NOTE_ACTION,
    payload: payload,
    meta: meta,
});
