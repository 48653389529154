import React from 'react';
import { useDispatch } from 'react-redux';

import {
    container,
    noImage,
    imageBox,
    image,
    button,
    iconBox,
    date,
    count,
    dataBox,
    dataRow,
    dataLabel,
    dataValue,
} from './transition-gallery-card.module.scss';
import BodyIcon from '../../assets/images/svg/icon-body.svg';
import { ITransitionMeasurement } from '../../models/transition.model';
import { addModalAction } from '../../redux/actions/actions-modals';

import Button from '../atoms/button';
import ProtectedImage from '../hoc/protected-image';

interface ITransitionGalleryCardProps {
    className?: string;
    measurement: ITransitionMeasurement;
}

const TransitionGalleryCard: React.FC<ITransitionGalleryCardProps> = ({
    className = '',
    measurement,
}) => {
    const dispatch = useDispatch();
    const imgSrc = measurement.photos[0]?.uri || measurement.photos[0]?.url;

    const handleShowMore = () => {
        dispatch(
            addModalAction({
                modalKey: 'TRANSITION_GALLERY_MODAL',
                modalProps: { measurement },
            })
        );
    };

    const handleAddPhotos = () => {
        dispatch(
            addModalAction({
                modalKey: 'MEASUREMENT_FORM_MODAL',
                modalProps: { measurement },
            })
        );
    };

    return (
        <div className={`${container} ${className} ${imgSrc ? '' : noImage}`}>
            <div className={imageBox}>
                {imgSrc && <ProtectedImage className={image} src={imgSrc} alt="" />}
                {!imgSrc && (
                    <div className={iconBox}>
                        <BodyIcon />
                    </div>
                )}
                <Button
                    size="small"
                    className={button}
                    onClick={imgSrc ? handleShowMore : handleAddPhotos}
                >
                    {imgSrc ? copy.more : copy.add}
                </Button>
                <div className={dataBox}>
                    <div className={dataRow}>
                        <p className={dataLabel}>{copy.weight}</p>
                        <p className={dataValue}>{measurement.weight || '-'} kg</p>
                    </div>
                    <div className={dataRow}>
                        <p className={dataLabel}>{copy.waist}</p>
                        <p className={dataValue}>{measurement.waist || '-'} cm</p>
                    </div>
                    <div className={dataRow}>
                        <p className={dataLabel}>{copy.thigh}</p>
                        <p className={dataValue}>{measurement.thigh || '-'} cm</p>
                    </div>
                </div>
            </div>
            <div>
                <p className={date}>{measurement.displayDate}</p>
                <p className={count}>
                    {measurement.photos.length > 0
                        ? `${copy.count} ${measurement.photos.length}`
                        : copy.empty}
                </p>
            </div>
        </div>
    );
};

const copy = {
    count: 'Liczba zdjęć:',
    empty: 'Brak zdjęć',
    weight: 'Waga',
    waist: 'Obwód pas',
    thigh: 'Obwód udo',
    more: 'Zobacz więcej',
    add: 'Dadaj zdjęcia',
};

export default TransitionGalleryCard;
