import React, { useEffect, useMemo } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    back,
    backIcon,
    header,
    headerTop,
    statusBox,
    sent,
    statusDot,
    name,
    box,
    checkbox,
    checkboxLabel,
    content,
    boxes,
    loader,
    errorText,
} from './transition-weekly-report-details.module.scss';
import ArrowLeftIcon from '../../../assets/images/svg/icon-arrow-left.svg';
import { TStatus } from '../../../models/status.model';
import { IWeeklyReport } from '../../../models/transition.model';
import { getAbsolutePath } from '../../../routes';
import {
    clearTransitionWeeklyReport,
    getTransitionWeeklyReport,
} from '../../../redux/transition/transition.actions';
import {
    selectTransitionWeeklyReport,
    selectTransitionWeeklyReportStatus,
} from '../../../redux/transition/transition.selectors';

import WeeklyReportQuestionAnswers from '../../molecules/weekly-report-question-answers';
import Checkbox from '../../atoms/form-poc/checkbox';
import TransitionWeekSwitcher from '../../molecules/transition-week-switcher';
import DailyReportCard from '../../molecules/daily-report-card';
import Loader from '../../atoms/loader';

interface ITransitionWeeklyReportDetailsProps {
    id: number;
}

const TransitionWeeklyReportDetails: React.FC<ITransitionWeeklyReportDetailsProps> = ({ id }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const report: IWeeklyReport = useSelector(selectTransitionWeeklyReport);
    const status: TStatus | undefined = useSelector(selectTransitionWeeklyReportStatus);
    const historyPathname = useMemo(() => getAbsolutePath('APP_MY_TRANSITION_HISTORY'), []);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const prevPathname = location.state?.prevPathname || historyPathname;

    useEffect(() => {
        dispatch(getTransitionWeeklyReport(id));
    }, [dispatch, id]);

    useEffect(() => {
        return () => {
            dispatch(clearTransitionWeeklyReport());
        };
    }, [dispatch]);

    if (!status || status === 'idle' || status === 'loading') {
        return <Loader className={loader} />;
    }

    if (status === 'fail') {
        return (
            <div className={container}>
                <Link to={prevPathname} className={back}>
                    <ArrowLeftIcon className={backIcon} /> {copy.back}
                </Link>
                <p className={errorText}>{copy.error}</p>
            </div>
        );
    }

    return (
        <div className={container}>
            <div className={header}>
                <div className={headerTop}>
                    <Link to={prevPathname} className={back}>
                        <ArrowLeftIcon className={backIcon} /> {copy.back}
                    </Link>
                    {report && (
                        <div className={`${statusBox} ${report.status === 'sent' ? sent : ''}`}>
                            <span className={statusDot} />
                            <p>{report.status === 'sent' ? copy.sent : copy.notSent}</p>
                        </div>
                    )}
                </div>
                <h2 className={name}>
                    {copy.report} <strong>{report.displayName}</strong>
                </h2>
            </div>
            <div className={content}>
                <div className={boxes}>
                    <div className={box}>
                        <WeeklyReportQuestionAnswers questions={report.questions} />
                    </div>
                    <div className={box}>
                        <Formik
                            initialValues={{ withPlanner: report.withPlanner }}
                            onSubmit={() => {}}
                        >
                            <Checkbox
                                containerClass={checkbox}
                                labelClass={checkboxLabel}
                                name="withPlanner"
                                disabled={true}
                            >
                                {copy.planner}
                            </Checkbox>
                        </Formik>
                    </div>
                </div>
                <TransitionWeekSwitcher dailyReports={report.dailyReports} mode="preview" />
                {report.dailyReports.map((dailyReport) => {
                    return (
                        <DailyReportCard
                            key={`daily-report-${dailyReport.dayId}`}
                            dailyReport={dailyReport}
                            mode="preview"
                        />
                    );
                })}
            </div>
        </div>
    );
};

const copy = {
    report: 'Raport tygodniowy',
    back: 'Wróć',
    sent: 'Wysłany',
    notSent: 'Niewysłany',
    planner: 'Dołączono menu z planera posiłków',
    error: 'Nie udało się pobrać raportu. Spróbuj ponownie później.',
};

export default TransitionWeeklyReportDetails;
