import React from 'react';

import {
    container,
    done,
    today,
    plus,
    check,
    iconBox,
    dayText,
    dayNumber,
    preview,
    close,
} from './transition-day-button.module.scss';
import CheckIcon from '../../assets/images/svg/ok.svg';
import PlusIcon from '../../assets/images/svg/plus-no-circle.svg';
import CloseIcon from '../../assets/images/svg/icon-close.svg';
import { IDailyReport } from '../../models/transition.model';
import { getIsDateToday } from '../../utills/get-is-date-today';

export type TTransitionDayButtonMode = 'normal' | 'preview';

export interface ITransitionDayButtonProps {
    className?: string;
    report: IDailyReport;
    showNumber?: boolean;
    mode?: TTransitionDayButtonMode;
    onDaySelect?(dailyReport: IDailyReport): void;
}

const TransitionDayButton: React.FC<ITransitionDayButtonProps> = ({
    className = '',
    report,
    onDaySelect,
    mode = 'normal',
    showNumber = true,
}) => {
    const dateObj = new Date(report.date);
    const date = dateObj.getDate();
    const weekDay = dateObj.getDay();
    const dayName = DAY_NAMES[weekDay];
    const isToday = getIsDateToday(dateObj.getTime(), false);

    const modeClass = modeClasses[mode];

    const handleDaySelect = () => {
        if (typeof onDaySelect === 'function') {
            onDaySelect(report);
        }
    };

    return (
        <button
            className={`${container} ${className} ${report.status === 'done' ? done : ''} ${
                isToday ? today : ''
            } ${modeClass}`}
            onClick={handleDaySelect}
            type="button"
        >
            <p className={dayText}>{dayName}</p>
            <div className={iconBox}>
                {report.status === 'done' && <CheckIcon className={check} />}
                {report.status !== 'done' && mode === 'normal' && <PlusIcon className={plus} />}
                {report.status !== 'done' && mode === 'preview' && <CloseIcon className={close} />}
            </div>
            {showNumber && <p className={dayNumber}>{date}</p>}
        </button>
    );
};

const modeClasses: Record<TTransitionDayButtonMode, string> = {
    preview: preview,
    normal: '',
};

const DAY_NAMES = ['ND', 'PN', 'WT', 'ŚR', 'CZ', 'PT', 'SO'];

export default TransitionDayButton;
