import React from 'react';

import { header, navigation, title, dashboard, container } from './dashboard.module.scss';

import DashboardNavigation from '../../molecules/dashboard-navigation';

const Dashboard = ({ routeData, children, uri, location }) => {
    return (
        <div className={dashboard}>
            <div className={header}>
                <h2 className={title}>{routeData.label}</h2>
                <DashboardNavigation
                    className={navigation}
                    routeData={routeData}
                    location={location}
                    uri={uri}
                />
            </div>
            <div className={container}>{children}</div>
        </div>
    );
};

export default Dashboard;
