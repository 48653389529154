// extracted by mini-css-extract-plugin
export var answersBox = "transition-weekly-answers-module--answers-box--AP9yh";
export var answersItem = "transition-weekly-answers-module--answers-item--OtdU1";
export var answersLabel = "transition-weekly-answers-module--answers-label--Ljhok";
export var answersList = "transition-weekly-answers-module--answers-list--wGalF";
export var answersNumber = "transition-weekly-answers-module--answers-number--ANrpo";
export var complete = "transition-weekly-answers-module--complete--Ru73i";
export var container = "transition-weekly-answers-module--container--latMP";
export var editButton = "transition-weekly-answers-module--edit-button--dWGcG";
export var sent = "transition-weekly-answers-module--sent--C59i4";
export var textButton = "transition-weekly-answers-module--text-button--Reigz";