import React from 'react';

import {
    container,
    questionIndex,
    item,
    title,
} from './weekly-report-question-answers.module.scss';
import { IReportQuestion } from '../../models/transition.model';

interface IWeeklyReportQuestionAnswersProps {
    className?: string;
    questions: IReportQuestion[];
}

const WeeklyReportQuestionAnswers: React.FC<IWeeklyReportQuestionAnswersProps> = ({
    className = '',
    questions,
}) => {
    return (
        <ul className={`${container} ${className}`}>
            {questions.map((question, index) => {
                return (
                    <li key={`survey-question-${index + 1}`} className={item}>
                        <p className={title}>
                            <span className={questionIndex}>{index + 1}.</span>
                            <span>{question.content}</span>
                        </p>
                        <p>{question.answer || '-'}</p>
                    </li>
                );
            })}
        </ul>
    );
};

export default WeeklyReportQuestionAnswers;
