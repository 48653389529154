import React, { useEffect } from 'react';
import { Link, useLocation } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    item,
    itemHeader,
    itemStatus,
    itemStatusDot,
    itemButton,
    sent,
    loader,
    errorText,
    noItems,
    button,
    filtersBox,
    filter,
    filterList,
    filterListBox,
    loading,
} from './transition-history.module.scss';
import { IWeeklyReport } from '../../../models/transition.model';
import { TStatus } from '../../../models/status.model';
import { IResponsePagination } from '../../../models/pagination';
import { GenericListFilters } from '../../../models/generic-list.model';
import { getAbsolutePath } from '../../../routes';
import {
    clearTransitionReportsHistory,
    getTransitionReportsHistory,
} from '../../../redux/transition/transition.actions';
import {
    selectTransitionReportsHistory,
    selectTransitionReportsHistoryStatus,
} from '../../../redux/transition/transition.selectors';
import { getParamsFromFilters } from '../../../utills/filter-utils';

import Button from '../../atoms/button';
import Loader from '../../atoms/loader';
import Filters from '../filters/filters';
import Pagination from '../../molecules/pagination';

interface ITransitionHistoryProps {
    className?: string;
    children?: React.ReactNode;
}

interface IReportsHistoryState {
    items: IWeeklyReport[];
    pagination: IResponsePagination;
    filters: GenericListFilters | null;
}

const TransitionHistory: React.FC<ITransitionHistoryProps> = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history: IReportsHistoryState = useSelector(selectTransitionReportsHistory);
    const status: TStatus | undefined = useSelector(selectTransitionReportsHistoryStatus);

    const hasParams = Object.values(getParamsFromFilters(history.filters)).some((value) => value);

    const handleFilterChange = (filters: GenericListFilters) => {
        const params = getParamsFromFilters(filters);
        dispatch(getTransitionReportsHistory({ params, page: 1 }));
    };

    const handlePageChange = (page: number) => {
        const params = getParamsFromFilters(history.filters);
        dispatch(getTransitionReportsHistory({ params, page }));
    };

    const handleClear = () => {
        dispatch(getTransitionReportsHistory());
    };

    useEffect(() => {
        dispatch(getTransitionReportsHistory());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearTransitionReportsHistory());
        };
    }, [dispatch]);

    if (!status || (status === 'loading' && !history.items.length)) {
        return <Loader className={loader} />;
    }

    if (status === 'fail') {
        return <p className={errorText}>{copy.error}</p>;
    }

    return (
        <div className={`${container} ${status === 'loading' ? loading : ''}`}>
            {history.filters && (
                <div className={filtersBox}>
                    <Button
                        color={hasParams ? 'blank' : 'black'}
                        onClick={handleClear}
                        size="small"
                        className={button}
                    >
                        {copy.clear}
                    </Button>
                    <Filters
                        className={filterListBox}
                        filterClassName={filter}
                        filterListClassName={filterList}
                        showTitle={false}
                        filters={history.filters}
                        onApplyFilters={handleFilterChange}
                    />
                </div>
            )}
            {history.items.length === 0 && <p className={noItems}>{copy.noItems}</p>}
            {history.items.length > 0 && (
                <>
                    <ul>
                        {history.items.map((report) => {
                            const url = `${getAbsolutePath('APP_MY_TRANSITION_REPORT')}/${
                                report.weekId
                            }`;
                            return (
                                <li
                                    key={`report-${report.from}`}
                                    className={`${item} ${report.status === 'sent' ? sent : ''}`}
                                >
                                    <div className={itemHeader}>
                                        <p>{report.displayName}</p>
                                        <div className={itemStatus}>
                                            <span className={itemStatusDot} />
                                            <p>
                                                {report.status === 'sent'
                                                    ? copy.sent
                                                    : copy.notSent}
                                            </p>
                                        </div>
                                    </div>
                                    <Link to={url} state={{ prevPathname: location.pathname }}>
                                        <Button className={itemButton} size="small" color="blank">
                                            {copy.details}
                                        </Button>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                    <Pagination
                        page={history.pagination.currentPage}
                        pageCount={history.pagination.pageCount}
                        onChange={handlePageChange}
                    />
                </>
            )}
        </div>
    );
};

const copy = {
    sent: 'Wysłany',
    notSent: 'Niewysłany',
    details: 'Szczegóły',
    error: 'Wystąpił bład. Spróbuj ponownie później...',
    noItems: 'Nie masz jeszcze żadnych archiwalnych raportów.',
    clear: 'Pełny zakres',
};

export default TransitionHistory;
