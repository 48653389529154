export function getDateRangeName(range: [Date, Date]) {
    const startYear = range[0].getFullYear();
    const endYear = range[1].getFullYear();
    const startMonth = range[0].getMonth();
    const endMonth = range[1].getMonth();
    const startMonthName = MONTHS[startMonth];
    const endMonthName = MONTHS[endMonth];
    if (startYear !== endYear) {
        return `${startMonthName} ${startYear} / ${endMonthName} ${endYear}`;
    }
    if (startMonth !== endMonth) {
        return `${startMonthName} / ${endMonthName} ${endYear}`;
    }
    return `${endMonthName} ${endYear}`;
}

const MONTHS = [
    'styczeń',
    'luty',
    'marzec',
    'kwiecień',
    'maj',
    'czerwiec',
    'lipiec',
    'sierpień',
    'wrzesień',
    'październik',
    'listopad',
    'grudzień',
];
