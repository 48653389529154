import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { wrapper, absolute } from './counter-dot.module.scss';
import { selectCartItemsCount } from '../../redux/shop/cart/cart.selectors';
import { selectNotificationsNotReadCount } from '../../redux/notifications/notifications.selectors';
import { config } from '../../config';
import { getCurrentProfileUnreadMessagesCount } from '../../redux/profile/profile.selectors';

const { counters } = config;

const CounterDot = ({ className, type, isAbsolute }) => {
    const counters = useSelector((state) => ({
        cart: selectCartItemsCount(state),
        notifications: selectNotificationsNotReadCount(state).count,
        messages: getCurrentProfileUnreadMessagesCount(state),
    }));

    const [countToShow, setCountToShow] = useState(null);

    useEffect(() => {
        switch (true) {
            case counters[type] >= 100:
                setCountToShow('99+');
                break;
            case counters[type] > 0:
                setCountToShow(counters[type]);
                break;
            default:
                setCountToShow(null);
        }
    }, [counters, type]);

    if (!countToShow) {
        return null;
    }

    return (
        <div
            className={`
                ${wrapper} 
                ${className}
                ${isAbsolute ? absolute : ''}
            `}
        >
            {countToShow}
        </div>
    );
};

CounterDot.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.values(counters)).isRequired,
    isAbsolute: PropTypes.bool,
};

CounterDot.defaultProps = {
    className: '',
    isAbsolute: false,
};

export default CounterDot;
