import React from 'react';
import { navigate } from 'gatsby';

import {
    clearUserData,
    fetchDeleteAccountAction,
    fetchUserData,
    postActivateAccount,
    postChangePassword,
    postLoginUser,
    postLogoutUser,
    postRegisterUser,
    setUserData,
    setUserVerificationStatus,
    types,
    USER,
} from './user.actions';
import { types as melaPlanerTypes } from '../meal-planer/meal-planer.actions';
import { types as melaPlanerTemplatesTypes } from '../meal-planer-template/meal-planer-template.actions';
import { types as personalTrainingTypes } from '../personal-training/personal-training.actions';
import { types as orderTypes } from '../shop/order/order.actions';
import { initProfile, types as profileTypes } from '../profile/profile.actions';
import { CLEAR_SURVEY } from '../actions/actionTypes';
import { config } from '../../config';
import { formatFormikErrors } from '../../communication/utils';
import { clearCartData, sendCart, setCartData } from '../shop/cart/cart.actions';
import { clearPermissions, setPermissions } from '../rbac/rbac.actions';
import { clearAllAlerts, setAlert } from '../alerts/alerts.actions';
import { addModalAction, removeModalAction } from '../actions/actions-modals';
import { clearDietTypes } from '../diet-types/diet-types.actions';
import { clearAllRecipes } from '../recipes/recipes.actions';
import { clearShoppingLists } from '../shopping-lists/shopping-lists.actions';
import { restoreInitialState } from '../single-shopping-list/single-shopping-list.actions';
import { clearFilesToDownload } from '../files-to-download/files-to-download.actions';
import { clearList } from '../generic-list/generic-list.actions';
import { TRAININGS_ATLAS, LIST } from '../trainings-atlas/trainings-atlas.reducer';
import { EXERCISE_ATLAS } from '../exercise-atlas/exercise-atlas.reducer';
import { isSubscription } from '../shop/cart/cart.selectors';
import { clearOrderData } from '../shop/order/order.actions';
import { requestDiscountsAll } from '../shop/discounts/discounts.actions';
import { clearGamification } from '../gamification/gamification.actions';

import SubscriptionTypeInfo from '../../components/organisms/custom-modals/subscription-type-info-modal';
import SadEmotics from '../../assets/images/svg/sad-emotics.svg';

const accountTypeMap = config.subscriptionMap.attributes.accountType.values;
const { alerts, formsStatusMap } = config;

const userMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta, error } = action;

    const user = getState().user.userData;
    const prevCart = getState().shop.cart;
    let cartPayload = [],
        userCart = [],
        accountType = accountTypeMap.single.key;

    const formikBag =
        meta?.previousAction?.meta?.formikBag || payload?.formikBag || meta?.formikBag;
    const modalId = meta?.previousAction?.meta?.modalId || payload?.modalId || meta?.modalId;

    next(action);

    switch (type) {
        case 'persist/REHYDRATE':
            dispatch(fetchUserData());
            break;
        case types.LOGIN_USER:
            dispatch(postLoginUser(payload));
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.loading);
            }
            break;
        case types.LOGOUT_USER:
            dispatch(postLogoutUser());
            break;
        case types.REGISTER_USER:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch(postRegisterUser({ payload, formikBag }));
            break;
        case types.CHANGE_PASSWORD:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.loading);
            }

            dispatch(postChangePassword({ payload, formikBag }));
            break;

        case types.ACTIVATE_ACCOUNT:
            dispatch(clearAllAlerts());
            dispatch(postActivateAccount(payload, meta));
            break;

        case types.ACTIVATE_ACCOUNT_SUCCESS:
            dispatch(fetchUserData());
            dispatch(
                setAlert(
                    {
                        type: alerts.types.success,
                        content: 'Twoje konto zostało aktywowane.',
                    },
                    USER
                )
            );
            break;

        case types.ACTIVATE_ACCOUNT_FAIL:
            dispatch(
                setAlert(
                    {
                        type: alerts.types.error,
                        content:
                            'Nie udało się aktywować konta. Sprawdź poprawność linku i spróbuj ponownie.',
                    },
                    USER
                )
            );
            break;

        case types.REGISTER_USER_SUCCESS:
        case types.LOGIN_USER_SUCCESS:
        case types.LOGOUT_USER_SUCCESS:
        case orderTypes.SEND_ORDER_SUCCESS:
            if (payload.data.token) {
                localStorage.setItem('tokenData', JSON.stringify(payload.data.token));
            } else if (type !== orderTypes.SEND_ORDER_SUCCESS) {
                localStorage.removeItem('tokenData');
            }
            if (payload.data.returnUrl) {
                navigate(payload.data.returnUrl);
            } else {
                dispatch(fetchUserData());
                dispatch(requestDiscountsAll());
                if (formikBag) {
                    formikBag.setStatus(formsStatusMap.success);
                }
            }
            break;

        case types.CHANGE_PASSWORD_SUCCESS:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.success);
                formikBag.resetForm();
            }

            dispatch(
                addModalAction({
                    type: 'info',
                    content: 'Twoje hasło zostało pomyślnie zmienione.',
                })
            );
            break;

        case types.GET_USER_DATA_SUCCESS:
            const {
                userId,
                email,
                profiles,
                isNewsletterSubscriber,
                permissions,
                surveyProfiles,
                cart,
                canDeleteAccount,
            } = payload?.data;

            const userData = {
                userId,
                email,
                isNewsletterSubscriber,
                surveyProfiles,
                canDeleteAccount,
            };

            dispatch(setPermissions(permissions));
            dispatch(setUserData(userData));
            dispatch(initProfile(profiles));
            dispatch(setCartData(cart));

            if (user === null) {
                if (profiles.length > 1) {
                    accountType = accountTypeMap.pair.key;
                }
                if (cart) {
                    cart.items.forEach((item) => {
                        if (item?.subscriptionSp) {
                            userCart.push(item);
                        }
                    });
                }

                cartPayload = [];
                if (prevCart?.items) {
                    prevCart.items.forEach((item) => {
                        if (
                            (isSubscription(item) === accountType && userCart.length === 0) ||
                            isSubscription(item) === 'no-sub'
                        ) {
                            cartPayload.push({
                                productId: item.productId,
                                variantId: item.variantId,
                                quantity: item.quantity,
                                action: `add`,
                            });
                        } else if (userCart.length === 0) {
                            dispatch(
                                addModalAction({
                                    renderComponent: (modalId) => (
                                        <SubscriptionTypeInfo
                                            modalId={modalId}
                                            onConfirm={() => dispatch(removeModalAction(modalId))}
                                        />
                                    ),
                                    forceAction: true,
                                })
                            );
                        }
                    });
                }

                if (Array.isArray(cartPayload) && cartPayload.length) {
                    dispatch(
                        sendCart(
                            { items: cartPayload },
                            { ...meta, action: `add`, product: cartPayload }
                        )
                    );
                }
            }

            dispatch(setUserVerificationStatus(true));
            break;

        case types.LOGIN_USER_FAIL:
        case types.REGISTER_USER_FAIL:
        case types.CHANGE_PASSWORD_FAIL:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.fail);
                formikBag.setErrors(formatFormikErrors(error));
            }
            break;

        case types.GET_USER_DATA_FAIL:
            if (user !== null) {
                dispatch(clearCartData());
            }
            dispatch(clearGamification());
            dispatch(clearUserData());
            dispatch(setUserVerificationStatus(true));
            break;

        case types.LOGOUT_USER_FAIL:
            dispatch(
                setAlert(
                    {
                        type: alerts.types.error,
                        content: 'Nie udało się wylogować',
                    },
                    USER
                )
            );
            break;

        case types.CLEAR_USER_DATA:
            dispatch(clearPermissions());
            dispatch(clearList(EXERCISE_ATLAS, LIST));
            dispatch(clearList(TRAININGS_ATLAS, LIST));
            dispatch({ type: melaPlanerTypes.CLEAR_PLANER_DATA });
            dispatch({
                type: melaPlanerTemplatesTypes.CLEAR_PLANER_TEMPLATE_DATA,
            });
            dispatch({ type: personalTrainingTypes.CLEAR_TRAINING_DATA });
            dispatch({ type: profileTypes.CLEAR_ALL_PROFILE_DATA });
            dispatch({ type: CLEAR_SURVEY });
            dispatch(clearAllRecipes());
            dispatch(restoreInitialState());
            dispatch(clearDietTypes());
            dispatch(clearFilesToDownload());
            dispatch(clearShoppingLists());
            dispatch(clearOrderData());
            break;

        case types.DELETE_ACCOUNT:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.loading);
            }
            dispatch(fetchDeleteAccountAction({ formikValues: payload, formikBag, modalId }));
            break;

        case types.DELETE_ACCOUNT_SUCCESS:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.success);
            }
            if (modalId) {
                dispatch(removeModalAction(modalId));
            }
            dispatch(
                addModalAction({
                    title: 'Twoje konto zostało usunięte',
                    buttonText: 'OK',
                    onClose: () => window.location.reload(),
                    emotics: SadEmotics,
                })
            );
            break;

        case types.DELETE_ACCOUNT_FAIL:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.fail);
            }
            break;

        // no default
    }
};

export default userMiddleware;
