import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { container, bar } from './info-bars.module.scss';
import { IInfoBar } from '../../models/info-bar.model';
import useHasMounted from '../../hooks/useHasMounted';
import { selectIsUserVerificationFinished } from '../../redux/user/user.selectors';
import { getInfoBars, setInfoBars } from '../../redux/info-bars/info-bars.actions';
import { selectInfoBars } from '../../redux/info-bars/info-bars.selectors';

import InfoBar, { IInfoBarProps } from './info-bar';

interface IInfoBarsProps {
    className?: string;
    afterBarRender?(element: HTMLDivElement): void;
}

const InfoBars: React.FC<IInfoBarsProps> = ({ className = '', afterBarRender }) => {
    const dispatch = useDispatch();
    const hasMounted = useHasMounted();
    const isUserVerificationFinished: boolean = useSelector(selectIsUserVerificationFinished);
    const infoBars: IInfoBar[] = useSelector(selectInfoBars);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleClose: IInfoBarProps['onClose'] = (infoBar) => {
        dispatch(setInfoBars(infoBars.filter((bar) => bar.infoBarId !== infoBar.infoBarId)));
    };

    const handleAnimationEnd = () => {
        if (typeof afterBarRender === 'function' && containerRef.current) {
            afterBarRender(containerRef.current);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (typeof afterBarRender === 'function' && containerRef.current) {
                afterBarRender(containerRef.current);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [afterBarRender]);

    useEffect(() => {
        if (!isUserVerificationFinished) return;
        dispatch(getInfoBars());
    }, [dispatch, isUserVerificationFinished]);

    useEffect(() => {
        if (typeof afterBarRender === 'function') {
            setTimeout(() => {
                if (containerRef.current) {
                    afterBarRender(containerRef.current);
                }
            }, 0);
        }
    }, [afterBarRender, dispatch, infoBars]);

    if (!hasMounted || !isUserVerificationFinished) return null;

    return (
        <div ref={containerRef} className={`${container} ${className}`}>
            {infoBars.map((infoBar) => {
                return (
                    <InfoBar
                        className={bar}
                        key={`info-bar-${infoBar.infoBarId}`}
                        infoBar={infoBar}
                        onClose={handleClose}
                        onAnimationEnd={handleAnimationEnd}
                    />
                );
            })}
        </div>
    );
};

export default InfoBars;
