export const selectNotificationsNotSeenCount = (state) => state.notifications.notSeenCount;

export const selectNotifications = (state) => state.notifications;

export const selectNotificationsItems = (state) => state.notifications.items;

export const selectNotReadNotificationsIds = (state) => {
    return state.notifications.items
        .filter((notify) => !notify.readAt)
        .map((notify) => notify.messageId);
};

export const selectNotSeenNotificationsIds = (state) => {
    return state.notifications.items
        .filter((notify) => !notify.seenAt)
        .map((notify) => notify.messageId);
};

export const selectNotificationsIntervalId = (state) => {
    return state.notifications.intervalId;
};

export const selectNotificationsNotReadCount = (state) => state.notifications.notReadCount;
