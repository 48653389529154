import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    container,
    loader,
    errorText,
    loading,
    grid,
    noItems,
} from './transition-gallery.module.scss';
import { TStatus } from '../../../models/status.model';
import { ITransitionMeasurement } from '../../../models/transition.model';
import { GenericListFilters } from '../../../models/generic-list.model';
import {
    selectTransitionGallery,
    selectTransitionGalleryStatus,
    selectTransitionMeasurementCount,
    selectTransitionMeasurementCountStatus,
} from '../../../redux/transition/transition.selectors';
import {
    clearTransitionGallery,
    getTransitionGallery,
} from '../../../redux/transition/transition.actions';
import { getParamsFromFilters } from '../../../utills/filter-utils';
import { addModalAction } from '../../../redux/actions/actions-modals';

import Loader from '../../atoms/loader';
import TransitionChartHeader, {
    ITransitionChartHeaderProps,
} from '../../molecules/transition-chart-header';
import TransitionGalleryCard from '../../molecules/transition-gallery-card';

interface ITransitionGalleryProps {
    className?: string;
}

interface ITransitionGalleryState {
    filters: GenericListFilters;
    items: ITransitionMeasurement[];
}

const TransitionGallery: React.FC<ITransitionGalleryProps> = ({ className = '' }) => {
    const dispatch = useDispatch();
    const gallery: ITransitionGalleryState = useSelector(selectTransitionGallery);
    const status: TStatus | undefined = useSelector(selectTransitionGalleryStatus);
    const measurementCount = useSelector(selectTransitionMeasurementCount);
    const countStatus = useSelector(selectTransitionMeasurementCountStatus);

    const showLoader =
        !status ||
        status === 'idle' ||
        (status === 'loading' && !gallery.items.length) ||
        !countStatus ||
        countStatus === 'idle' ||
        countStatus === 'loading';

    const handleAddMeasurement = () => {
        dispatch(
            addModalAction({
                modalKey: 'MEASUREMENT_FORM_MODAL',
            })
        );
    };

    const handleFilterChange: ITransitionChartHeaderProps['onFiltersChange'] = (filters) => {
        const params = getParamsFromFilters(filters);
        dispatch(getTransitionGallery(params));
    };

    const handleClear = () => {
        dispatch(getTransitionGallery());
    };

    useEffect(() => {
        dispatch(getTransitionGallery({ filters: 'default' }));
        return () => {
            dispatch(clearTransitionGallery());
        };
    }, [dispatch]);

    if (measurementCount === 0) return null;

    if (showLoader) {
        return (
            <div className={`${container} ${className}`}>
                <Loader className={loader} />
            </div>
        );
    }

    if (status === 'fail') {
        return (
            <div className={`${container} ${className}`}>
                <p className={errorText}>{copy.error}</p>
            </div>
        );
    }

    return (
        <div className={`${container} ${className} ${status === 'loading' ? loading : ''}`}>
            <TransitionChartHeader
                filters={gallery.filters}
                title={copy.title}
                buttonText={copy.button}
                onAdd={handleAddMeasurement}
                onFiltersChange={handleFilterChange}
                onClearFilters={handleClear}
            />
            {gallery.items.length === 0 && <p className={noItems}>{copy.noItems}</p>}
            {gallery.items.length > 0 && (
                <div className={grid}>
                    {gallery.items.map((item) => {
                        return (
                            <TransitionGalleryCard
                                key={`measurement-${item.measurementId}`}
                                measurement={item}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const copy = {
    title: 'Twoje pomiary',
    error: 'Nie udało się pobrać danych pomiarów. Spróbuj ponownie później...',
    button: 'Dodaj pomiar',
    clear: 'Pełny zakres',
    noItems: 'Brak pomiarów w wybranym zakresie.',
};

export default TransitionGallery;
