import React, { useState } from 'react';

import { container, button, dropdown, open } from './more-button.module.scss';
import MoreIcon from '../../assets/images/svg/more.svg';

import Dropdown from './dropdown';

interface IMoreButtonProps {
    className?: string;
    children: React.ReactNode;
}

const MoreButton: React.FC<IMoreButtonProps> = ({ className = '', children }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleCloseDropdown = () => setIsDropdownOpen(false);

    const handleOpenDropdown = () => setIsDropdownOpen(true);

    return (
        <div className={`${container} ${className} ${isDropdownOpen ? open : ''}`}>
            <button className={button} onClick={handleOpenDropdown}>
                <MoreIcon />
            </button>
            <Dropdown
                open={isDropdownOpen}
                closeFunc={handleCloseDropdown}
                className={dropdown}
                isTriangle={false}
            >
                {children}
            </Dropdown>
        </div>
    );
};

export default MoreButton;
